import React from 'react';
import { Spin } from 'antd';

const Loader = (): React.ReactElement => (
  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%' }}>
    <Spin />
  </div>
);

export default Loader;
