/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const listUsers = /* GraphQL */ `
  query ListUsers($limit: Int, $nextToken: String) {
    listUsers(limit: $limit, nextToken: $nextToken) {
      items {
        id
        email
        name
        userType
        odooId
        customerId
      }
      nextToken
    }
  }
`;
export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      email
      name
      userType
      odooId
      customerId
    }
  }
`;
export const getCandidateResume = /* GraphQL */ `
  query GetCandidateResume($id: ID!) {
    getCandidateResume(id: $id) {
      id
      owner
      submitted
      verified
      ExperienceSummary {
        TotalYearsOfManagementWorkExperience
        TotalYearsOfLowLevelManagementWorkExperience
        TotalMonthsOfWorkExperience
        ExecutiveBrief
        TotalMonthsOfLowLevelManagementWorkExperience
        TotalMonthsOfManagementWorkExperience
        TotalYearsOfMidLevelManagementWorkExperience
        TotalMonthsOfMidLevelManagementWorkExperience
        TotalYearsOfSeniorManagementWorkExperience
        TotalMonthsOfSeniorManagementWorkExperience
        ManagementRecord
        HighestEducationalLevel
        TotalYearsOfWorkExperience
      }
      Achievements
      Available
      Competency {
        skillLevel
        skillUsed {
          value
          type
        }
        skillProficiency
        Grade
        skillName
        description
        auth
        skillCount
        lastUsed
        CompetencyId {
          idOwner
          id
        }
        TaxonomyId {
          idOwner
          description
        }
        skillAliasArray
      }
      ContactMethod {
        InternetWebAddress {
          content
          type
        }
        InternetEmailAddress_main
        InternetEmailAddress_alt
        Telephone_home
        Telephone_work
        Telephone_alt
        Telephone_mobile
        Pager
        Fax
        PostalAddress_main {
          CountryCode
          Municipality
          Region
          AddressLine
          PostalCode
        }
        PostalAddress_alt {
          CountryCode
          Municipality
          Region
          AddressLine
          PostalCode
        }
      }
      DOB
      EducationHistory {
        StartDate
        EndDate
        MeasureValue
        Major
        SchoolUnit
        Degree {
          degreeType
          DegreeName
          DegreeDate
        }
        LocationSummary {
          CountryCode
          Municipality
          Region
          AddressLine
          PostalCode
        }
        SchoolName
        schoolType
        Comments
      }
      EmploymentHistory {
        StartDate
        EndDate
        LocationSummary {
          CountryCode
          Municipality
          Region
          AddressLine
          PostalCode
        }
        InternetDomainName
        EmployerOrgName
        employerOrgType
        PositionType
        MonthsOfWork
        TimelineNumber
        Title
        ReasonForLeaving
        OrgIndustry {
          IndustryDescription
          primaryIndicator
        }
        JobArea
        JobGrade
        Project
        OrgName
        Description
      }
      ExecutiveSummary
      Hobbies
      LicensesAndCertifications {
        IssuingAuthority {
          IssuingAuthorityName
          countryCode
        }
        Id
        EffectiveDate {
          ValidFrom
          FirstIssuedDate
          ValidTo
        }
        LicensesOrCertificationName
        Description
      }
      Languages {
        CompetencyId {
          idOwner
          id
        }
        Proficiency
        Speak
        LanguageCode
        Write
        Read
      }
      lang
      LegalDocuments {
        IssueCountryCode
        IssueAuthority
        IssueDate
        Number
        IssueLocationSummary {
          CountryCode
          Municipality
          Region
          AddressLine
          PostalCode
        }
        type
      }
      MaritalStatus
      Nationality
      OtherInfo
      PersonName {
        FormattedName
        Title
        Suffix
        FamilyName
        GivenName
        PreferredGivenName
        MiddleName
        sex
      }
      PlaceOfBirth {
        CountryCode
        Municipality
        Region
        AddressLine
        PostalCode
      }
      PoliticalStatus
      PreferredLocation
      PreferredPosition
      PreferredPositionType
      PreferredTypeOfHours
      ProjectHistory {
        StartDate
        EndDate
        LocationSummary {
          CountryCode
          Municipality
          Region
          AddressLine
          PostalCode
        }
        InternetDomainName
        EmployerOrgName
        employerOrgType
        PositionType
        MonthsOfWork
        TimelineNumber
        Title
        ReasonForLeaving
        OrgIndustry {
          IndustryDescription
          primaryIndicator
        }
        JobArea
        JobGrade
        Project
        OrgName
        Description
      }
      PublicationHistory {
        Comments
        content
        Issue
        JournalOrSerialName
        Volume
        PageNumber
        Name {
          FormattedName
          role
        }
        Title
        PublicationDate
      }
      Race
      References {
        CompanyName
        PersonName {
          FormattedName
          Title
          Suffix
          FamilyName
          GivenName
          PreferredGivenName
          MiddleName
          sex
        }
        ContactMethod {
          InternetEmailAddress_main
          InternetEmailAddress_alt
          Telephone_home
          Telephone_work
          Telephone_alt
          Telephone_mobile
          Pager
          Fax
        }
        PositionTitle
      }
      RegisteredResidence {
        CountryCode
        Municipality
        Region
        AddressLine
        PostalCode
      }
      Religion
      RevisionDate
      Salary_desired {
        currency
        amount
        hiamount
        period
      }
      Salary_current {
        currency
        amount
        hiamount
        period
      }
      WillRelocate
      RawData
      createdAt
      updatedAt
    }
  }
`;
export const listCandidateResumes = /* GraphQL */ `
  query ListCandidateResumes(
    $filter: ModelCandidateResumeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCandidateResumes(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        submitted
        verified
        ExperienceSummary {
          TotalYearsOfManagementWorkExperience
          TotalYearsOfLowLevelManagementWorkExperience
          TotalMonthsOfWorkExperience
          ExecutiveBrief
          TotalMonthsOfLowLevelManagementWorkExperience
          TotalMonthsOfManagementWorkExperience
          TotalYearsOfMidLevelManagementWorkExperience
          TotalMonthsOfMidLevelManagementWorkExperience
          TotalYearsOfSeniorManagementWorkExperience
          TotalMonthsOfSeniorManagementWorkExperience
          ManagementRecord
          HighestEducationalLevel
          TotalYearsOfWorkExperience
        }
        Achievements
        Available
        Competency {
          skillLevel
          skillProficiency
          Grade
          skillName
          description
          auth
          skillCount
          lastUsed
          skillAliasArray
        }
        ContactMethod {
          InternetEmailAddress_main
          InternetEmailAddress_alt
          Telephone_home
          Telephone_work
          Telephone_alt
          Telephone_mobile
          Pager
          Fax
        }
        DOB
        EducationHistory {
          StartDate
          EndDate
          MeasureValue
          Major
          SchoolUnit
          SchoolName
          schoolType
          Comments
        }
        EmploymentHistory {
          StartDate
          EndDate
          InternetDomainName
          EmployerOrgName
          employerOrgType
          PositionType
          MonthsOfWork
          TimelineNumber
          Title
          ReasonForLeaving
          JobArea
          JobGrade
          Project
          OrgName
          Description
        }
        ExecutiveSummary
        Hobbies
        LicensesAndCertifications {
          Id
          LicensesOrCertificationName
          Description
        }
        Languages {
          Proficiency
          Speak
          LanguageCode
          Write
          Read
        }
        lang
        LegalDocuments {
          IssueCountryCode
          IssueAuthority
          IssueDate
          Number
          type
        }
        MaritalStatus
        Nationality
        OtherInfo
        PersonName {
          FormattedName
          Title
          Suffix
          FamilyName
          GivenName
          PreferredGivenName
          MiddleName
          sex
        }
        PlaceOfBirth {
          CountryCode
          Municipality
          Region
          AddressLine
          PostalCode
        }
        PoliticalStatus
        PreferredLocation
        PreferredPosition
        PreferredPositionType
        PreferredTypeOfHours
        ProjectHistory {
          StartDate
          EndDate
          InternetDomainName
          EmployerOrgName
          employerOrgType
          PositionType
          MonthsOfWork
          TimelineNumber
          Title
          ReasonForLeaving
          JobArea
          JobGrade
          Project
          OrgName
          Description
        }
        PublicationHistory {
          Comments
          content
          Issue
          JournalOrSerialName
          Volume
          PageNumber
          Title
          PublicationDate
        }
        Race
        References {
          CompanyName
          PositionTitle
        }
        RegisteredResidence {
          CountryCode
          Municipality
          Region
          AddressLine
          PostalCode
        }
        Religion
        RevisionDate
        Salary_desired {
          currency
          amount
          hiamount
          period
        }
        Salary_current {
          currency
          amount
          hiamount
          period
        }
        WillRelocate
        RawData
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getTimesheet = /* GraphQL */ `
  query GetTimesheet($id: ID!) {
    getTimesheet(id: $id) {
      id
      owner
      user {
        id
        email
        name
        userType
        odooId
        customerId
      }
      contractId
      employeeId
      employeeName
      customerId
      customerName
      jobId
      status
      state
      submitted
      submittedBy
      submittedByName
      submittedDate
      approver1
      approver1Name
      approver1Token
      approved1
      approved1By
      approved1ByName
      approved1Date
      approver2
      approver2Name
      approver2Token
      approved2
      approved2By
      approved2ByName
      approved2Date
      fromDate
      toDate
      description
      entries {
        date
        type
        leaveType
        leaveId
      }
      managersComments {
        date
        managerId
        message
      }
      statusChanges {
        date
        userId
        status
        message
      }
      createdAt
      updatedAt
    }
  }
`;
export const listTimesheets = /* GraphQL */ `
  query ListTimesheets(
    $filter: ModelTimesheetFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTimesheets(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        owner
        user {
          id
          email
          name
          userType
          odooId
          customerId
        }
        contractId
        employeeId
        employeeName
        customerId
        customerName
        jobId
        status
        state
        submitted
        submittedBy
        submittedByName
        submittedDate
        approver1
        approver1Name
        approver1Token
        approved1
        approved1By
        approved1ByName
        approved1Date
        approver2
        approver2Name
        approver2Token
        approved2
        approved2By
        approved2ByName
        approved2Date
        fromDate
        toDate
        description
        entries {
          date
          type
          leaveType
          leaveId
        }
        managersComments {
          date
          managerId
          message
        }
        statusChanges {
          date
          userId
          status
          message
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const timesheetsByContractor = /* GraphQL */ `
  query TimesheetsByContractor(
    $owner: String
    $statusFromDate: ModelTimesheetByContractorCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTimesheetFilterInput
    $limit: Int
    $nextToken: String
  ) {
    timesheetsByContractor(
      owner: $owner
      statusFromDate: $statusFromDate
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        user {
          id
          email
          name
          userType
          odooId
          customerId
        }
        contractId
        employeeId
        employeeName
        customerId
        customerName
        jobId
        status
        state
        submitted
        submittedBy
        submittedByName
        submittedDate
        approver1
        approver1Name
        approver1Token
        approved1
        approved1By
        approved1ByName
        approved1Date
        approver2
        approver2Name
        approver2Token
        approved2
        approved2By
        approved2ByName
        approved2Date
        fromDate
        toDate
        description
        entries {
          date
          type
          leaveType
          leaveId
        }
        managersComments {
          date
          managerId
          message
        }
        statusChanges {
          date
          userId
          status
          message
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const timesheetsByContractorAndDate = /* GraphQL */ `
  query TimesheetsByContractorAndDate(
    $owner: String
    $fromDate: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTimesheetFilterInput
    $limit: Int
    $nextToken: String
  ) {
    timesheetsByContractorAndDate(
      owner: $owner
      fromDate: $fromDate
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        user {
          id
          email
          name
          userType
          odooId
          customerId
        }
        contractId
        employeeId
        employeeName
        customerId
        customerName
        jobId
        status
        state
        submitted
        submittedBy
        submittedByName
        submittedDate
        approver1
        approver1Name
        approver1Token
        approved1
        approved1By
        approved1ByName
        approved1Date
        approver2
        approver2Name
        approver2Token
        approved2
        approved2By
        approved2ByName
        approved2Date
        fromDate
        toDate
        description
        entries {
          date
          type
          leaveType
          leaveId
        }
        managersComments {
          date
          managerId
          message
        }
        statusChanges {
          date
          userId
          status
          message
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const timesheetsByEmployee = /* GraphQL */ `
  query TimesheetsByEmployee(
    $employeeId: Int
    $fromDate: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTimesheetFilterInput
    $limit: Int
    $nextToken: String
  ) {
    timesheetsByEmployee(
      employeeId: $employeeId
      fromDate: $fromDate
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        user {
          id
          email
          name
          userType
          odooId
          customerId
        }
        contractId
        employeeId
        employeeName
        customerId
        customerName
        jobId
        status
        state
        submitted
        submittedBy
        submittedByName
        submittedDate
        approver1
        approver1Name
        approver1Token
        approved1
        approved1By
        approved1ByName
        approved1Date
        approver2
        approver2Name
        approver2Token
        approved2
        approved2By
        approved2ByName
        approved2Date
        fromDate
        toDate
        description
        entries {
          date
          type
          leaveType
          leaveId
        }
        managersComments {
          date
          managerId
          message
        }
        statusChanges {
          date
          userId
          status
          message
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const timesheetsByCustomer = /* GraphQL */ `
  query TimesheetsByCustomer(
    $customerId: String
    $statusFromDate: ModelTimesheetByCustomerCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTimesheetFilterInput
    $limit: Int
    $nextToken: String
  ) {
    timesheetsByCustomer(
      customerId: $customerId
      statusFromDate: $statusFromDate
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        user {
          id
          email
          name
          userType
          odooId
          customerId
        }
        contractId
        employeeId
        employeeName
        customerId
        customerName
        jobId
        status
        state
        submitted
        submittedBy
        submittedByName
        submittedDate
        approver1
        approver1Name
        approver1Token
        approved1
        approved1By
        approved1ByName
        approved1Date
        approver2
        approver2Name
        approver2Token
        approved2
        approved2By
        approved2ByName
        approved2Date
        fromDate
        toDate
        description
        entries {
          date
          type
          leaveType
          leaveId
        }
        managersComments {
          date
          managerId
          message
        }
        statusChanges {
          date
          userId
          status
          message
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const timesheetsByStatus = /* GraphQL */ `
  query TimesheetsByStatus(
    $status: TimesheetStatus
    $fromDate: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTimesheetFilterInput
    $limit: Int
    $nextToken: String
  ) {
    timesheetsByStatus(
      status: $status
      fromDate: $fromDate
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        user {
          id
          email
          name
          userType
          odooId
          customerId
        }
        contractId
        employeeId
        employeeName
        customerId
        customerName
        jobId
        status
        state
        submitted
        submittedBy
        submittedByName
        submittedDate
        approver1
        approver1Name
        approver1Token
        approved1
        approved1By
        approved1ByName
        approved1Date
        approver2
        approver2Name
        approver2Token
        approved2
        approved2By
        approved2ByName
        approved2Date
        fromDate
        toDate
        description
        entries {
          date
          type
          leaveType
          leaveId
        }
        managersComments {
          date
          managerId
          message
        }
        statusChanges {
          date
          userId
          status
          message
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const timesheetsByApprover1 = /* GraphQL */ `
  query TimesheetsByApprover1(
    $approver1: String
    $sortDirection: ModelSortDirection
    $filter: ModelTimesheetFilterInput
    $limit: Int
    $nextToken: String
  ) {
    timesheetsByApprover1(
      approver1: $approver1
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        user {
          id
          email
          name
          userType
          odooId
          customerId
        }
        contractId
        employeeId
        employeeName
        customerId
        customerName
        jobId
        status
        state
        submitted
        submittedBy
        submittedByName
        submittedDate
        approver1
        approver1Name
        approver1Token
        approved1
        approved1By
        approved1ByName
        approved1Date
        approver2
        approver2Name
        approver2Token
        approved2
        approved2By
        approved2ByName
        approved2Date
        fromDate
        toDate
        description
        entries {
          date
          type
          leaveType
          leaveId
        }
        managersComments {
          date
          managerId
          message
        }
        statusChanges {
          date
          userId
          status
          message
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const timesheetsByApprover2 = /* GraphQL */ `
  query TimesheetsByApprover2(
    $approver2: String
    $sortDirection: ModelSortDirection
    $filter: ModelTimesheetFilterInput
    $limit: Int
    $nextToken: String
  ) {
    timesheetsByApprover2(
      approver2: $approver2
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        user {
          id
          email
          name
          userType
          odooId
          customerId
        }
        contractId
        employeeId
        employeeName
        customerId
        customerName
        jobId
        status
        state
        submitted
        submittedBy
        submittedByName
        submittedDate
        approver1
        approver1Name
        approver1Token
        approved1
        approved1By
        approved1ByName
        approved1Date
        approver2
        approver2Name
        approver2Token
        approved2
        approved2By
        approved2ByName
        approved2Date
        fromDate
        toDate
        description
        entries {
          date
          type
          leaveType
          leaveId
        }
        managersComments {
          date
          managerId
          message
        }
        statusChanges {
          date
          userId
          status
          message
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getNotification = /* GraphQL */ `
  query GetNotification($id: ID!) {
    getNotification(id: $id) {
      id
      status
      date
      user
      template
      parameters
      timesheetId
      createdAt
      updatedAt
    }
  }
`;
export const listNotifications = /* GraphQL */ `
  query ListNotifications(
    $filter: ModelNotificationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listNotifications(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        status
        date
        user
        template
        parameters
        timesheetId
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const notificationsByTimesheet = /* GraphQL */ `
  query NotificationsByTimesheet(
    $timesheetId: String
    $userDate: ModelNotificationByTimesheetCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelNotificationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    notificationsByTimesheet(
      timesheetId: $timesheetId
      userDate: $userDate
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        status
        date
        user
        template
        parameters
        timesheetId
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getRequest = /* GraphQL */ `
  query GetRequest($id: ID!) {
    getRequest(id: $id) {
      id
      owner
      user {
        id
        email
        name
        userType
        odooId
        customerId
      }
      employeeId
      employeeName
      status
      type
      requestDate
      entityName
      notes
      drivingInstitureName
      emirate
      country
      dateFrom
      dateTo
      expectedAmount
      receivedAmount
      months
      createdAt
      updatedAt
    }
  }
`;
export const listRequests = /* GraphQL */ `
  query ListRequests(
    $filter: ModelRequestFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRequests(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        owner
        user {
          id
          email
          name
          userType
          odooId
          customerId
        }
        employeeId
        employeeName
        status
        type
        requestDate
        entityName
        notes
        drivingInstitureName
        emirate
        country
        dateFrom
        dateTo
        expectedAmount
        receivedAmount
        months
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const requestsByContractor = /* GraphQL */ `
  query RequestsByContractor(
    $owner: String
    $requestDate: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelRequestFilterInput
    $limit: Int
    $nextToken: String
  ) {
    requestsByContractor(
      owner: $owner
      requestDate: $requestDate
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        user {
          id
          email
          name
          userType
          odooId
          customerId
        }
        employeeId
        employeeName
        status
        type
        requestDate
        entityName
        notes
        drivingInstitureName
        emirate
        country
        dateFrom
        dateTo
        expectedAmount
        receivedAmount
        months
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
