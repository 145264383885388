import React from 'react';
import { API, Auth } from 'aws-amplify';
import { PageHeader, Typography, Descriptions, Spin } from 'antd';
import ContentWrapper from '../../Components/ContentWrapper';
import withAppState, { IAppStateComponentProps } from '../../state/withAppState';
import { Redirect } from 'react-router-dom';
import moment from 'moment';

type LeaveQuota = {
  number_of_days: number;
  leaves_taken: number;
};

type Props = IAppStateComponentProps & {};
type State = {
  leaveQuotas?: {
    annual: LeaveQuota;
    sick: LeaveQuota;
  };
  activeContract?: { [key: string]: any } | boolean;
};

class Profile extends React.Component<Props, State> {
  state = {
    leaveQuotas: undefined,
    activeContract: undefined,
  };

  async componentDidMount(): Promise<void> {
    if (!this.props.appState.user.profile) {
      API.get('EhcmsPortalApi', '/me', {}).then((r: any): void => {
        this.props.setAppState('user', r);
      });
    }
    API.get('EhcmsPortalApi', '/contractor/leaves/quotas', {}).then((r: any): void => {
      this.setState({ leaveQuotas: r });
    });
    API.get('EhcmsPortalApi', '/contractor/contracts/active', {}).then((r: any): void => {
      this.setState({ activeContract: r || false });
    });
  }

  render(): React.ReactElement {
    const user = this.props.appState?.user;
    const activeContract = this.state.activeContract || ({} as any);
    const leaveQuotas = this.state.leaveQuotas || ({} as any);
    if (!user) {
      return <Redirect to="/" />;
    }
    return (
      <div data-testid="timesheet-form">
        <PageHeader title={user?.profile?.name || ''} />
        <ContentWrapper>
          {/* <Typography.Title>{record?.employee_id[1]}</Typography.Title> */}
          {this.state.activeContract !== false && (
            <>
              <Typography.Title level={3}>Contract Details</Typography.Title>
              {typeof this.state.activeContract === 'undefined' && <Spin />}
              {activeContract.id && (
                <Descriptions layout="vertical" bordered>
                  <Descriptions.Item label="Employer">{activeContract.customer[1]}</Descriptions.Item>
                  <Descriptions.Item label="Start Date">
                    {moment(activeContract.date_start).format('DD/MM/YYYY')}
                  </Descriptions.Item>
                  {activeContract.date_end !== false && (
                    <Descriptions.Item label="End Date">
                      {moment(activeContract.date_end).format('DD/MM/YYYY')}
                    </Descriptions.Item>
                  )}
                  <Descriptions.Item label="Job Title">{activeContract.job_id[1]}</Descriptions.Item>
                </Descriptions>
              )}
            </>
          )}
          {this.state.leaveQuotas && (
            <>
              <Typography.Title level={3} style={{ marginTop: '20px' }}>
                Leave Quota
              </Typography.Title>
              <Descriptions layout="vertical" bordered>
                {leaveQuotas?.annual && (
                  <Descriptions.Item label="Annual Leave">
                    {leaveQuotas?.annual.number_of_days - leaveQuotas?.annual.leaves_taken}
                  </Descriptions.Item>
                )}
                {/* {leaveQuotas?.sick && (
                  <Descriptions.Item label="Sick Leave">
                    {leaveQuotas?.sick.number_of_days - leaveQuotas?.sick.leaves_taken}
                  </Descriptions.Item>
                )} */}
              </Descriptions>
            </>
          )}
        </ContentWrapper>
      </div>
    );
  }
}

export default withAppState(Profile);
