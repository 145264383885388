import React from 'react';
import { Subtract } from 'utility-types';

import { AppState, IAppStateContext, IAppState } from './AppStateGate';

export interface IAppStateComponentProps {
  appState: IAppState;
  setAppState: (key: string, value: any) => void;
}

const withAppState = <P extends IAppStateComponentProps>(
  Component: React.ComponentType<P>,
): React.FC<Subtract<P, IAppStateComponentProps>> => {
  return (props: Subtract<P, IAppStateComponentProps>): React.ReactElement => {
    return (
      <AppState.Consumer>
        {(state: IAppStateContext): React.ReactElement => (
          <Component {...(props as P)} appState={state.appState} setAppState={state.setAppState} />
        )}
      </AppState.Consumer>
    );
  };
};

export default withAppState;
