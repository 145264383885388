
import { Auth } from 'aws-amplify';
import mixpanel from 'mixpanel-browser';

const mixpanel_token = 'b3f7977b780d1338778b6e32c9ff0cc2';

export const initMixpanel = async () => {
  mixpanel.init(mixpanel_token);
  const user = await Auth.currentUserInfo();
  if (user) {
    mixpanel.identify(user.username);
  }
}

export const mixpanelSetUser = async () => {
  const user = await Auth.currentUserInfo();
  mixpanel.identify(user.username);
  mixpanel.track('sign in');
}