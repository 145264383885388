import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Request, IConnectGetQuery } from '../../types';
import { API, graphqlOperation } from 'aws-amplify';
import { getRequest } from '../../graphql/queries';
import Loader from '../../Components/Loader';
import { message } from 'antd';
import RequestForm from './partials/RequestForm';
import { CreateRequestInput, RequestStatus } from '../../API';
import { createRequest } from '../../graphql/mutations';
import mixpanel from 'mixpanel-browser';
import withAppState, { IAppStateComponentProps } from '../../state/withAppState';
import moment from 'moment';

type Props = IAppStateComponentProps & RouteComponentProps<{ id: string }, {}, { record: any }> & {};
type State = {
  request?: Request | null;
  loading: boolean;
};
class Form extends React.Component<Props, State> {
  state = {
    request: null,
    loading: false,
  };
  async componentDidMount(): Promise<void> {
    if (this.props.location.state?.record) {
      // We passed it from the list in the state
      this.setState({
        request: this.props.location.state.record,
      });
      mixpanel.track('View Request', {
        requestId: this.props.location.state.record.id,
      });
    } else if (this.props.match.params.id && this.props.match.params.id!=='new') {
      // We should load from the API
      try {
        this.setState({
          loading: true,
        });
        const request = (await API.graphql(
          graphqlOperation(getRequest, { id: this.props.match.params.id }),
        )) as IConnectGetQuery<Request, 'getRequest'>;
        if (request.data && request.data.getRequest) {
          this.setState({
            request: request.data.getRequest,
            loading: false,
          });
          mixpanel.track('View Request', {
            requestId: request.data.getRequest.id,
          });
        }
      } catch (e) {
        message.error('Request not found');
        this.props.history.goBack();
      }
    } else {
      this.setState({
        loading: false,
        request: {} as Request,
      })
    }
    console.log(this.props.appState.user);
  }
  save = async (values: CreateRequestInput & { submitting?: boolean }): Promise<any> => {
    try {
      const op = createRequest;
      const opName = 'createRequest';
      const user = this.props.appState.user;
      const vals = {
        ...values,
        owner: user.id,
        employeeId: user.odooId,
        employeeName: user.name,
        status: RequestStatus.PENDING,
        requestDate: moment().format('YYYY-MM-DD'),
      };
      if (vals.dateFrom) {
        vals.dateFrom = moment(vals.dateFrom).format('YYYY-MM-DD');
      }
      if (vals.dateTo) {
        vals.dateTo = moment(vals.dateTo).format('YYYY-MM-DD');
      }
      const response = await API.graphql(graphqlOperation(op, { input: vals }));
      if (response && response.data && response.data[opName]) {
        message.success('Your request was saved');
        this.props.history.push('/requests');
        return response.data[opName];
      }
      // return Promise.reject(new Error('Error saving your request'));
    } catch (e) {
      // return Promise.reject(e);
    }
  };
  close = (): void => {
    this.props.history.goBack();
  };
  render(): React.ReactElement {
    if (this.state.loading) {
      return <Loader />;
    }
    if (this.state.request) {
      return (
        <RequestForm
          record={this.state.request}
          save={this.save}
          close={this.close}
        />
      );
    }
    return <Loader />;
  }
}

export default withAppState(Form);
