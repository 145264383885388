import React from 'react';
import { Switch, Route, RouteComponentProps } from 'react-router-dom';
import { Tabs } from 'antd';
import { TimesheetStatus } from '../../API';
import List from './List';
import ContentWrapper from '../../Components/ContentWrapper';
import Form from './Form';
import PageHeader from '../../Components/PageHeader';

class Timesheets extends React.Component {
  render(): React.ReactElement {
    return (
      <Switch>
        <Route
          path="/timesheets"
          exact
          render={(props: RouteComponentProps): React.ReactElement => (
            <>
              <PageHeader title="Timesheets" />
              <ContentWrapper>
                <Tabs defaultActiveKey="pending">
                  <Tabs.TabPane tab="Pending" key="pending">
                    <List status={TimesheetStatus.PENDING} history={props.history} />
                  </Tabs.TabPane>
                  <Tabs.TabPane tab="Rejected" key="rejected">
                    <List status={TimesheetStatus.REJECTED} history={props.history} />
                  </Tabs.TabPane>
                  <Tabs.TabPane tab="Submitted" key="submitted">
                    <List status={TimesheetStatus.SUBMITTED} history={props.history} />
                  </Tabs.TabPane>
                  <Tabs.TabPane tab="Approved" key="approved">
                    <List status={TimesheetStatus.APPROVED} history={props.history} />
                  </Tabs.TabPane>
                </Tabs>
              </ContentWrapper>
            </>
          )}
        />
        <Route path="/timesheets/:id" component={Form} />
      </Switch>
    );
  }
}

export default Timesheets;
