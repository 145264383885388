import { CandidateResume } from '../types';
import { API } from 'aws-amplify';

class UserModel {
  public async getProfile(): Promise<any> {
    const response = await API.get('EhcmsPortalApi', '/me', {});
    return response.user;
  }

  public async markSetupCompleted(): Promise<any> {
    const response = await API.post('EhcmsPortalApi', '/contractor/setup/complete', {});
    return response.user;
  }
}

export default new UserModel();
