import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { REACT_APP_ENV, APP_SENTRYDSN } from './configurations/AppConfig';

let app;
if (REACT_APP_ENV == 'prod') {
  Sentry.init({
    dsn: APP_SENTRYDSN,
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 1.0,
  });
  app = (
    <Sentry.ErrorBoundary>
      <App />
    </Sentry.ErrorBoundary>
  );
} else {
  app = <App />;
}

ReactDOM.render(app, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
