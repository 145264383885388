import React, { ReactElement } from 'react';
import { Router, Route, Switch, Redirect } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import App from './App';
import AppStateGate from '../state/AppStateGate';
import UserProfileGate from '../Components/UserProfileGate';
import { OAuthProvider } from 'basealpha-ui';
import Timesheets from '../Pages/Timesheets';
import Profile from '../Pages/Profile/Profile';
import Requests from '../Pages/Requests';

const browserHistory = createBrowserHistory();

const MainRouter: React.FC = (): ReactElement => (
  <Router history={browserHistory}>
    <AppStateGate>
      <UserProfileGate>
        <App>
          <Switch>
            <Route path="/oauth/login" component={OAuthProvider} />
            <Route path="/timesheets" component={Timesheets} />
            <Route path="/requests" component={Requests} />
            <Route path="/profile" component={Profile} />
            <Route render={(): React.ReactElement => <Redirect to="/timesheets" />} />
          </Switch>
        </App>
      </UserProfileGate>
    </AppStateGate>
  </Router>
);

export default MainRouter;
