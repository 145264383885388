import React from 'react';
import { Form, Input } from 'formik-antd';

const MedicalInsurance = (): React.ReactElement => {
  return (
    <>
      <h3>Medical Insurance</h3>
      <Form.Item name={`notes`} label="Notes">
        <Input.TextArea name="notes" />
      </Form.Item>
      <b>Note: if you have an urgent query regarding medical insurance please reach out to your point of contact within Edari.</b>
    </>
  );
};

export default MedicalInsurance;
