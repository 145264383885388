/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.

export type CreateCandidateResumeInput = {
  id?: string | null,
  owner: string,
  submitted?: boolean | null,
  verified?: boolean | null,
  ExperienceSummary?: ExperienceSummaryInput | null,
  Achievements?: Array< string | null > | null,
  Available?: string | null,
  Competency?: Array< CompetencyInput | null > | null,
  ContactMethod?: ContactMethodInput | null,
  DOB?: string | null,
  EducationHistory?: Array< EducationInput | null > | null,
  EmploymentHistory?: Array< EmploymentInput | null > | null,
  ExecutiveSummary?: string | null,
  Hobbies?: string | null,
  LicensesAndCertifications?: Array< LicenseOrCertificationInput | null > | null,
  Languages?: Array< LanguageInput | null > | null,
  lang?: string | null,
  LegalDocuments?: Array< LegalDocumentInput | null > | null,
  MaritalStatus?: MaritalStatus | null,
  Nationality?: Array< string | null > | null,
  OtherInfo?: string | null,
  PersonName?: PersonNameInput | null,
  PlaceOfBirth?: LocationSummaryInput | null,
  PoliticalStatus?: string | null,
  PreferredLocation?: string | null,
  PreferredPosition?: string | null,
  PreferredPositionType?: Array< PreferredPositionType | null > | null,
  PreferredTypeOfHours?: Array< PreferredTypeOfHours | null > | null,
  ProjectHistory?: Array< EmploymentInput | null > | null,
  PublicationHistory?: Array< PublicationInput | null > | null,
  Race?: string | null,
  References?: Array< ReferenceInput | null > | null,
  RegisteredResidence?: LocationSummaryInput | null,
  Religion?: string | null,
  RevisionDate?: string | null,
  Salary_desired?: SalaryInput | null,
  Salary_current?: SalaryInput | null,
  WillRelocate?: boolean | null,
  RawData?: string | null,
};

export type ExperienceSummaryInput = {
  TotalYearsOfManagementWorkExperience?: number | null,
  TotalYearsOfLowLevelManagementWorkExperience?: number | null,
  TotalMonthsOfWorkExperience?: number | null,
  ExecutiveBrief?: string | null,
  TotalMonthsOfLowLevelManagementWorkExperience?: number | null,
  TotalMonthsOfManagementWorkExperience?: number | null,
  TotalYearsOfMidLevelManagementWorkExperience?: number | null,
  TotalMonthsOfMidLevelManagementWorkExperience?: number | null,
  TotalYearsOfSeniorManagementWorkExperience?: number | null,
  TotalMonthsOfSeniorManagementWorkExperience?: number | null,
  ManagementRecord?: string | null,
  HighestEducationalLevel?: DegreeType | null,
  TotalYearsOfWorkExperience?: number | null,
};

export enum DegreeType {
  doctorate = "doctorate",
  some_post_graduate = "some_post_graduate",
  masters = "masters",
  postprofessional = "postprofessional",
  professional = "professional",
  bachelors = "bachelors",
  associates = "associates",
  intermediategraduate = "intermediategraduate",
  certification = "certification",
  vocational = "vocational",
  HND_HNC_or_equivalent = "HND_HNC_or_equivalent",
  some_college = "some_college",
  high_school_or_equivalent = "high_school_or_equivalent",
  some_high_school_or_equivalent = "some_high_school_or_equivalent",
  secondary = "secondary",
  ged = "ged",
}


export type CompetencyInput = {
  skillLevel?: number | null,
  skillUsed?: CompetencySkillUsedInput | null,
  skillProficiency?: SkillProficiencyList | null,
  Grade?: string | null,
  skillName?: string | null,
  description?: string | null,
  auth?: string | null,
  skillCount?: number | null,
  lastUsed?: string | null,
  CompetencyId?: CompetencyIdInput | null,
  TaxonomyId?: TaxonomyIdInput | null,
  skillAliasArray?: Array< string | null > | null,
};

export type CompetencySkillUsedInput = {
  value?: number | null,
  type?: SkillUsedType | null,
};

export enum SkillUsedType {
  Hours = "Hours",
  Days = "Days",
  Weeks = "Weeks",
  Months = "Months",
  Years = "Years",
}


export enum SkillProficiencyList {
  EXCELLENT = "EXCELLENT",
  GOOD = "GOOD",
  WORKING = "WORKING",
  BASIC = "BASIC",
  ADVANCED = "ADVANCED",
}


export type CompetencyIdInput = {
  idOwner?: string | null,
  id?: string | null,
};

export type TaxonomyIdInput = {
  idOwner?: string | null,
  description?: string | null,
};

export type ContactMethodInput = {
  InternetWebAddress?: Array< InternetWebAddressInput | null > | null,
  InternetEmailAddress_main?: string | null,
  InternetEmailAddress_alt?: string | null,
  Telephone_home?: string | null,
  Telephone_work?: string | null,
  Telephone_alt?: string | null,
  Telephone_mobile?: string | null,
  Pager?: string | null,
  Fax?: string | null,
  PostalAddress_main?: LocationSummaryInput | null,
  PostalAddress_alt?: LocationSummaryInput | null,
};

export type InternetWebAddressInput = {
  content?: string | null,
  type?: string | null,
};

export type LocationSummaryInput = {
  CountryCode?: string | null,
  Municipality?: string | null,
  Region?: string | null,
  AddressLine?: string | null,
  PostalCode?: string | null,
};

export type EducationInput = {
  StartDate?: string | null,
  EndDate?: string | null,
  MeasureValue?: string | null,
  Major?: string | null,
  SchoolUnit?: string | null,
  Degree?: DegreeInput | null,
  LocationSummary?: LocationSummaryInput | null,
  SchoolName?: string | null,
  schoolType?: SchoolType | null,
  Comments?: string | null,
};

export type DegreeInput = {
  degreeType?: string | null,
  DegreeName?: string | null,
  DegreeDate?: string | null,
};

export enum SchoolType {
  university = "university",
  college = "college",
  community = "community",
  trade = "trade",
  highschool = "highschool",
  secondary = "secondary",
  TRAINING = "TRAINING",
  unknown = "unknown",
}


export type EmploymentInput = {
  StartDate?: string | null,
  EndDate?: string | null,
  LocationSummary?: LocationSummaryInput | null,
  InternetDomainName?: string | null,
  EmployerOrgName?: string | null,
  employerOrgType?: OrgType | null,
  PositionType?: string | null,
  MonthsOfWork?: number | null,
  TimelineNumber?: number | null,
  Title?: string | null,
  ReasonForLeaving?: string | null,
  OrgIndustry?: OrgIndustryInput | null,
  JobArea?: string | null,
  JobGrade?: string | null,
  Project?: string | null,
  OrgName?: string | null,
  Description?: string | null,
};

export enum OrgType {
  MILITARY = "MILITARY",
  PROJECT = "PROJECT",
  agent = "agent",
  broker = "broker",
  self = "self",
  soleEmployer = "soleEmployer",
  PRACTICA = "PRACTICA",
}


export type OrgIndustryInput = {
  IndustryDescription?: string | null,
  primaryIndicator?: boolean | null,
};

export type LicenseOrCertificationInput = {
  IssuingAuthority?: LicenseOrCertificationIssuingAuthorityInput | null,
  Id?: string | null,
  EffectiveDate?: LicenseOrCertificationEffectiveDateInput | null,
  LicensesOrCertificationName?: string | null,
  Description?: string | null,
};

export type LicenseOrCertificationIssuingAuthorityInput = {
  IssuingAuthorityName?: string | null,
  countryCode?: string | null,
};

export type LicenseOrCertificationEffectiveDateInput = {
  ValidFrom?: string | null,
  FirstIssuedDate?: string | null,
  ValidTo?: string | null,
};

export type LanguageInput = {
  CompetencyId?: CompetencyIdInput | null,
  Proficiency?: LanguageProficiency | null,
  Speak?: boolean | null,
  LanguageCode?: string | null,
  Write?: boolean | null,
  Read?: boolean | null,
};

export enum LanguageProficiency {
  NATIVE = "NATIVE",
  EXCELLENT = "EXCELLENT",
  FLUENT = "FLUENT",
  ADVANCED = "ADVANCED",
  INTERMEDIATE = "INTERMEDIATE",
  BASIC = "BASIC",
}


export type LegalDocumentInput = {
  IssueCountryCode?: string | null,
  IssueAuthority?: string | null,
  IssueDate?: string | null,
  Number?: string | null,
  IssueLocationSummary?: LocationSummaryInput | null,
  type?: string | null,
};

export enum MaritalStatus {
  CIVILUNION = "CIVILUNION",
  COHABITATING = "COHABITATING",
  DIVORCED = "DIVORCED",
  ENGAGED = "ENGAGED",
  MARRIED = "MARRIED",
  SEPARATED = "SEPARATED",
  SINGLE = "SINGLE",
  UNKNOWN = "UNKNOWN",
  WIDOWED = "WIDOWED",
}


export type PersonNameInput = {
  FormattedName?: string | null,
  Title?: string | null,
  Suffix?: string | null,
  FamilyName?: string | null,
  GivenName?: string | null,
  PreferredGivenName?: string | null,
  MiddleName?: string | null,
  sex?: Gender | null,
};

export enum Gender {
  Female = "Female",
  Male = "Male",
}


export enum PreferredPositionType {
  CONTRACT = "CONTRACT",
  TEMPORARY = "TEMPORARY",
  PERMANENT = "PERMANENT",
}


export enum PreferredTypeOfHours {
  FULLTIME = "FULLTIME",
  PARTTIME = "PARTTIME",
}


export type PublicationInput = {
  Comments?: string | null,
  content?: Array< string | null > | null,
  Issue?: string | null,
  JournalOrSerialName?: string | null,
  Volume?: string | null,
  PageNumber?: string | null,
  Name?: Array< PublicationNameInput | null > | null,
  Title?: Array< string | null > | null,
  PublicationDate?: string | null,
};

export type PublicationNameInput = {
  FormattedName?: string | null,
  role?: string | null,
};

export type ReferenceInput = {
  CompanyName?: string | null,
  PersonName?: PersonNameInput | null,
  ContactMethod?: ContactMethodInput | null,
  PositionTitle?: string | null,
};

export type SalaryInput = {
  currency?: string | null,
  amount?: number | null,
  hiamount?: number | null,
  period?: SalaryPeriod | null,
};

export enum SalaryPeriod {
  YEAR = "YEAR",
  QUARTER = "QUARTER",
  MONTH = "MONTH",
  SEMIMONTHLY = "SEMIMONTHLY",
  BIWEEKLY = "BIWEEKLY",
  WEEK = "WEEK",
  DAY = "DAY",
  HOUR = "HOUR",
}


export type ModelCandidateResumeConditionInput = {
  submitted?: ModelBooleanInput | null,
  verified?: ModelBooleanInput | null,
  Achievements?: ModelStringInput | null,
  Available?: ModelStringInput | null,
  DOB?: ModelStringInput | null,
  ExecutiveSummary?: ModelStringInput | null,
  Hobbies?: ModelStringInput | null,
  lang?: ModelStringInput | null,
  MaritalStatus?: ModelMaritalStatusInput | null,
  Nationality?: ModelStringInput | null,
  OtherInfo?: ModelStringInput | null,
  PoliticalStatus?: ModelStringInput | null,
  PreferredLocation?: ModelStringInput | null,
  PreferredPosition?: ModelStringInput | null,
  PreferredPositionType?: ModelPreferredPositionTypeListInput | null,
  PreferredTypeOfHours?: ModelPreferredTypeOfHoursListInput | null,
  Race?: ModelStringInput | null,
  Religion?: ModelStringInput | null,
  RevisionDate?: ModelStringInput | null,
  WillRelocate?: ModelBooleanInput | null,
  RawData?: ModelStringInput | null,
  and?: Array< ModelCandidateResumeConditionInput | null > | null,
  or?: Array< ModelCandidateResumeConditionInput | null > | null,
  not?: ModelCandidateResumeConditionInput | null,
};

export type ModelBooleanInput = {
  ne?: boolean | null,
  eq?: boolean | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export enum ModelAttributeTypes {
  binary = "binary",
  binarySet = "binarySet",
  bool = "bool",
  list = "list",
  map = "map",
  number = "number",
  numberSet = "numberSet",
  string = "string",
  stringSet = "stringSet",
  _null = "_null",
}


export type ModelStringInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export type ModelSizeInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
};

export type ModelMaritalStatusInput = {
  eq?: MaritalStatus | null,
  ne?: MaritalStatus | null,
};

export type ModelPreferredPositionTypeListInput = {
  eq?: Array< PreferredPositionType | null > | null,
  ne?: Array< PreferredPositionType | null > | null,
  contains?: PreferredPositionType | null,
  notContains?: PreferredPositionType | null,
};

export type ModelPreferredTypeOfHoursListInput = {
  eq?: Array< PreferredTypeOfHours | null > | null,
  ne?: Array< PreferredTypeOfHours | null > | null,
  contains?: PreferredTypeOfHours | null,
  notContains?: PreferredTypeOfHours | null,
};

export type CandidateResume = {
  __typename: "CandidateResume",
  id: string,
  owner: string,
  submitted?: boolean | null,
  verified?: boolean | null,
  ExperienceSummary?: ExperienceSummary | null,
  Achievements?: Array< string | null > | null,
  Available?: string | null,
  Competency?:  Array<Competency | null > | null,
  ContactMethod?: ContactMethod | null,
  DOB?: string | null,
  EducationHistory?:  Array<Education | null > | null,
  EmploymentHistory?:  Array<Employment | null > | null,
  ExecutiveSummary?: string | null,
  Hobbies?: string | null,
  LicensesAndCertifications?:  Array<LicenseOrCertification | null > | null,
  Languages?:  Array<Language | null > | null,
  lang?: string | null,
  LegalDocuments?:  Array<LegalDocument | null > | null,
  MaritalStatus?: MaritalStatus | null,
  Nationality?: Array< string | null > | null,
  OtherInfo?: string | null,
  PersonName?: PersonName | null,
  PlaceOfBirth?: LocationSummary | null,
  PoliticalStatus?: string | null,
  PreferredLocation?: string | null,
  PreferredPosition?: string | null,
  PreferredPositionType?: Array< PreferredPositionType | null > | null,
  PreferredTypeOfHours?: Array< PreferredTypeOfHours | null > | null,
  ProjectHistory?:  Array<Employment | null > | null,
  PublicationHistory?:  Array<Publication | null > | null,
  Race?: string | null,
  References?:  Array<Reference | null > | null,
  RegisteredResidence?: LocationSummary | null,
  Religion?: string | null,
  RevisionDate?: string | null,
  Salary_desired?: Salary | null,
  Salary_current?: Salary | null,
  WillRelocate?: boolean | null,
  RawData?: string | null,
  createdAt: string,
  updatedAt: string,
};

export type ExperienceSummary = {
  __typename: "ExperienceSummary",
  TotalYearsOfManagementWorkExperience?: number | null,
  TotalYearsOfLowLevelManagementWorkExperience?: number | null,
  TotalMonthsOfWorkExperience?: number | null,
  ExecutiveBrief?: string | null,
  TotalMonthsOfLowLevelManagementWorkExperience?: number | null,
  TotalMonthsOfManagementWorkExperience?: number | null,
  TotalYearsOfMidLevelManagementWorkExperience?: number | null,
  TotalMonthsOfMidLevelManagementWorkExperience?: number | null,
  TotalYearsOfSeniorManagementWorkExperience?: number | null,
  TotalMonthsOfSeniorManagementWorkExperience?: number | null,
  ManagementRecord?: string | null,
  HighestEducationalLevel?: DegreeType | null,
  TotalYearsOfWorkExperience?: number | null,
};

export type Competency = {
  __typename: "Competency",
  skillLevel?: number | null,
  skillUsed?: CompetencySkillUsed | null,
  skillProficiency?: SkillProficiencyList | null,
  Grade?: string | null,
  skillName?: string | null,
  description?: string | null,
  auth?: string | null,
  skillCount?: number | null,
  lastUsed?: string | null,
  CompetencyId?: CompetencyId | null,
  TaxonomyId?: TaxonomyId | null,
  skillAliasArray?: Array< string | null > | null,
};

export type CompetencySkillUsed = {
  __typename: "CompetencySkillUsed",
  value?: number | null,
  type?: SkillUsedType | null,
};

export type CompetencyId = {
  __typename: "CompetencyId",
  idOwner?: string | null,
  id?: string | null,
};

export type TaxonomyId = {
  __typename: "TaxonomyId",
  idOwner?: string | null,
  description?: string | null,
};

export type ContactMethod = {
  __typename: "ContactMethod",
  InternetWebAddress?:  Array<InternetWebAddress | null > | null,
  InternetEmailAddress_main?: string | null,
  InternetEmailAddress_alt?: string | null,
  Telephone_home?: string | null,
  Telephone_work?: string | null,
  Telephone_alt?: string | null,
  Telephone_mobile?: string | null,
  Pager?: string | null,
  Fax?: string | null,
  PostalAddress_main?: LocationSummary | null,
  PostalAddress_alt?: LocationSummary | null,
};

export type InternetWebAddress = {
  __typename: "InternetWebAddress",
  content?: string | null,
  type?: string | null,
};

export type LocationSummary = {
  __typename: "LocationSummary",
  CountryCode?: string | null,
  Municipality?: string | null,
  Region?: string | null,
  AddressLine?: string | null,
  PostalCode?: string | null,
};

export type Education = {
  __typename: "Education",
  StartDate?: string | null,
  EndDate?: string | null,
  MeasureValue?: string | null,
  Major?: string | null,
  SchoolUnit?: string | null,
  Degree?: Degree | null,
  LocationSummary?: LocationSummary | null,
  SchoolName?: string | null,
  schoolType?: SchoolType | null,
  Comments?: string | null,
};

export type Degree = {
  __typename: "Degree",
  degreeType?: string | null,
  DegreeName?: string | null,
  DegreeDate?: string | null,
};

export type Employment = {
  __typename: "Employment",
  StartDate?: string | null,
  EndDate?: string | null,
  LocationSummary?: LocationSummary | null,
  InternetDomainName?: string | null,
  EmployerOrgName?: string | null,
  employerOrgType?: OrgType | null,
  PositionType?: string | null,
  MonthsOfWork?: number | null,
  TimelineNumber?: number | null,
  Title?: string | null,
  ReasonForLeaving?: string | null,
  OrgIndustry?: OrgIndustry | null,
  JobArea?: string | null,
  JobGrade?: string | null,
  Project?: string | null,
  OrgName?: string | null,
  Description?: string | null,
};

export type OrgIndustry = {
  __typename: "OrgIndustry",
  IndustryDescription?: string | null,
  primaryIndicator?: boolean | null,
};

export type LicenseOrCertification = {
  __typename: "LicenseOrCertification",
  IssuingAuthority?: LicenseOrCertificationIssuingAuthority | null,
  Id?: string | null,
  EffectiveDate?: LicenseOrCertificationEffectiveDate | null,
  LicensesOrCertificationName?: string | null,
  Description?: string | null,
};

export type LicenseOrCertificationIssuingAuthority = {
  __typename: "LicenseOrCertificationIssuingAuthority",
  IssuingAuthorityName?: string | null,
  countryCode?: string | null,
};

export type LicenseOrCertificationEffectiveDate = {
  __typename: "LicenseOrCertificationEffectiveDate",
  ValidFrom?: string | null,
  FirstIssuedDate?: string | null,
  ValidTo?: string | null,
};

export type Language = {
  __typename: "Language",
  CompetencyId?: CompetencyId | null,
  Proficiency?: LanguageProficiency | null,
  Speak?: boolean | null,
  LanguageCode?: string | null,
  Write?: boolean | null,
  Read?: boolean | null,
};

export type LegalDocument = {
  __typename: "LegalDocument",
  IssueCountryCode?: string | null,
  IssueAuthority?: string | null,
  IssueDate?: string | null,
  Number?: string | null,
  IssueLocationSummary?: LocationSummary | null,
  type?: string | null,
};

export type PersonName = {
  __typename: "PersonName",
  FormattedName?: string | null,
  Title?: string | null,
  Suffix?: string | null,
  FamilyName?: string | null,
  GivenName?: string | null,
  PreferredGivenName?: string | null,
  MiddleName?: string | null,
  sex?: Gender | null,
};

export type Publication = {
  __typename: "Publication",
  Comments?: string | null,
  content?: Array< string | null > | null,
  Issue?: string | null,
  JournalOrSerialName?: string | null,
  Volume?: string | null,
  PageNumber?: string | null,
  Name?:  Array<PublicationName | null > | null,
  Title?: Array< string | null > | null,
  PublicationDate?: string | null,
};

export type PublicationName = {
  __typename: "PublicationName",
  FormattedName?: string | null,
  role?: string | null,
};

export type Reference = {
  __typename: "Reference",
  CompanyName?: string | null,
  PersonName?: PersonName | null,
  ContactMethod?: ContactMethod | null,
  PositionTitle?: string | null,
};

export type Salary = {
  __typename: "Salary",
  currency?: string | null,
  amount?: number | null,
  hiamount?: number | null,
  period?: SalaryPeriod | null,
};

export type UpdateCandidateResumeInput = {
  id: string,
  owner?: string | null,
  submitted?: boolean | null,
  verified?: boolean | null,
  ExperienceSummary?: ExperienceSummaryInput | null,
  Achievements?: Array< string | null > | null,
  Available?: string | null,
  Competency?: Array< CompetencyInput | null > | null,
  ContactMethod?: ContactMethodInput | null,
  DOB?: string | null,
  EducationHistory?: Array< EducationInput | null > | null,
  EmploymentHistory?: Array< EmploymentInput | null > | null,
  ExecutiveSummary?: string | null,
  Hobbies?: string | null,
  LicensesAndCertifications?: Array< LicenseOrCertificationInput | null > | null,
  Languages?: Array< LanguageInput | null > | null,
  lang?: string | null,
  LegalDocuments?: Array< LegalDocumentInput | null > | null,
  MaritalStatus?: MaritalStatus | null,
  Nationality?: Array< string | null > | null,
  OtherInfo?: string | null,
  PersonName?: PersonNameInput | null,
  PlaceOfBirth?: LocationSummaryInput | null,
  PoliticalStatus?: string | null,
  PreferredLocation?: string | null,
  PreferredPosition?: string | null,
  PreferredPositionType?: Array< PreferredPositionType | null > | null,
  PreferredTypeOfHours?: Array< PreferredTypeOfHours | null > | null,
  ProjectHistory?: Array< EmploymentInput | null > | null,
  PublicationHistory?: Array< PublicationInput | null > | null,
  Race?: string | null,
  References?: Array< ReferenceInput | null > | null,
  RegisteredResidence?: LocationSummaryInput | null,
  Religion?: string | null,
  RevisionDate?: string | null,
  Salary_desired?: SalaryInput | null,
  Salary_current?: SalaryInput | null,
  WillRelocate?: boolean | null,
  RawData?: string | null,
};

export type DeleteCandidateResumeInput = {
  id: string,
};

export type CreateTimesheetInput = {
  id?: string | null,
  owner: string,
  user?: UserInput | null,
  contractId?: number | null,
  employeeId?: number | null,
  employeeName?: string | null,
  customerId?: string | null,
  customerName?: string | null,
  jobId?: number | null,
  status?: TimesheetStatus | null,
  state?: TimesheetState | null,
  submitted?: boolean | null,
  submittedBy?: string | null,
  submittedByName?: string | null,
  submittedDate?: string | null,
  approver1?: string | null,
  approver1Name?: string | null,
  approver1Token?: string | null,
  approved1?: boolean | null,
  approved1By?: string | null,
  approved1ByName?: string | null,
  approved1Date?: string | null,
  approver2?: string | null,
  approver2Name?: string | null,
  approver2Token?: string | null,
  approved2?: boolean | null,
  approved2By?: string | null,
  approved2ByName?: string | null,
  approved2Date?: string | null,
  fromDate?: string | null,
  toDate?: string | null,
  description?: string | null,
  entries?: Array< TimesheetEntryInput | null > | null,
  managersComments?: Array< TimesheetManagerCommentInput | null > | null,
  statusChanges?: Array< TimesheetStatusChangeInput | null > | null,
};

export type UserInput = {
  id: string,
  email?: string | null,
  name?: string | null,
  userType?: string | null,
  odooId?: string | null,
  customerId?: number | null,
};

export enum TimesheetStatus {
  PENDING = "PENDING",
  SUBMITTED = "SUBMITTED",
  APPROVED = "APPROVED",
  REJECTED = "REJECTED",
}


export enum TimesheetState {
  PENDING = "PENDING",
  SAVED_TO_ERP = "SAVED_TO_ERP",
}


export type TimesheetEntryInput = {
  date: string,
  type?: DayType | null,
  leaveType?: number | null,
  leaveId?: number | null,
};

export enum DayType {
  WORKED = "WORKED",
  HOLIDAY = "HOLIDAY",
  LEAVE = "LEAVE",
  CUSTOM_HOLIDAY = "CUSTOM_HOLIDAY",
}


export type TimesheetManagerCommentInput = {
  date: string,
  managerId?: string | null,
  message?: string | null,
};

export type TimesheetStatusChangeInput = {
  date: string,
  userId?: string | null,
  status?: TimesheetStatus | null,
  message?: string | null,
};

export type ModelTimesheetConditionInput = {
  contractId?: ModelIntInput | null,
  employeeId?: ModelIntInput | null,
  employeeName?: ModelStringInput | null,
  customerName?: ModelStringInput | null,
  jobId?: ModelIntInput | null,
  status?: ModelTimesheetStatusInput | null,
  state?: ModelTimesheetStateInput | null,
  submitted?: ModelBooleanInput | null,
  submittedBy?: ModelStringInput | null,
  submittedByName?: ModelStringInput | null,
  submittedDate?: ModelStringInput | null,
  approver1?: ModelStringInput | null,
  approver1Name?: ModelStringInput | null,
  approver1Token?: ModelStringInput | null,
  approved1?: ModelBooleanInput | null,
  approved1By?: ModelStringInput | null,
  approved1ByName?: ModelStringInput | null,
  approved1Date?: ModelStringInput | null,
  approver2?: ModelStringInput | null,
  approver2Name?: ModelStringInput | null,
  approver2Token?: ModelStringInput | null,
  approved2?: ModelBooleanInput | null,
  approved2By?: ModelStringInput | null,
  approved2ByName?: ModelStringInput | null,
  approved2Date?: ModelStringInput | null,
  fromDate?: ModelStringInput | null,
  toDate?: ModelStringInput | null,
  description?: ModelStringInput | null,
  and?: Array< ModelTimesheetConditionInput | null > | null,
  or?: Array< ModelTimesheetConditionInput | null > | null,
  not?: ModelTimesheetConditionInput | null,
};

export type ModelIntInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type ModelTimesheetStatusInput = {
  eq?: TimesheetStatus | null,
  ne?: TimesheetStatus | null,
};

export type ModelTimesheetStateInput = {
  eq?: TimesheetState | null,
  ne?: TimesheetState | null,
};

export type Timesheet = {
  __typename: "Timesheet",
  id: string,
  owner: string,
  user?: User | null,
  contractId?: number | null,
  employeeId?: number | null,
  employeeName?: string | null,
  customerId?: string | null,
  customerName?: string | null,
  jobId?: number | null,
  status?: TimesheetStatus | null,
  state?: TimesheetState | null,
  submitted?: boolean | null,
  submittedBy?: string | null,
  submittedByName?: string | null,
  submittedDate?: string | null,
  approver1?: string | null,
  approver1Name?: string | null,
  approver1Token?: string | null,
  approved1?: boolean | null,
  approved1By?: string | null,
  approved1ByName?: string | null,
  approved1Date?: string | null,
  approver2?: string | null,
  approver2Name?: string | null,
  approver2Token?: string | null,
  approved2?: boolean | null,
  approved2By?: string | null,
  approved2ByName?: string | null,
  approved2Date?: string | null,
  fromDate?: string | null,
  toDate?: string | null,
  description?: string | null,
  entries?:  Array<TimesheetEntry | null > | null,
  managersComments?:  Array<TimesheetManagerComment | null > | null,
  statusChanges?:  Array<TimesheetStatusChange | null > | null,
  createdAt: string,
  updatedAt: string,
};

export type User = {
  __typename: "User",
  id: string,
  email?: string | null,
  name?: string | null,
  userType?: string | null,
  odooId?: string | null,
  customerId?: number | null,
};

export type TimesheetEntry = {
  __typename: "TimesheetEntry",
  date: string,
  type?: DayType | null,
  leaveType?: number | null,
  leaveId?: number | null,
};

export type TimesheetManagerComment = {
  __typename: "TimesheetManagerComment",
  date: string,
  managerId?: string | null,
  message?: string | null,
};

export type TimesheetStatusChange = {
  __typename: "TimesheetStatusChange",
  date: string,
  userId?: string | null,
  status?: TimesheetStatus | null,
  message?: string | null,
};

export type DeleteTimesheetInput = {
  id: string,
};

export type UpdateTimesheetInput = {
  id: string,
  owner?: string | null,
  user?: UserInput | null,
  contractId?: number | null,
  employeeId?: number | null,
  employeeName?: string | null,
  customerId?: string | null,
  customerName?: string | null,
  jobId?: number | null,
  status?: TimesheetStatus | null,
  state?: TimesheetState | null,
  submitted?: boolean | null,
  submittedBy?: string | null,
  submittedByName?: string | null,
  submittedDate?: string | null,
  approver1?: string | null,
  approver1Name?: string | null,
  approver1Token?: string | null,
  approved1?: boolean | null,
  approved1By?: string | null,
  approved1ByName?: string | null,
  approved1Date?: string | null,
  approver2?: string | null,
  approver2Name?: string | null,
  approver2Token?: string | null,
  approved2?: boolean | null,
  approved2By?: string | null,
  approved2ByName?: string | null,
  approved2Date?: string | null,
  fromDate?: string | null,
  toDate?: string | null,
  description?: string | null,
  entries?: Array< TimesheetEntryInput | null > | null,
  managersComments?: Array< TimesheetManagerCommentInput | null > | null,
  statusChanges?: Array< TimesheetStatusChangeInput | null > | null,
};

export type CreateNotificationInput = {
  id?: string | null,
  status?: NotificationStatus | null,
  date?: string | null,
  user?: string | null,
  template?: string | null,
  parameters?: string | null,
  timesheetId?: string | null,
};

export enum NotificationStatus {
  NEW = "NEW",
  SENT = "SENT",
}


export type ModelNotificationConditionInput = {
  status?: ModelNotificationStatusInput | null,
  date?: ModelStringInput | null,
  user?: ModelStringInput | null,
  template?: ModelStringInput | null,
  parameters?: ModelStringInput | null,
  timesheetId?: ModelStringInput | null,
  and?: Array< ModelNotificationConditionInput | null > | null,
  or?: Array< ModelNotificationConditionInput | null > | null,
  not?: ModelNotificationConditionInput | null,
};

export type ModelNotificationStatusInput = {
  eq?: NotificationStatus | null,
  ne?: NotificationStatus | null,
};

export type Notification = {
  __typename: "Notification",
  id: string,
  status?: NotificationStatus | null,
  date?: string | null,
  user?: string | null,
  template?: string | null,
  parameters?: string | null,
  timesheetId?: string | null,
  createdAt: string,
  updatedAt: string,
};

export type UpdateNotificationInput = {
  id: string,
  status?: NotificationStatus | null,
  date?: string | null,
  user?: string | null,
  template?: string | null,
  parameters?: string | null,
  timesheetId?: string | null,
};

export type DeleteNotificationInput = {
  id: string,
};

export type CreateRequestInput = {
  id?: string | null,
  owner: string,
  user?: UserInput | null,
  employeeId?: number | null,
  employeeName?: string | null,
  status?: RequestStatus | null,
  type?: RequestType | null,
  requestDate?: string | null,
  entityName?: string | null,
  notes?: string | null,
  drivingInstitureName?: string | null,
  emirate?: string | null,
  country?: string | null,
  dateFrom?: string | null,
  dateTo?: string | null,
  expectedAmount?: string | null,
  receivedAmount?: string | null,
  months?: string | null,
};

export enum RequestStatus {
  PENDING = "PENDING",
  COMPLETED = "COMPLETED",
  CANCELLED = "CANCELLED",
  REJECTED = "REJECTED",
}


export enum RequestType {
  SALARY_CERTIFICATE = "SALARY_CERTIFICATE",
  EXPERIENCE_LETTER = "EXPERIENCE_LETTER",
  EMPLOYMENT_LETTER = "EMPLOYMENT_LETTER",
  SALARY_TRANSFER_LETTER = "SALARY_TRANSFER_LETTER",
  NOC_DRIVING_LICENSE = "NOC_DRIVING_LICENSE",
  NOC_TRAVEL = "NOC_TRAVEL",
  PAYROLL_NOT_RECEIVED = "PAYROLL_NOT_RECEIVED",
  PAYROLL_WRONG_AMOUNT = "PAYROLL_WRONG_AMOUNT",
  PAYROLL_PAYSLIP = "PAYROLL_PAYSLIP",
  MEDICAL_INSURANCE = "MEDICAL_INSURANCE",
  TIMESHEET_QUERY = "TIMESHEET_QUERY",
}


export type ModelRequestConditionInput = {
  employeeId?: ModelIntInput | null,
  employeeName?: ModelStringInput | null,
  status?: ModelRequestStatusInput | null,
  type?: ModelRequestTypeInput | null,
  requestDate?: ModelStringInput | null,
  entityName?: ModelStringInput | null,
  notes?: ModelStringInput | null,
  drivingInstitureName?: ModelStringInput | null,
  emirate?: ModelStringInput | null,
  country?: ModelStringInput | null,
  dateFrom?: ModelStringInput | null,
  dateTo?: ModelStringInput | null,
  expectedAmount?: ModelStringInput | null,
  receivedAmount?: ModelStringInput | null,
  months?: ModelStringInput | null,
  and?: Array< ModelRequestConditionInput | null > | null,
  or?: Array< ModelRequestConditionInput | null > | null,
  not?: ModelRequestConditionInput | null,
};

export type ModelRequestStatusInput = {
  eq?: RequestStatus | null,
  ne?: RequestStatus | null,
};

export type ModelRequestTypeInput = {
  eq?: RequestType | null,
  ne?: RequestType | null,
};

export type Request = {
  __typename: "Request",
  id: string,
  owner: string,
  user?: User | null,
  employeeId?: number | null,
  employeeName?: string | null,
  status?: RequestStatus | null,
  type?: RequestType | null,
  requestDate?: string | null,
  entityName?: string | null,
  notes?: string | null,
  drivingInstitureName?: string | null,
  emirate?: string | null,
  country?: string | null,
  dateFrom?: string | null,
  dateTo?: string | null,
  expectedAmount?: string | null,
  receivedAmount?: string | null,
  months?: string | null,
  createdAt: string,
  updatedAt: string,
};

export type DeleteRequestInput = {
  id: string,
};

export type UpdateRequestInput = {
  id: string,
  owner?: string | null,
  user?: UserInput | null,
  employeeId?: number | null,
  employeeName?: string | null,
  status?: RequestStatus | null,
  type?: RequestType | null,
  requestDate?: string | null,
  entityName?: string | null,
  notes?: string | null,
  drivingInstitureName?: string | null,
  emirate?: string | null,
  country?: string | null,
  dateFrom?: string | null,
  dateTo?: string | null,
  expectedAmount?: string | null,
  receivedAmount?: string | null,
  months?: string | null,
};

export type ModelUserConnection = {
  __typename: "ModelUserConnection",
  items?:  Array<User | null > | null,
  nextToken?: string | null,
};

export type ModelCandidateResumeFilterInput = {
  id?: ModelIDInput | null,
  owner?: ModelStringInput | null,
  submitted?: ModelBooleanInput | null,
  verified?: ModelBooleanInput | null,
  Achievements?: ModelStringInput | null,
  Available?: ModelStringInput | null,
  DOB?: ModelStringInput | null,
  ExecutiveSummary?: ModelStringInput | null,
  Hobbies?: ModelStringInput | null,
  lang?: ModelStringInput | null,
  MaritalStatus?: ModelMaritalStatusInput | null,
  Nationality?: ModelStringInput | null,
  OtherInfo?: ModelStringInput | null,
  PoliticalStatus?: ModelStringInput | null,
  PreferredLocation?: ModelStringInput | null,
  PreferredPosition?: ModelStringInput | null,
  PreferredPositionType?: ModelPreferredPositionTypeListInput | null,
  PreferredTypeOfHours?: ModelPreferredTypeOfHoursListInput | null,
  Race?: ModelStringInput | null,
  Religion?: ModelStringInput | null,
  RevisionDate?: ModelStringInput | null,
  WillRelocate?: ModelBooleanInput | null,
  RawData?: ModelStringInput | null,
  and?: Array< ModelCandidateResumeFilterInput | null > | null,
  or?: Array< ModelCandidateResumeFilterInput | null > | null,
  not?: ModelCandidateResumeFilterInput | null,
};

export type ModelIDInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export type ModelCandidateResumeConnection = {
  __typename: "ModelCandidateResumeConnection",
  items:  Array<CandidateResume | null >,
  nextToken?: string | null,
};

export type ModelTimesheetFilterInput = {
  id?: ModelIDInput | null,
  owner?: ModelStringInput | null,
  contractId?: ModelIntInput | null,
  employeeId?: ModelIntInput | null,
  employeeName?: ModelStringInput | null,
  customerId?: ModelStringInput | null,
  customerName?: ModelStringInput | null,
  jobId?: ModelIntInput | null,
  status?: ModelTimesheetStatusInput | null,
  state?: ModelTimesheetStateInput | null,
  submitted?: ModelBooleanInput | null,
  submittedBy?: ModelStringInput | null,
  submittedByName?: ModelStringInput | null,
  submittedDate?: ModelStringInput | null,
  approver1?: ModelStringInput | null,
  approver1Name?: ModelStringInput | null,
  approver1Token?: ModelStringInput | null,
  approved1?: ModelBooleanInput | null,
  approved1By?: ModelStringInput | null,
  approved1ByName?: ModelStringInput | null,
  approved1Date?: ModelStringInput | null,
  approver2?: ModelStringInput | null,
  approver2Name?: ModelStringInput | null,
  approver2Token?: ModelStringInput | null,
  approved2?: ModelBooleanInput | null,
  approved2By?: ModelStringInput | null,
  approved2ByName?: ModelStringInput | null,
  approved2Date?: ModelStringInput | null,
  fromDate?: ModelStringInput | null,
  toDate?: ModelStringInput | null,
  description?: ModelStringInput | null,
  and?: Array< ModelTimesheetFilterInput | null > | null,
  or?: Array< ModelTimesheetFilterInput | null > | null,
  not?: ModelTimesheetFilterInput | null,
};

export type ModelTimesheetConnection = {
  __typename: "ModelTimesheetConnection",
  items:  Array<Timesheet | null >,
  nextToken?: string | null,
};

export type ModelTimesheetByContractorCompositeKeyConditionInput = {
  eq?: ModelTimesheetByContractorCompositeKeyInput | null,
  le?: ModelTimesheetByContractorCompositeKeyInput | null,
  lt?: ModelTimesheetByContractorCompositeKeyInput | null,
  ge?: ModelTimesheetByContractorCompositeKeyInput | null,
  gt?: ModelTimesheetByContractorCompositeKeyInput | null,
  between?: Array< ModelTimesheetByContractorCompositeKeyInput | null > | null,
  beginsWith?: ModelTimesheetByContractorCompositeKeyInput | null,
};

export type ModelTimesheetByContractorCompositeKeyInput = {
  status?: TimesheetStatus | null,
  fromDate?: string | null,
};

export enum ModelSortDirection {
  ASC = "ASC",
  DESC = "DESC",
}


export type ModelStringKeyConditionInput = {
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
};

export type ModelTimesheetByCustomerCompositeKeyConditionInput = {
  eq?: ModelTimesheetByCustomerCompositeKeyInput | null,
  le?: ModelTimesheetByCustomerCompositeKeyInput | null,
  lt?: ModelTimesheetByCustomerCompositeKeyInput | null,
  ge?: ModelTimesheetByCustomerCompositeKeyInput | null,
  gt?: ModelTimesheetByCustomerCompositeKeyInput | null,
  between?: Array< ModelTimesheetByCustomerCompositeKeyInput | null > | null,
  beginsWith?: ModelTimesheetByCustomerCompositeKeyInput | null,
};

export type ModelTimesheetByCustomerCompositeKeyInput = {
  status?: TimesheetStatus | null,
  fromDate?: string | null,
};

export type ModelNotificationFilterInput = {
  id?: ModelIDInput | null,
  status?: ModelNotificationStatusInput | null,
  date?: ModelStringInput | null,
  user?: ModelStringInput | null,
  template?: ModelStringInput | null,
  parameters?: ModelStringInput | null,
  timesheetId?: ModelStringInput | null,
  and?: Array< ModelNotificationFilterInput | null > | null,
  or?: Array< ModelNotificationFilterInput | null > | null,
  not?: ModelNotificationFilterInput | null,
};

export type ModelNotificationConnection = {
  __typename: "ModelNotificationConnection",
  items:  Array<Notification | null >,
  nextToken?: string | null,
};

export type ModelNotificationByTimesheetCompositeKeyConditionInput = {
  eq?: ModelNotificationByTimesheetCompositeKeyInput | null,
  le?: ModelNotificationByTimesheetCompositeKeyInput | null,
  lt?: ModelNotificationByTimesheetCompositeKeyInput | null,
  ge?: ModelNotificationByTimesheetCompositeKeyInput | null,
  gt?: ModelNotificationByTimesheetCompositeKeyInput | null,
  between?: Array< ModelNotificationByTimesheetCompositeKeyInput | null > | null,
  beginsWith?: ModelNotificationByTimesheetCompositeKeyInput | null,
};

export type ModelNotificationByTimesheetCompositeKeyInput = {
  user?: string | null,
  date?: string | null,
};

export type ModelRequestFilterInput = {
  id?: ModelIDInput | null,
  owner?: ModelStringInput | null,
  employeeId?: ModelIntInput | null,
  employeeName?: ModelStringInput | null,
  status?: ModelRequestStatusInput | null,
  type?: ModelRequestTypeInput | null,
  requestDate?: ModelStringInput | null,
  entityName?: ModelStringInput | null,
  notes?: ModelStringInput | null,
  drivingInstitureName?: ModelStringInput | null,
  emirate?: ModelStringInput | null,
  country?: ModelStringInput | null,
  dateFrom?: ModelStringInput | null,
  dateTo?: ModelStringInput | null,
  expectedAmount?: ModelStringInput | null,
  receivedAmount?: ModelStringInput | null,
  months?: ModelStringInput | null,
  and?: Array< ModelRequestFilterInput | null > | null,
  or?: Array< ModelRequestFilterInput | null > | null,
  not?: ModelRequestFilterInput | null,
};

export type ModelRequestConnection = {
  __typename: "ModelRequestConnection",
  items:  Array<Request | null >,
  nextToken?: string | null,
};

export type CreateCandidateResumeMutationVariables = {
  input: CreateCandidateResumeInput,
  condition?: ModelCandidateResumeConditionInput | null,
};

export type CreateCandidateResumeMutation = {
  createCandidateResume?:  {
    __typename: "CandidateResume",
    id: string,
    owner: string,
    submitted?: boolean | null,
    verified?: boolean | null,
    ExperienceSummary?:  {
      __typename: "ExperienceSummary",
      TotalYearsOfManagementWorkExperience?: number | null,
      TotalYearsOfLowLevelManagementWorkExperience?: number | null,
      TotalMonthsOfWorkExperience?: number | null,
      ExecutiveBrief?: string | null,
      TotalMonthsOfLowLevelManagementWorkExperience?: number | null,
      TotalMonthsOfManagementWorkExperience?: number | null,
      TotalYearsOfMidLevelManagementWorkExperience?: number | null,
      TotalMonthsOfMidLevelManagementWorkExperience?: number | null,
      TotalYearsOfSeniorManagementWorkExperience?: number | null,
      TotalMonthsOfSeniorManagementWorkExperience?: number | null,
      ManagementRecord?: string | null,
      HighestEducationalLevel?: DegreeType | null,
      TotalYearsOfWorkExperience?: number | null,
    } | null,
    Achievements?: Array< string | null > | null,
    Available?: string | null,
    Competency?:  Array< {
      __typename: "Competency",
      skillLevel?: number | null,
      skillUsed?:  {
        __typename: "CompetencySkillUsed",
        value?: number | null,
        type?: SkillUsedType | null,
      } | null,
      skillProficiency?: SkillProficiencyList | null,
      Grade?: string | null,
      skillName?: string | null,
      description?: string | null,
      auth?: string | null,
      skillCount?: number | null,
      lastUsed?: string | null,
      CompetencyId?:  {
        __typename: "CompetencyId",
        idOwner?: string | null,
        id?: string | null,
      } | null,
      TaxonomyId?:  {
        __typename: "TaxonomyId",
        idOwner?: string | null,
        description?: string | null,
      } | null,
      skillAliasArray?: Array< string | null > | null,
    } | null > | null,
    ContactMethod?:  {
      __typename: "ContactMethod",
      InternetWebAddress?:  Array< {
        __typename: "InternetWebAddress",
        content?: string | null,
        type?: string | null,
      } | null > | null,
      InternetEmailAddress_main?: string | null,
      InternetEmailAddress_alt?: string | null,
      Telephone_home?: string | null,
      Telephone_work?: string | null,
      Telephone_alt?: string | null,
      Telephone_mobile?: string | null,
      Pager?: string | null,
      Fax?: string | null,
      PostalAddress_main?:  {
        __typename: "LocationSummary",
        CountryCode?: string | null,
        Municipality?: string | null,
        Region?: string | null,
        AddressLine?: string | null,
        PostalCode?: string | null,
      } | null,
      PostalAddress_alt?:  {
        __typename: "LocationSummary",
        CountryCode?: string | null,
        Municipality?: string | null,
        Region?: string | null,
        AddressLine?: string | null,
        PostalCode?: string | null,
      } | null,
    } | null,
    DOB?: string | null,
    EducationHistory?:  Array< {
      __typename: "Education",
      StartDate?: string | null,
      EndDate?: string | null,
      MeasureValue?: string | null,
      Major?: string | null,
      SchoolUnit?: string | null,
      Degree?:  {
        __typename: "Degree",
        degreeType?: string | null,
        DegreeName?: string | null,
        DegreeDate?: string | null,
      } | null,
      LocationSummary?:  {
        __typename: "LocationSummary",
        CountryCode?: string | null,
        Municipality?: string | null,
        Region?: string | null,
        AddressLine?: string | null,
        PostalCode?: string | null,
      } | null,
      SchoolName?: string | null,
      schoolType?: SchoolType | null,
      Comments?: string | null,
    } | null > | null,
    EmploymentHistory?:  Array< {
      __typename: "Employment",
      StartDate?: string | null,
      EndDate?: string | null,
      LocationSummary?:  {
        __typename: "LocationSummary",
        CountryCode?: string | null,
        Municipality?: string | null,
        Region?: string | null,
        AddressLine?: string | null,
        PostalCode?: string | null,
      } | null,
      InternetDomainName?: string | null,
      EmployerOrgName?: string | null,
      employerOrgType?: OrgType | null,
      PositionType?: string | null,
      MonthsOfWork?: number | null,
      TimelineNumber?: number | null,
      Title?: string | null,
      ReasonForLeaving?: string | null,
      OrgIndustry?:  {
        __typename: "OrgIndustry",
        IndustryDescription?: string | null,
        primaryIndicator?: boolean | null,
      } | null,
      JobArea?: string | null,
      JobGrade?: string | null,
      Project?: string | null,
      OrgName?: string | null,
      Description?: string | null,
    } | null > | null,
    ExecutiveSummary?: string | null,
    Hobbies?: string | null,
    LicensesAndCertifications?:  Array< {
      __typename: "LicenseOrCertification",
      IssuingAuthority?:  {
        __typename: "LicenseOrCertificationIssuingAuthority",
        IssuingAuthorityName?: string | null,
        countryCode?: string | null,
      } | null,
      Id?: string | null,
      EffectiveDate?:  {
        __typename: "LicenseOrCertificationEffectiveDate",
        ValidFrom?: string | null,
        FirstIssuedDate?: string | null,
        ValidTo?: string | null,
      } | null,
      LicensesOrCertificationName?: string | null,
      Description?: string | null,
    } | null > | null,
    Languages?:  Array< {
      __typename: "Language",
      CompetencyId?:  {
        __typename: "CompetencyId",
        idOwner?: string | null,
        id?: string | null,
      } | null,
      Proficiency?: LanguageProficiency | null,
      Speak?: boolean | null,
      LanguageCode?: string | null,
      Write?: boolean | null,
      Read?: boolean | null,
    } | null > | null,
    lang?: string | null,
    LegalDocuments?:  Array< {
      __typename: "LegalDocument",
      IssueCountryCode?: string | null,
      IssueAuthority?: string | null,
      IssueDate?: string | null,
      Number?: string | null,
      IssueLocationSummary?:  {
        __typename: "LocationSummary",
        CountryCode?: string | null,
        Municipality?: string | null,
        Region?: string | null,
        AddressLine?: string | null,
        PostalCode?: string | null,
      } | null,
      type?: string | null,
    } | null > | null,
    MaritalStatus?: MaritalStatus | null,
    Nationality?: Array< string | null > | null,
    OtherInfo?: string | null,
    PersonName?:  {
      __typename: "PersonName",
      FormattedName?: string | null,
      Title?: string | null,
      Suffix?: string | null,
      FamilyName?: string | null,
      GivenName?: string | null,
      PreferredGivenName?: string | null,
      MiddleName?: string | null,
      sex?: Gender | null,
    } | null,
    PlaceOfBirth?:  {
      __typename: "LocationSummary",
      CountryCode?: string | null,
      Municipality?: string | null,
      Region?: string | null,
      AddressLine?: string | null,
      PostalCode?: string | null,
    } | null,
    PoliticalStatus?: string | null,
    PreferredLocation?: string | null,
    PreferredPosition?: string | null,
    PreferredPositionType?: Array< PreferredPositionType | null > | null,
    PreferredTypeOfHours?: Array< PreferredTypeOfHours | null > | null,
    ProjectHistory?:  Array< {
      __typename: "Employment",
      StartDate?: string | null,
      EndDate?: string | null,
      LocationSummary?:  {
        __typename: "LocationSummary",
        CountryCode?: string | null,
        Municipality?: string | null,
        Region?: string | null,
        AddressLine?: string | null,
        PostalCode?: string | null,
      } | null,
      InternetDomainName?: string | null,
      EmployerOrgName?: string | null,
      employerOrgType?: OrgType | null,
      PositionType?: string | null,
      MonthsOfWork?: number | null,
      TimelineNumber?: number | null,
      Title?: string | null,
      ReasonForLeaving?: string | null,
      OrgIndustry?:  {
        __typename: "OrgIndustry",
        IndustryDescription?: string | null,
        primaryIndicator?: boolean | null,
      } | null,
      JobArea?: string | null,
      JobGrade?: string | null,
      Project?: string | null,
      OrgName?: string | null,
      Description?: string | null,
    } | null > | null,
    PublicationHistory?:  Array< {
      __typename: "Publication",
      Comments?: string | null,
      content?: Array< string | null > | null,
      Issue?: string | null,
      JournalOrSerialName?: string | null,
      Volume?: string | null,
      PageNumber?: string | null,
      Name?:  Array< {
        __typename: "PublicationName",
        FormattedName?: string | null,
        role?: string | null,
      } | null > | null,
      Title?: Array< string | null > | null,
      PublicationDate?: string | null,
    } | null > | null,
    Race?: string | null,
    References?:  Array< {
      __typename: "Reference",
      CompanyName?: string | null,
      PersonName?:  {
        __typename: "PersonName",
        FormattedName?: string | null,
        Title?: string | null,
        Suffix?: string | null,
        FamilyName?: string | null,
        GivenName?: string | null,
        PreferredGivenName?: string | null,
        MiddleName?: string | null,
        sex?: Gender | null,
      } | null,
      ContactMethod?:  {
        __typename: "ContactMethod",
        InternetEmailAddress_main?: string | null,
        InternetEmailAddress_alt?: string | null,
        Telephone_home?: string | null,
        Telephone_work?: string | null,
        Telephone_alt?: string | null,
        Telephone_mobile?: string | null,
        Pager?: string | null,
        Fax?: string | null,
      } | null,
      PositionTitle?: string | null,
    } | null > | null,
    RegisteredResidence?:  {
      __typename: "LocationSummary",
      CountryCode?: string | null,
      Municipality?: string | null,
      Region?: string | null,
      AddressLine?: string | null,
      PostalCode?: string | null,
    } | null,
    Religion?: string | null,
    RevisionDate?: string | null,
    Salary_desired?:  {
      __typename: "Salary",
      currency?: string | null,
      amount?: number | null,
      hiamount?: number | null,
      period?: SalaryPeriod | null,
    } | null,
    Salary_current?:  {
      __typename: "Salary",
      currency?: string | null,
      amount?: number | null,
      hiamount?: number | null,
      period?: SalaryPeriod | null,
    } | null,
    WillRelocate?: boolean | null,
    RawData?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateCandidateResumeMutationVariables = {
  input: UpdateCandidateResumeInput,
  condition?: ModelCandidateResumeConditionInput | null,
};

export type UpdateCandidateResumeMutation = {
  updateCandidateResume?:  {
    __typename: "CandidateResume",
    id: string,
    owner: string,
    submitted?: boolean | null,
    verified?: boolean | null,
    ExperienceSummary?:  {
      __typename: "ExperienceSummary",
      TotalYearsOfManagementWorkExperience?: number | null,
      TotalYearsOfLowLevelManagementWorkExperience?: number | null,
      TotalMonthsOfWorkExperience?: number | null,
      ExecutiveBrief?: string | null,
      TotalMonthsOfLowLevelManagementWorkExperience?: number | null,
      TotalMonthsOfManagementWorkExperience?: number | null,
      TotalYearsOfMidLevelManagementWorkExperience?: number | null,
      TotalMonthsOfMidLevelManagementWorkExperience?: number | null,
      TotalYearsOfSeniorManagementWorkExperience?: number | null,
      TotalMonthsOfSeniorManagementWorkExperience?: number | null,
      ManagementRecord?: string | null,
      HighestEducationalLevel?: DegreeType | null,
      TotalYearsOfWorkExperience?: number | null,
    } | null,
    Achievements?: Array< string | null > | null,
    Available?: string | null,
    Competency?:  Array< {
      __typename: "Competency",
      skillLevel?: number | null,
      skillUsed?:  {
        __typename: "CompetencySkillUsed",
        value?: number | null,
        type?: SkillUsedType | null,
      } | null,
      skillProficiency?: SkillProficiencyList | null,
      Grade?: string | null,
      skillName?: string | null,
      description?: string | null,
      auth?: string | null,
      skillCount?: number | null,
      lastUsed?: string | null,
      CompetencyId?:  {
        __typename: "CompetencyId",
        idOwner?: string | null,
        id?: string | null,
      } | null,
      TaxonomyId?:  {
        __typename: "TaxonomyId",
        idOwner?: string | null,
        description?: string | null,
      } | null,
      skillAliasArray?: Array< string | null > | null,
    } | null > | null,
    ContactMethod?:  {
      __typename: "ContactMethod",
      InternetWebAddress?:  Array< {
        __typename: "InternetWebAddress",
        content?: string | null,
        type?: string | null,
      } | null > | null,
      InternetEmailAddress_main?: string | null,
      InternetEmailAddress_alt?: string | null,
      Telephone_home?: string | null,
      Telephone_work?: string | null,
      Telephone_alt?: string | null,
      Telephone_mobile?: string | null,
      Pager?: string | null,
      Fax?: string | null,
      PostalAddress_main?:  {
        __typename: "LocationSummary",
        CountryCode?: string | null,
        Municipality?: string | null,
        Region?: string | null,
        AddressLine?: string | null,
        PostalCode?: string | null,
      } | null,
      PostalAddress_alt?:  {
        __typename: "LocationSummary",
        CountryCode?: string | null,
        Municipality?: string | null,
        Region?: string | null,
        AddressLine?: string | null,
        PostalCode?: string | null,
      } | null,
    } | null,
    DOB?: string | null,
    EducationHistory?:  Array< {
      __typename: "Education",
      StartDate?: string | null,
      EndDate?: string | null,
      MeasureValue?: string | null,
      Major?: string | null,
      SchoolUnit?: string | null,
      Degree?:  {
        __typename: "Degree",
        degreeType?: string | null,
        DegreeName?: string | null,
        DegreeDate?: string | null,
      } | null,
      LocationSummary?:  {
        __typename: "LocationSummary",
        CountryCode?: string | null,
        Municipality?: string | null,
        Region?: string | null,
        AddressLine?: string | null,
        PostalCode?: string | null,
      } | null,
      SchoolName?: string | null,
      schoolType?: SchoolType | null,
      Comments?: string | null,
    } | null > | null,
    EmploymentHistory?:  Array< {
      __typename: "Employment",
      StartDate?: string | null,
      EndDate?: string | null,
      LocationSummary?:  {
        __typename: "LocationSummary",
        CountryCode?: string | null,
        Municipality?: string | null,
        Region?: string | null,
        AddressLine?: string | null,
        PostalCode?: string | null,
      } | null,
      InternetDomainName?: string | null,
      EmployerOrgName?: string | null,
      employerOrgType?: OrgType | null,
      PositionType?: string | null,
      MonthsOfWork?: number | null,
      TimelineNumber?: number | null,
      Title?: string | null,
      ReasonForLeaving?: string | null,
      OrgIndustry?:  {
        __typename: "OrgIndustry",
        IndustryDescription?: string | null,
        primaryIndicator?: boolean | null,
      } | null,
      JobArea?: string | null,
      JobGrade?: string | null,
      Project?: string | null,
      OrgName?: string | null,
      Description?: string | null,
    } | null > | null,
    ExecutiveSummary?: string | null,
    Hobbies?: string | null,
    LicensesAndCertifications?:  Array< {
      __typename: "LicenseOrCertification",
      IssuingAuthority?:  {
        __typename: "LicenseOrCertificationIssuingAuthority",
        IssuingAuthorityName?: string | null,
        countryCode?: string | null,
      } | null,
      Id?: string | null,
      EffectiveDate?:  {
        __typename: "LicenseOrCertificationEffectiveDate",
        ValidFrom?: string | null,
        FirstIssuedDate?: string | null,
        ValidTo?: string | null,
      } | null,
      LicensesOrCertificationName?: string | null,
      Description?: string | null,
    } | null > | null,
    Languages?:  Array< {
      __typename: "Language",
      CompetencyId?:  {
        __typename: "CompetencyId",
        idOwner?: string | null,
        id?: string | null,
      } | null,
      Proficiency?: LanguageProficiency | null,
      Speak?: boolean | null,
      LanguageCode?: string | null,
      Write?: boolean | null,
      Read?: boolean | null,
    } | null > | null,
    lang?: string | null,
    LegalDocuments?:  Array< {
      __typename: "LegalDocument",
      IssueCountryCode?: string | null,
      IssueAuthority?: string | null,
      IssueDate?: string | null,
      Number?: string | null,
      IssueLocationSummary?:  {
        __typename: "LocationSummary",
        CountryCode?: string | null,
        Municipality?: string | null,
        Region?: string | null,
        AddressLine?: string | null,
        PostalCode?: string | null,
      } | null,
      type?: string | null,
    } | null > | null,
    MaritalStatus?: MaritalStatus | null,
    Nationality?: Array< string | null > | null,
    OtherInfo?: string | null,
    PersonName?:  {
      __typename: "PersonName",
      FormattedName?: string | null,
      Title?: string | null,
      Suffix?: string | null,
      FamilyName?: string | null,
      GivenName?: string | null,
      PreferredGivenName?: string | null,
      MiddleName?: string | null,
      sex?: Gender | null,
    } | null,
    PlaceOfBirth?:  {
      __typename: "LocationSummary",
      CountryCode?: string | null,
      Municipality?: string | null,
      Region?: string | null,
      AddressLine?: string | null,
      PostalCode?: string | null,
    } | null,
    PoliticalStatus?: string | null,
    PreferredLocation?: string | null,
    PreferredPosition?: string | null,
    PreferredPositionType?: Array< PreferredPositionType | null > | null,
    PreferredTypeOfHours?: Array< PreferredTypeOfHours | null > | null,
    ProjectHistory?:  Array< {
      __typename: "Employment",
      StartDate?: string | null,
      EndDate?: string | null,
      LocationSummary?:  {
        __typename: "LocationSummary",
        CountryCode?: string | null,
        Municipality?: string | null,
        Region?: string | null,
        AddressLine?: string | null,
        PostalCode?: string | null,
      } | null,
      InternetDomainName?: string | null,
      EmployerOrgName?: string | null,
      employerOrgType?: OrgType | null,
      PositionType?: string | null,
      MonthsOfWork?: number | null,
      TimelineNumber?: number | null,
      Title?: string | null,
      ReasonForLeaving?: string | null,
      OrgIndustry?:  {
        __typename: "OrgIndustry",
        IndustryDescription?: string | null,
        primaryIndicator?: boolean | null,
      } | null,
      JobArea?: string | null,
      JobGrade?: string | null,
      Project?: string | null,
      OrgName?: string | null,
      Description?: string | null,
    } | null > | null,
    PublicationHistory?:  Array< {
      __typename: "Publication",
      Comments?: string | null,
      content?: Array< string | null > | null,
      Issue?: string | null,
      JournalOrSerialName?: string | null,
      Volume?: string | null,
      PageNumber?: string | null,
      Name?:  Array< {
        __typename: "PublicationName",
        FormattedName?: string | null,
        role?: string | null,
      } | null > | null,
      Title?: Array< string | null > | null,
      PublicationDate?: string | null,
    } | null > | null,
    Race?: string | null,
    References?:  Array< {
      __typename: "Reference",
      CompanyName?: string | null,
      PersonName?:  {
        __typename: "PersonName",
        FormattedName?: string | null,
        Title?: string | null,
        Suffix?: string | null,
        FamilyName?: string | null,
        GivenName?: string | null,
        PreferredGivenName?: string | null,
        MiddleName?: string | null,
        sex?: Gender | null,
      } | null,
      ContactMethod?:  {
        __typename: "ContactMethod",
        InternetEmailAddress_main?: string | null,
        InternetEmailAddress_alt?: string | null,
        Telephone_home?: string | null,
        Telephone_work?: string | null,
        Telephone_alt?: string | null,
        Telephone_mobile?: string | null,
        Pager?: string | null,
        Fax?: string | null,
      } | null,
      PositionTitle?: string | null,
    } | null > | null,
    RegisteredResidence?:  {
      __typename: "LocationSummary",
      CountryCode?: string | null,
      Municipality?: string | null,
      Region?: string | null,
      AddressLine?: string | null,
      PostalCode?: string | null,
    } | null,
    Religion?: string | null,
    RevisionDate?: string | null,
    Salary_desired?:  {
      __typename: "Salary",
      currency?: string | null,
      amount?: number | null,
      hiamount?: number | null,
      period?: SalaryPeriod | null,
    } | null,
    Salary_current?:  {
      __typename: "Salary",
      currency?: string | null,
      amount?: number | null,
      hiamount?: number | null,
      period?: SalaryPeriod | null,
    } | null,
    WillRelocate?: boolean | null,
    RawData?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteCandidateResumeMutationVariables = {
  input: DeleteCandidateResumeInput,
  condition?: ModelCandidateResumeConditionInput | null,
};

export type DeleteCandidateResumeMutation = {
  deleteCandidateResume?:  {
    __typename: "CandidateResume",
    id: string,
    owner: string,
    submitted?: boolean | null,
    verified?: boolean | null,
    ExperienceSummary?:  {
      __typename: "ExperienceSummary",
      TotalYearsOfManagementWorkExperience?: number | null,
      TotalYearsOfLowLevelManagementWorkExperience?: number | null,
      TotalMonthsOfWorkExperience?: number | null,
      ExecutiveBrief?: string | null,
      TotalMonthsOfLowLevelManagementWorkExperience?: number | null,
      TotalMonthsOfManagementWorkExperience?: number | null,
      TotalYearsOfMidLevelManagementWorkExperience?: number | null,
      TotalMonthsOfMidLevelManagementWorkExperience?: number | null,
      TotalYearsOfSeniorManagementWorkExperience?: number | null,
      TotalMonthsOfSeniorManagementWorkExperience?: number | null,
      ManagementRecord?: string | null,
      HighestEducationalLevel?: DegreeType | null,
      TotalYearsOfWorkExperience?: number | null,
    } | null,
    Achievements?: Array< string | null > | null,
    Available?: string | null,
    Competency?:  Array< {
      __typename: "Competency",
      skillLevel?: number | null,
      skillUsed?:  {
        __typename: "CompetencySkillUsed",
        value?: number | null,
        type?: SkillUsedType | null,
      } | null,
      skillProficiency?: SkillProficiencyList | null,
      Grade?: string | null,
      skillName?: string | null,
      description?: string | null,
      auth?: string | null,
      skillCount?: number | null,
      lastUsed?: string | null,
      CompetencyId?:  {
        __typename: "CompetencyId",
        idOwner?: string | null,
        id?: string | null,
      } | null,
      TaxonomyId?:  {
        __typename: "TaxonomyId",
        idOwner?: string | null,
        description?: string | null,
      } | null,
      skillAliasArray?: Array< string | null > | null,
    } | null > | null,
    ContactMethod?:  {
      __typename: "ContactMethod",
      InternetWebAddress?:  Array< {
        __typename: "InternetWebAddress",
        content?: string | null,
        type?: string | null,
      } | null > | null,
      InternetEmailAddress_main?: string | null,
      InternetEmailAddress_alt?: string | null,
      Telephone_home?: string | null,
      Telephone_work?: string | null,
      Telephone_alt?: string | null,
      Telephone_mobile?: string | null,
      Pager?: string | null,
      Fax?: string | null,
      PostalAddress_main?:  {
        __typename: "LocationSummary",
        CountryCode?: string | null,
        Municipality?: string | null,
        Region?: string | null,
        AddressLine?: string | null,
        PostalCode?: string | null,
      } | null,
      PostalAddress_alt?:  {
        __typename: "LocationSummary",
        CountryCode?: string | null,
        Municipality?: string | null,
        Region?: string | null,
        AddressLine?: string | null,
        PostalCode?: string | null,
      } | null,
    } | null,
    DOB?: string | null,
    EducationHistory?:  Array< {
      __typename: "Education",
      StartDate?: string | null,
      EndDate?: string | null,
      MeasureValue?: string | null,
      Major?: string | null,
      SchoolUnit?: string | null,
      Degree?:  {
        __typename: "Degree",
        degreeType?: string | null,
        DegreeName?: string | null,
        DegreeDate?: string | null,
      } | null,
      LocationSummary?:  {
        __typename: "LocationSummary",
        CountryCode?: string | null,
        Municipality?: string | null,
        Region?: string | null,
        AddressLine?: string | null,
        PostalCode?: string | null,
      } | null,
      SchoolName?: string | null,
      schoolType?: SchoolType | null,
      Comments?: string | null,
    } | null > | null,
    EmploymentHistory?:  Array< {
      __typename: "Employment",
      StartDate?: string | null,
      EndDate?: string | null,
      LocationSummary?:  {
        __typename: "LocationSummary",
        CountryCode?: string | null,
        Municipality?: string | null,
        Region?: string | null,
        AddressLine?: string | null,
        PostalCode?: string | null,
      } | null,
      InternetDomainName?: string | null,
      EmployerOrgName?: string | null,
      employerOrgType?: OrgType | null,
      PositionType?: string | null,
      MonthsOfWork?: number | null,
      TimelineNumber?: number | null,
      Title?: string | null,
      ReasonForLeaving?: string | null,
      OrgIndustry?:  {
        __typename: "OrgIndustry",
        IndustryDescription?: string | null,
        primaryIndicator?: boolean | null,
      } | null,
      JobArea?: string | null,
      JobGrade?: string | null,
      Project?: string | null,
      OrgName?: string | null,
      Description?: string | null,
    } | null > | null,
    ExecutiveSummary?: string | null,
    Hobbies?: string | null,
    LicensesAndCertifications?:  Array< {
      __typename: "LicenseOrCertification",
      IssuingAuthority?:  {
        __typename: "LicenseOrCertificationIssuingAuthority",
        IssuingAuthorityName?: string | null,
        countryCode?: string | null,
      } | null,
      Id?: string | null,
      EffectiveDate?:  {
        __typename: "LicenseOrCertificationEffectiveDate",
        ValidFrom?: string | null,
        FirstIssuedDate?: string | null,
        ValidTo?: string | null,
      } | null,
      LicensesOrCertificationName?: string | null,
      Description?: string | null,
    } | null > | null,
    Languages?:  Array< {
      __typename: "Language",
      CompetencyId?:  {
        __typename: "CompetencyId",
        idOwner?: string | null,
        id?: string | null,
      } | null,
      Proficiency?: LanguageProficiency | null,
      Speak?: boolean | null,
      LanguageCode?: string | null,
      Write?: boolean | null,
      Read?: boolean | null,
    } | null > | null,
    lang?: string | null,
    LegalDocuments?:  Array< {
      __typename: "LegalDocument",
      IssueCountryCode?: string | null,
      IssueAuthority?: string | null,
      IssueDate?: string | null,
      Number?: string | null,
      IssueLocationSummary?:  {
        __typename: "LocationSummary",
        CountryCode?: string | null,
        Municipality?: string | null,
        Region?: string | null,
        AddressLine?: string | null,
        PostalCode?: string | null,
      } | null,
      type?: string | null,
    } | null > | null,
    MaritalStatus?: MaritalStatus | null,
    Nationality?: Array< string | null > | null,
    OtherInfo?: string | null,
    PersonName?:  {
      __typename: "PersonName",
      FormattedName?: string | null,
      Title?: string | null,
      Suffix?: string | null,
      FamilyName?: string | null,
      GivenName?: string | null,
      PreferredGivenName?: string | null,
      MiddleName?: string | null,
      sex?: Gender | null,
    } | null,
    PlaceOfBirth?:  {
      __typename: "LocationSummary",
      CountryCode?: string | null,
      Municipality?: string | null,
      Region?: string | null,
      AddressLine?: string | null,
      PostalCode?: string | null,
    } | null,
    PoliticalStatus?: string | null,
    PreferredLocation?: string | null,
    PreferredPosition?: string | null,
    PreferredPositionType?: Array< PreferredPositionType | null > | null,
    PreferredTypeOfHours?: Array< PreferredTypeOfHours | null > | null,
    ProjectHistory?:  Array< {
      __typename: "Employment",
      StartDate?: string | null,
      EndDate?: string | null,
      LocationSummary?:  {
        __typename: "LocationSummary",
        CountryCode?: string | null,
        Municipality?: string | null,
        Region?: string | null,
        AddressLine?: string | null,
        PostalCode?: string | null,
      } | null,
      InternetDomainName?: string | null,
      EmployerOrgName?: string | null,
      employerOrgType?: OrgType | null,
      PositionType?: string | null,
      MonthsOfWork?: number | null,
      TimelineNumber?: number | null,
      Title?: string | null,
      ReasonForLeaving?: string | null,
      OrgIndustry?:  {
        __typename: "OrgIndustry",
        IndustryDescription?: string | null,
        primaryIndicator?: boolean | null,
      } | null,
      JobArea?: string | null,
      JobGrade?: string | null,
      Project?: string | null,
      OrgName?: string | null,
      Description?: string | null,
    } | null > | null,
    PublicationHistory?:  Array< {
      __typename: "Publication",
      Comments?: string | null,
      content?: Array< string | null > | null,
      Issue?: string | null,
      JournalOrSerialName?: string | null,
      Volume?: string | null,
      PageNumber?: string | null,
      Name?:  Array< {
        __typename: "PublicationName",
        FormattedName?: string | null,
        role?: string | null,
      } | null > | null,
      Title?: Array< string | null > | null,
      PublicationDate?: string | null,
    } | null > | null,
    Race?: string | null,
    References?:  Array< {
      __typename: "Reference",
      CompanyName?: string | null,
      PersonName?:  {
        __typename: "PersonName",
        FormattedName?: string | null,
        Title?: string | null,
        Suffix?: string | null,
        FamilyName?: string | null,
        GivenName?: string | null,
        PreferredGivenName?: string | null,
        MiddleName?: string | null,
        sex?: Gender | null,
      } | null,
      ContactMethod?:  {
        __typename: "ContactMethod",
        InternetEmailAddress_main?: string | null,
        InternetEmailAddress_alt?: string | null,
        Telephone_home?: string | null,
        Telephone_work?: string | null,
        Telephone_alt?: string | null,
        Telephone_mobile?: string | null,
        Pager?: string | null,
        Fax?: string | null,
      } | null,
      PositionTitle?: string | null,
    } | null > | null,
    RegisteredResidence?:  {
      __typename: "LocationSummary",
      CountryCode?: string | null,
      Municipality?: string | null,
      Region?: string | null,
      AddressLine?: string | null,
      PostalCode?: string | null,
    } | null,
    Religion?: string | null,
    RevisionDate?: string | null,
    Salary_desired?:  {
      __typename: "Salary",
      currency?: string | null,
      amount?: number | null,
      hiamount?: number | null,
      period?: SalaryPeriod | null,
    } | null,
    Salary_current?:  {
      __typename: "Salary",
      currency?: string | null,
      amount?: number | null,
      hiamount?: number | null,
      period?: SalaryPeriod | null,
    } | null,
    WillRelocate?: boolean | null,
    RawData?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateTimesheetMutationVariables = {
  input: CreateTimesheetInput,
  condition?: ModelTimesheetConditionInput | null,
};

export type CreateTimesheetMutation = {
  createTimesheet?:  {
    __typename: "Timesheet",
    id: string,
    owner: string,
    user?:  {
      __typename: "User",
      id: string,
      email?: string | null,
      name?: string | null,
      userType?: string | null,
      odooId?: string | null,
      customerId?: number | null,
    } | null,
    contractId?: number | null,
    employeeId?: number | null,
    employeeName?: string | null,
    customerId?: string | null,
    customerName?: string | null,
    jobId?: number | null,
    status?: TimesheetStatus | null,
    state?: TimesheetState | null,
    submitted?: boolean | null,
    submittedBy?: string | null,
    submittedByName?: string | null,
    submittedDate?: string | null,
    approver1?: string | null,
    approver1Name?: string | null,
    approver1Token?: string | null,
    approved1?: boolean | null,
    approved1By?: string | null,
    approved1ByName?: string | null,
    approved1Date?: string | null,
    approver2?: string | null,
    approver2Name?: string | null,
    approver2Token?: string | null,
    approved2?: boolean | null,
    approved2By?: string | null,
    approved2ByName?: string | null,
    approved2Date?: string | null,
    fromDate?: string | null,
    toDate?: string | null,
    description?: string | null,
    entries?:  Array< {
      __typename: "TimesheetEntry",
      date: string,
      type?: DayType | null,
      leaveType?: number | null,
      leaveId?: number | null,
    } | null > | null,
    managersComments?:  Array< {
      __typename: "TimesheetManagerComment",
      date: string,
      managerId?: string | null,
      message?: string | null,
    } | null > | null,
    statusChanges?:  Array< {
      __typename: "TimesheetStatusChange",
      date: string,
      userId?: string | null,
      status?: TimesheetStatus | null,
      message?: string | null,
    } | null > | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteTimesheetMutationVariables = {
  input: DeleteTimesheetInput,
  condition?: ModelTimesheetConditionInput | null,
};

export type DeleteTimesheetMutation = {
  deleteTimesheet?:  {
    __typename: "Timesheet",
    id: string,
    owner: string,
    user?:  {
      __typename: "User",
      id: string,
      email?: string | null,
      name?: string | null,
      userType?: string | null,
      odooId?: string | null,
      customerId?: number | null,
    } | null,
    contractId?: number | null,
    employeeId?: number | null,
    employeeName?: string | null,
    customerId?: string | null,
    customerName?: string | null,
    jobId?: number | null,
    status?: TimesheetStatus | null,
    state?: TimesheetState | null,
    submitted?: boolean | null,
    submittedBy?: string | null,
    submittedByName?: string | null,
    submittedDate?: string | null,
    approver1?: string | null,
    approver1Name?: string | null,
    approver1Token?: string | null,
    approved1?: boolean | null,
    approved1By?: string | null,
    approved1ByName?: string | null,
    approved1Date?: string | null,
    approver2?: string | null,
    approver2Name?: string | null,
    approver2Token?: string | null,
    approved2?: boolean | null,
    approved2By?: string | null,
    approved2ByName?: string | null,
    approved2Date?: string | null,
    fromDate?: string | null,
    toDate?: string | null,
    description?: string | null,
    entries?:  Array< {
      __typename: "TimesheetEntry",
      date: string,
      type?: DayType | null,
      leaveType?: number | null,
      leaveId?: number | null,
    } | null > | null,
    managersComments?:  Array< {
      __typename: "TimesheetManagerComment",
      date: string,
      managerId?: string | null,
      message?: string | null,
    } | null > | null,
    statusChanges?:  Array< {
      __typename: "TimesheetStatusChange",
      date: string,
      userId?: string | null,
      status?: TimesheetStatus | null,
      message?: string | null,
    } | null > | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateTimesheetMutationVariables = {
  input: UpdateTimesheetInput,
  condition?: ModelTimesheetConditionInput | null,
};

export type UpdateTimesheetMutation = {
  updateTimesheet?:  {
    __typename: "Timesheet",
    id: string,
    owner: string,
    user?:  {
      __typename: "User",
      id: string,
      email?: string | null,
      name?: string | null,
      userType?: string | null,
      odooId?: string | null,
      customerId?: number | null,
    } | null,
    contractId?: number | null,
    employeeId?: number | null,
    employeeName?: string | null,
    customerId?: string | null,
    customerName?: string | null,
    jobId?: number | null,
    status?: TimesheetStatus | null,
    state?: TimesheetState | null,
    submitted?: boolean | null,
    submittedBy?: string | null,
    submittedByName?: string | null,
    submittedDate?: string | null,
    approver1?: string | null,
    approver1Name?: string | null,
    approver1Token?: string | null,
    approved1?: boolean | null,
    approved1By?: string | null,
    approved1ByName?: string | null,
    approved1Date?: string | null,
    approver2?: string | null,
    approver2Name?: string | null,
    approver2Token?: string | null,
    approved2?: boolean | null,
    approved2By?: string | null,
    approved2ByName?: string | null,
    approved2Date?: string | null,
    fromDate?: string | null,
    toDate?: string | null,
    description?: string | null,
    entries?:  Array< {
      __typename: "TimesheetEntry",
      date: string,
      type?: DayType | null,
      leaveType?: number | null,
      leaveId?: number | null,
    } | null > | null,
    managersComments?:  Array< {
      __typename: "TimesheetManagerComment",
      date: string,
      managerId?: string | null,
      message?: string | null,
    } | null > | null,
    statusChanges?:  Array< {
      __typename: "TimesheetStatusChange",
      date: string,
      userId?: string | null,
      status?: TimesheetStatus | null,
      message?: string | null,
    } | null > | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateNotificationMutationVariables = {
  input: CreateNotificationInput,
  condition?: ModelNotificationConditionInput | null,
};

export type CreateNotificationMutation = {
  createNotification?:  {
    __typename: "Notification",
    id: string,
    status?: NotificationStatus | null,
    date?: string | null,
    user?: string | null,
    template?: string | null,
    parameters?: string | null,
    timesheetId?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateNotificationMutationVariables = {
  input: UpdateNotificationInput,
  condition?: ModelNotificationConditionInput | null,
};

export type UpdateNotificationMutation = {
  updateNotification?:  {
    __typename: "Notification",
    id: string,
    status?: NotificationStatus | null,
    date?: string | null,
    user?: string | null,
    template?: string | null,
    parameters?: string | null,
    timesheetId?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteNotificationMutationVariables = {
  input: DeleteNotificationInput,
  condition?: ModelNotificationConditionInput | null,
};

export type DeleteNotificationMutation = {
  deleteNotification?:  {
    __typename: "Notification",
    id: string,
    status?: NotificationStatus | null,
    date?: string | null,
    user?: string | null,
    template?: string | null,
    parameters?: string | null,
    timesheetId?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateRequestMutationVariables = {
  input: CreateRequestInput,
  condition?: ModelRequestConditionInput | null,
};

export type CreateRequestMutation = {
  createRequest?:  {
    __typename: "Request",
    id: string,
    owner: string,
    user?:  {
      __typename: "User",
      id: string,
      email?: string | null,
      name?: string | null,
      userType?: string | null,
      odooId?: string | null,
      customerId?: number | null,
    } | null,
    employeeId?: number | null,
    employeeName?: string | null,
    status?: RequestStatus | null,
    type?: RequestType | null,
    requestDate?: string | null,
    entityName?: string | null,
    notes?: string | null,
    drivingInstitureName?: string | null,
    emirate?: string | null,
    country?: string | null,
    dateFrom?: string | null,
    dateTo?: string | null,
    expectedAmount?: string | null,
    receivedAmount?: string | null,
    months?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteRequestMutationVariables = {
  input: DeleteRequestInput,
  condition?: ModelRequestConditionInput | null,
};

export type DeleteRequestMutation = {
  deleteRequest?:  {
    __typename: "Request",
    id: string,
    owner: string,
    user?:  {
      __typename: "User",
      id: string,
      email?: string | null,
      name?: string | null,
      userType?: string | null,
      odooId?: string | null,
      customerId?: number | null,
    } | null,
    employeeId?: number | null,
    employeeName?: string | null,
    status?: RequestStatus | null,
    type?: RequestType | null,
    requestDate?: string | null,
    entityName?: string | null,
    notes?: string | null,
    drivingInstitureName?: string | null,
    emirate?: string | null,
    country?: string | null,
    dateFrom?: string | null,
    dateTo?: string | null,
    expectedAmount?: string | null,
    receivedAmount?: string | null,
    months?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateRequestMutationVariables = {
  input: UpdateRequestInput,
  condition?: ModelRequestConditionInput | null,
};

export type UpdateRequestMutation = {
  updateRequest?:  {
    __typename: "Request",
    id: string,
    owner: string,
    user?:  {
      __typename: "User",
      id: string,
      email?: string | null,
      name?: string | null,
      userType?: string | null,
      odooId?: string | null,
      customerId?: number | null,
    } | null,
    employeeId?: number | null,
    employeeName?: string | null,
    status?: RequestStatus | null,
    type?: RequestType | null,
    requestDate?: string | null,
    entityName?: string | null,
    notes?: string | null,
    drivingInstitureName?: string | null,
    emirate?: string | null,
    country?: string | null,
    dateFrom?: string | null,
    dateTo?: string | null,
    expectedAmount?: string | null,
    receivedAmount?: string | null,
    months?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListUsersQueryVariables = {
  limit?: number | null,
  nextToken?: string | null,
};

export type ListUsersQuery = {
  listUsers?:  {
    __typename: "ModelUserConnection",
    items?:  Array< {
      __typename: "User",
      id: string,
      email?: string | null,
      name?: string | null,
      userType?: string | null,
      odooId?: string | null,
      customerId?: number | null,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type GetUserQueryVariables = {
  id: string,
};

export type GetUserQuery = {
  getUser?:  {
    __typename: "User",
    id: string,
    email?: string | null,
    name?: string | null,
    userType?: string | null,
    odooId?: string | null,
    customerId?: number | null,
  } | null,
};

export type GetCandidateResumeQueryVariables = {
  id: string,
};

export type GetCandidateResumeQuery = {
  getCandidateResume?:  {
    __typename: "CandidateResume",
    id: string,
    owner: string,
    submitted?: boolean | null,
    verified?: boolean | null,
    ExperienceSummary?:  {
      __typename: "ExperienceSummary",
      TotalYearsOfManagementWorkExperience?: number | null,
      TotalYearsOfLowLevelManagementWorkExperience?: number | null,
      TotalMonthsOfWorkExperience?: number | null,
      ExecutiveBrief?: string | null,
      TotalMonthsOfLowLevelManagementWorkExperience?: number | null,
      TotalMonthsOfManagementWorkExperience?: number | null,
      TotalYearsOfMidLevelManagementWorkExperience?: number | null,
      TotalMonthsOfMidLevelManagementWorkExperience?: number | null,
      TotalYearsOfSeniorManagementWorkExperience?: number | null,
      TotalMonthsOfSeniorManagementWorkExperience?: number | null,
      ManagementRecord?: string | null,
      HighestEducationalLevel?: DegreeType | null,
      TotalYearsOfWorkExperience?: number | null,
    } | null,
    Achievements?: Array< string | null > | null,
    Available?: string | null,
    Competency?:  Array< {
      __typename: "Competency",
      skillLevel?: number | null,
      skillUsed?:  {
        __typename: "CompetencySkillUsed",
        value?: number | null,
        type?: SkillUsedType | null,
      } | null,
      skillProficiency?: SkillProficiencyList | null,
      Grade?: string | null,
      skillName?: string | null,
      description?: string | null,
      auth?: string | null,
      skillCount?: number | null,
      lastUsed?: string | null,
      CompetencyId?:  {
        __typename: "CompetencyId",
        idOwner?: string | null,
        id?: string | null,
      } | null,
      TaxonomyId?:  {
        __typename: "TaxonomyId",
        idOwner?: string | null,
        description?: string | null,
      } | null,
      skillAliasArray?: Array< string | null > | null,
    } | null > | null,
    ContactMethod?:  {
      __typename: "ContactMethod",
      InternetWebAddress?:  Array< {
        __typename: "InternetWebAddress",
        content?: string | null,
        type?: string | null,
      } | null > | null,
      InternetEmailAddress_main?: string | null,
      InternetEmailAddress_alt?: string | null,
      Telephone_home?: string | null,
      Telephone_work?: string | null,
      Telephone_alt?: string | null,
      Telephone_mobile?: string | null,
      Pager?: string | null,
      Fax?: string | null,
      PostalAddress_main?:  {
        __typename: "LocationSummary",
        CountryCode?: string | null,
        Municipality?: string | null,
        Region?: string | null,
        AddressLine?: string | null,
        PostalCode?: string | null,
      } | null,
      PostalAddress_alt?:  {
        __typename: "LocationSummary",
        CountryCode?: string | null,
        Municipality?: string | null,
        Region?: string | null,
        AddressLine?: string | null,
        PostalCode?: string | null,
      } | null,
    } | null,
    DOB?: string | null,
    EducationHistory?:  Array< {
      __typename: "Education",
      StartDate?: string | null,
      EndDate?: string | null,
      MeasureValue?: string | null,
      Major?: string | null,
      SchoolUnit?: string | null,
      Degree?:  {
        __typename: "Degree",
        degreeType?: string | null,
        DegreeName?: string | null,
        DegreeDate?: string | null,
      } | null,
      LocationSummary?:  {
        __typename: "LocationSummary",
        CountryCode?: string | null,
        Municipality?: string | null,
        Region?: string | null,
        AddressLine?: string | null,
        PostalCode?: string | null,
      } | null,
      SchoolName?: string | null,
      schoolType?: SchoolType | null,
      Comments?: string | null,
    } | null > | null,
    EmploymentHistory?:  Array< {
      __typename: "Employment",
      StartDate?: string | null,
      EndDate?: string | null,
      LocationSummary?:  {
        __typename: "LocationSummary",
        CountryCode?: string | null,
        Municipality?: string | null,
        Region?: string | null,
        AddressLine?: string | null,
        PostalCode?: string | null,
      } | null,
      InternetDomainName?: string | null,
      EmployerOrgName?: string | null,
      employerOrgType?: OrgType | null,
      PositionType?: string | null,
      MonthsOfWork?: number | null,
      TimelineNumber?: number | null,
      Title?: string | null,
      ReasonForLeaving?: string | null,
      OrgIndustry?:  {
        __typename: "OrgIndustry",
        IndustryDescription?: string | null,
        primaryIndicator?: boolean | null,
      } | null,
      JobArea?: string | null,
      JobGrade?: string | null,
      Project?: string | null,
      OrgName?: string | null,
      Description?: string | null,
    } | null > | null,
    ExecutiveSummary?: string | null,
    Hobbies?: string | null,
    LicensesAndCertifications?:  Array< {
      __typename: "LicenseOrCertification",
      IssuingAuthority?:  {
        __typename: "LicenseOrCertificationIssuingAuthority",
        IssuingAuthorityName?: string | null,
        countryCode?: string | null,
      } | null,
      Id?: string | null,
      EffectiveDate?:  {
        __typename: "LicenseOrCertificationEffectiveDate",
        ValidFrom?: string | null,
        FirstIssuedDate?: string | null,
        ValidTo?: string | null,
      } | null,
      LicensesOrCertificationName?: string | null,
      Description?: string | null,
    } | null > | null,
    Languages?:  Array< {
      __typename: "Language",
      CompetencyId?:  {
        __typename: "CompetencyId",
        idOwner?: string | null,
        id?: string | null,
      } | null,
      Proficiency?: LanguageProficiency | null,
      Speak?: boolean | null,
      LanguageCode?: string | null,
      Write?: boolean | null,
      Read?: boolean | null,
    } | null > | null,
    lang?: string | null,
    LegalDocuments?:  Array< {
      __typename: "LegalDocument",
      IssueCountryCode?: string | null,
      IssueAuthority?: string | null,
      IssueDate?: string | null,
      Number?: string | null,
      IssueLocationSummary?:  {
        __typename: "LocationSummary",
        CountryCode?: string | null,
        Municipality?: string | null,
        Region?: string | null,
        AddressLine?: string | null,
        PostalCode?: string | null,
      } | null,
      type?: string | null,
    } | null > | null,
    MaritalStatus?: MaritalStatus | null,
    Nationality?: Array< string | null > | null,
    OtherInfo?: string | null,
    PersonName?:  {
      __typename: "PersonName",
      FormattedName?: string | null,
      Title?: string | null,
      Suffix?: string | null,
      FamilyName?: string | null,
      GivenName?: string | null,
      PreferredGivenName?: string | null,
      MiddleName?: string | null,
      sex?: Gender | null,
    } | null,
    PlaceOfBirth?:  {
      __typename: "LocationSummary",
      CountryCode?: string | null,
      Municipality?: string | null,
      Region?: string | null,
      AddressLine?: string | null,
      PostalCode?: string | null,
    } | null,
    PoliticalStatus?: string | null,
    PreferredLocation?: string | null,
    PreferredPosition?: string | null,
    PreferredPositionType?: Array< PreferredPositionType | null > | null,
    PreferredTypeOfHours?: Array< PreferredTypeOfHours | null > | null,
    ProjectHistory?:  Array< {
      __typename: "Employment",
      StartDate?: string | null,
      EndDate?: string | null,
      LocationSummary?:  {
        __typename: "LocationSummary",
        CountryCode?: string | null,
        Municipality?: string | null,
        Region?: string | null,
        AddressLine?: string | null,
        PostalCode?: string | null,
      } | null,
      InternetDomainName?: string | null,
      EmployerOrgName?: string | null,
      employerOrgType?: OrgType | null,
      PositionType?: string | null,
      MonthsOfWork?: number | null,
      TimelineNumber?: number | null,
      Title?: string | null,
      ReasonForLeaving?: string | null,
      OrgIndustry?:  {
        __typename: "OrgIndustry",
        IndustryDescription?: string | null,
        primaryIndicator?: boolean | null,
      } | null,
      JobArea?: string | null,
      JobGrade?: string | null,
      Project?: string | null,
      OrgName?: string | null,
      Description?: string | null,
    } | null > | null,
    PublicationHistory?:  Array< {
      __typename: "Publication",
      Comments?: string | null,
      content?: Array< string | null > | null,
      Issue?: string | null,
      JournalOrSerialName?: string | null,
      Volume?: string | null,
      PageNumber?: string | null,
      Name?:  Array< {
        __typename: "PublicationName",
        FormattedName?: string | null,
        role?: string | null,
      } | null > | null,
      Title?: Array< string | null > | null,
      PublicationDate?: string | null,
    } | null > | null,
    Race?: string | null,
    References?:  Array< {
      __typename: "Reference",
      CompanyName?: string | null,
      PersonName?:  {
        __typename: "PersonName",
        FormattedName?: string | null,
        Title?: string | null,
        Suffix?: string | null,
        FamilyName?: string | null,
        GivenName?: string | null,
        PreferredGivenName?: string | null,
        MiddleName?: string | null,
        sex?: Gender | null,
      } | null,
      ContactMethod?:  {
        __typename: "ContactMethod",
        InternetEmailAddress_main?: string | null,
        InternetEmailAddress_alt?: string | null,
        Telephone_home?: string | null,
        Telephone_work?: string | null,
        Telephone_alt?: string | null,
        Telephone_mobile?: string | null,
        Pager?: string | null,
        Fax?: string | null,
      } | null,
      PositionTitle?: string | null,
    } | null > | null,
    RegisteredResidence?:  {
      __typename: "LocationSummary",
      CountryCode?: string | null,
      Municipality?: string | null,
      Region?: string | null,
      AddressLine?: string | null,
      PostalCode?: string | null,
    } | null,
    Religion?: string | null,
    RevisionDate?: string | null,
    Salary_desired?:  {
      __typename: "Salary",
      currency?: string | null,
      amount?: number | null,
      hiamount?: number | null,
      period?: SalaryPeriod | null,
    } | null,
    Salary_current?:  {
      __typename: "Salary",
      currency?: string | null,
      amount?: number | null,
      hiamount?: number | null,
      period?: SalaryPeriod | null,
    } | null,
    WillRelocate?: boolean | null,
    RawData?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListCandidateResumesQueryVariables = {
  filter?: ModelCandidateResumeFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListCandidateResumesQuery = {
  listCandidateResumes?:  {
    __typename: "ModelCandidateResumeConnection",
    items:  Array< {
      __typename: "CandidateResume",
      id: string,
      owner: string,
      submitted?: boolean | null,
      verified?: boolean | null,
      ExperienceSummary?:  {
        __typename: "ExperienceSummary",
        TotalYearsOfManagementWorkExperience?: number | null,
        TotalYearsOfLowLevelManagementWorkExperience?: number | null,
        TotalMonthsOfWorkExperience?: number | null,
        ExecutiveBrief?: string | null,
        TotalMonthsOfLowLevelManagementWorkExperience?: number | null,
        TotalMonthsOfManagementWorkExperience?: number | null,
        TotalYearsOfMidLevelManagementWorkExperience?: number | null,
        TotalMonthsOfMidLevelManagementWorkExperience?: number | null,
        TotalYearsOfSeniorManagementWorkExperience?: number | null,
        TotalMonthsOfSeniorManagementWorkExperience?: number | null,
        ManagementRecord?: string | null,
        HighestEducationalLevel?: DegreeType | null,
        TotalYearsOfWorkExperience?: number | null,
      } | null,
      Achievements?: Array< string | null > | null,
      Available?: string | null,
      Competency?:  Array< {
        __typename: "Competency",
        skillLevel?: number | null,
        skillProficiency?: SkillProficiencyList | null,
        Grade?: string | null,
        skillName?: string | null,
        description?: string | null,
        auth?: string | null,
        skillCount?: number | null,
        lastUsed?: string | null,
        skillAliasArray?: Array< string | null > | null,
      } | null > | null,
      ContactMethod?:  {
        __typename: "ContactMethod",
        InternetEmailAddress_main?: string | null,
        InternetEmailAddress_alt?: string | null,
        Telephone_home?: string | null,
        Telephone_work?: string | null,
        Telephone_alt?: string | null,
        Telephone_mobile?: string | null,
        Pager?: string | null,
        Fax?: string | null,
      } | null,
      DOB?: string | null,
      EducationHistory?:  Array< {
        __typename: "Education",
        StartDate?: string | null,
        EndDate?: string | null,
        MeasureValue?: string | null,
        Major?: string | null,
        SchoolUnit?: string | null,
        SchoolName?: string | null,
        schoolType?: SchoolType | null,
        Comments?: string | null,
      } | null > | null,
      EmploymentHistory?:  Array< {
        __typename: "Employment",
        StartDate?: string | null,
        EndDate?: string | null,
        InternetDomainName?: string | null,
        EmployerOrgName?: string | null,
        employerOrgType?: OrgType | null,
        PositionType?: string | null,
        MonthsOfWork?: number | null,
        TimelineNumber?: number | null,
        Title?: string | null,
        ReasonForLeaving?: string | null,
        JobArea?: string | null,
        JobGrade?: string | null,
        Project?: string | null,
        OrgName?: string | null,
        Description?: string | null,
      } | null > | null,
      ExecutiveSummary?: string | null,
      Hobbies?: string | null,
      LicensesAndCertifications?:  Array< {
        __typename: "LicenseOrCertification",
        Id?: string | null,
        LicensesOrCertificationName?: string | null,
        Description?: string | null,
      } | null > | null,
      Languages?:  Array< {
        __typename: "Language",
        Proficiency?: LanguageProficiency | null,
        Speak?: boolean | null,
        LanguageCode?: string | null,
        Write?: boolean | null,
        Read?: boolean | null,
      } | null > | null,
      lang?: string | null,
      LegalDocuments?:  Array< {
        __typename: "LegalDocument",
        IssueCountryCode?: string | null,
        IssueAuthority?: string | null,
        IssueDate?: string | null,
        Number?: string | null,
        type?: string | null,
      } | null > | null,
      MaritalStatus?: MaritalStatus | null,
      Nationality?: Array< string | null > | null,
      OtherInfo?: string | null,
      PersonName?:  {
        __typename: "PersonName",
        FormattedName?: string | null,
        Title?: string | null,
        Suffix?: string | null,
        FamilyName?: string | null,
        GivenName?: string | null,
        PreferredGivenName?: string | null,
        MiddleName?: string | null,
        sex?: Gender | null,
      } | null,
      PlaceOfBirth?:  {
        __typename: "LocationSummary",
        CountryCode?: string | null,
        Municipality?: string | null,
        Region?: string | null,
        AddressLine?: string | null,
        PostalCode?: string | null,
      } | null,
      PoliticalStatus?: string | null,
      PreferredLocation?: string | null,
      PreferredPosition?: string | null,
      PreferredPositionType?: Array< PreferredPositionType | null > | null,
      PreferredTypeOfHours?: Array< PreferredTypeOfHours | null > | null,
      ProjectHistory?:  Array< {
        __typename: "Employment",
        StartDate?: string | null,
        EndDate?: string | null,
        InternetDomainName?: string | null,
        EmployerOrgName?: string | null,
        employerOrgType?: OrgType | null,
        PositionType?: string | null,
        MonthsOfWork?: number | null,
        TimelineNumber?: number | null,
        Title?: string | null,
        ReasonForLeaving?: string | null,
        JobArea?: string | null,
        JobGrade?: string | null,
        Project?: string | null,
        OrgName?: string | null,
        Description?: string | null,
      } | null > | null,
      PublicationHistory?:  Array< {
        __typename: "Publication",
        Comments?: string | null,
        content?: Array< string | null > | null,
        Issue?: string | null,
        JournalOrSerialName?: string | null,
        Volume?: string | null,
        PageNumber?: string | null,
        Title?: Array< string | null > | null,
        PublicationDate?: string | null,
      } | null > | null,
      Race?: string | null,
      References?:  Array< {
        __typename: "Reference",
        CompanyName?: string | null,
        PositionTitle?: string | null,
      } | null > | null,
      RegisteredResidence?:  {
        __typename: "LocationSummary",
        CountryCode?: string | null,
        Municipality?: string | null,
        Region?: string | null,
        AddressLine?: string | null,
        PostalCode?: string | null,
      } | null,
      Religion?: string | null,
      RevisionDate?: string | null,
      Salary_desired?:  {
        __typename: "Salary",
        currency?: string | null,
        amount?: number | null,
        hiamount?: number | null,
        period?: SalaryPeriod | null,
      } | null,
      Salary_current?:  {
        __typename: "Salary",
        currency?: string | null,
        amount?: number | null,
        hiamount?: number | null,
        period?: SalaryPeriod | null,
      } | null,
      WillRelocate?: boolean | null,
      RawData?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetTimesheetQueryVariables = {
  id: string,
};

export type GetTimesheetQuery = {
  getTimesheet?:  {
    __typename: "Timesheet",
    id: string,
    owner: string,
    user?:  {
      __typename: "User",
      id: string,
      email?: string | null,
      name?: string | null,
      userType?: string | null,
      odooId?: string | null,
      customerId?: number | null,
    } | null,
    contractId?: number | null,
    employeeId?: number | null,
    employeeName?: string | null,
    customerId?: string | null,
    customerName?: string | null,
    jobId?: number | null,
    status?: TimesheetStatus | null,
    state?: TimesheetState | null,
    submitted?: boolean | null,
    submittedBy?: string | null,
    submittedByName?: string | null,
    submittedDate?: string | null,
    approver1?: string | null,
    approver1Name?: string | null,
    approver1Token?: string | null,
    approved1?: boolean | null,
    approved1By?: string | null,
    approved1ByName?: string | null,
    approved1Date?: string | null,
    approver2?: string | null,
    approver2Name?: string | null,
    approver2Token?: string | null,
    approved2?: boolean | null,
    approved2By?: string | null,
    approved2ByName?: string | null,
    approved2Date?: string | null,
    fromDate?: string | null,
    toDate?: string | null,
    description?: string | null,
    entries?:  Array< {
      __typename: "TimesheetEntry",
      date: string,
      type?: DayType | null,
      leaveType?: number | null,
      leaveId?: number | null,
    } | null > | null,
    managersComments?:  Array< {
      __typename: "TimesheetManagerComment",
      date: string,
      managerId?: string | null,
      message?: string | null,
    } | null > | null,
    statusChanges?:  Array< {
      __typename: "TimesheetStatusChange",
      date: string,
      userId?: string | null,
      status?: TimesheetStatus | null,
      message?: string | null,
    } | null > | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListTimesheetsQueryVariables = {
  filter?: ModelTimesheetFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListTimesheetsQuery = {
  listTimesheets?:  {
    __typename: "ModelTimesheetConnection",
    items:  Array< {
      __typename: "Timesheet",
      id: string,
      owner: string,
      user?:  {
        __typename: "User",
        id: string,
        email?: string | null,
        name?: string | null,
        userType?: string | null,
        odooId?: string | null,
        customerId?: number | null,
      } | null,
      contractId?: number | null,
      employeeId?: number | null,
      employeeName?: string | null,
      customerId?: string | null,
      customerName?: string | null,
      jobId?: number | null,
      status?: TimesheetStatus | null,
      state?: TimesheetState | null,
      submitted?: boolean | null,
      submittedBy?: string | null,
      submittedByName?: string | null,
      submittedDate?: string | null,
      approver1?: string | null,
      approver1Name?: string | null,
      approver1Token?: string | null,
      approved1?: boolean | null,
      approved1By?: string | null,
      approved1ByName?: string | null,
      approved1Date?: string | null,
      approver2?: string | null,
      approver2Name?: string | null,
      approver2Token?: string | null,
      approved2?: boolean | null,
      approved2By?: string | null,
      approved2ByName?: string | null,
      approved2Date?: string | null,
      fromDate?: string | null,
      toDate?: string | null,
      description?: string | null,
      entries?:  Array< {
        __typename: "TimesheetEntry",
        date: string,
        type?: DayType | null,
        leaveType?: number | null,
        leaveId?: number | null,
      } | null > | null,
      managersComments?:  Array< {
        __typename: "TimesheetManagerComment",
        date: string,
        managerId?: string | null,
        message?: string | null,
      } | null > | null,
      statusChanges?:  Array< {
        __typename: "TimesheetStatusChange",
        date: string,
        userId?: string | null,
        status?: TimesheetStatus | null,
        message?: string | null,
      } | null > | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type TimesheetsByContractorQueryVariables = {
  owner?: string | null,
  statusFromDate?: ModelTimesheetByContractorCompositeKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelTimesheetFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type TimesheetsByContractorQuery = {
  timesheetsByContractor?:  {
    __typename: "ModelTimesheetConnection",
    items:  Array< {
      __typename: "Timesheet",
      id: string,
      owner: string,
      user?:  {
        __typename: "User",
        id: string,
        email?: string | null,
        name?: string | null,
        userType?: string | null,
        odooId?: string | null,
        customerId?: number | null,
      } | null,
      contractId?: number | null,
      employeeId?: number | null,
      employeeName?: string | null,
      customerId?: string | null,
      customerName?: string | null,
      jobId?: number | null,
      status?: TimesheetStatus | null,
      state?: TimesheetState | null,
      submitted?: boolean | null,
      submittedBy?: string | null,
      submittedByName?: string | null,
      submittedDate?: string | null,
      approver1?: string | null,
      approver1Name?: string | null,
      approver1Token?: string | null,
      approved1?: boolean | null,
      approved1By?: string | null,
      approved1ByName?: string | null,
      approved1Date?: string | null,
      approver2?: string | null,
      approver2Name?: string | null,
      approver2Token?: string | null,
      approved2?: boolean | null,
      approved2By?: string | null,
      approved2ByName?: string | null,
      approved2Date?: string | null,
      fromDate?: string | null,
      toDate?: string | null,
      description?: string | null,
      entries?:  Array< {
        __typename: "TimesheetEntry",
        date: string,
        type?: DayType | null,
        leaveType?: number | null,
        leaveId?: number | null,
      } | null > | null,
      managersComments?:  Array< {
        __typename: "TimesheetManagerComment",
        date: string,
        managerId?: string | null,
        message?: string | null,
      } | null > | null,
      statusChanges?:  Array< {
        __typename: "TimesheetStatusChange",
        date: string,
        userId?: string | null,
        status?: TimesheetStatus | null,
        message?: string | null,
      } | null > | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type TimesheetsByContractorAndDateQueryVariables = {
  owner?: string | null,
  fromDate?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelTimesheetFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type TimesheetsByContractorAndDateQuery = {
  timesheetsByContractorAndDate?:  {
    __typename: "ModelTimesheetConnection",
    items:  Array< {
      __typename: "Timesheet",
      id: string,
      owner: string,
      user?:  {
        __typename: "User",
        id: string,
        email?: string | null,
        name?: string | null,
        userType?: string | null,
        odooId?: string | null,
        customerId?: number | null,
      } | null,
      contractId?: number | null,
      employeeId?: number | null,
      employeeName?: string | null,
      customerId?: string | null,
      customerName?: string | null,
      jobId?: number | null,
      status?: TimesheetStatus | null,
      state?: TimesheetState | null,
      submitted?: boolean | null,
      submittedBy?: string | null,
      submittedByName?: string | null,
      submittedDate?: string | null,
      approver1?: string | null,
      approver1Name?: string | null,
      approver1Token?: string | null,
      approved1?: boolean | null,
      approved1By?: string | null,
      approved1ByName?: string | null,
      approved1Date?: string | null,
      approver2?: string | null,
      approver2Name?: string | null,
      approver2Token?: string | null,
      approved2?: boolean | null,
      approved2By?: string | null,
      approved2ByName?: string | null,
      approved2Date?: string | null,
      fromDate?: string | null,
      toDate?: string | null,
      description?: string | null,
      entries?:  Array< {
        __typename: "TimesheetEntry",
        date: string,
        type?: DayType | null,
        leaveType?: number | null,
        leaveId?: number | null,
      } | null > | null,
      managersComments?:  Array< {
        __typename: "TimesheetManagerComment",
        date: string,
        managerId?: string | null,
        message?: string | null,
      } | null > | null,
      statusChanges?:  Array< {
        __typename: "TimesheetStatusChange",
        date: string,
        userId?: string | null,
        status?: TimesheetStatus | null,
        message?: string | null,
      } | null > | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type TimesheetsByEmployeeQueryVariables = {
  employeeId?: number | null,
  fromDate?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelTimesheetFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type TimesheetsByEmployeeQuery = {
  timesheetsByEmployee?:  {
    __typename: "ModelTimesheetConnection",
    items:  Array< {
      __typename: "Timesheet",
      id: string,
      owner: string,
      user?:  {
        __typename: "User",
        id: string,
        email?: string | null,
        name?: string | null,
        userType?: string | null,
        odooId?: string | null,
        customerId?: number | null,
      } | null,
      contractId?: number | null,
      employeeId?: number | null,
      employeeName?: string | null,
      customerId?: string | null,
      customerName?: string | null,
      jobId?: number | null,
      status?: TimesheetStatus | null,
      state?: TimesheetState | null,
      submitted?: boolean | null,
      submittedBy?: string | null,
      submittedByName?: string | null,
      submittedDate?: string | null,
      approver1?: string | null,
      approver1Name?: string | null,
      approver1Token?: string | null,
      approved1?: boolean | null,
      approved1By?: string | null,
      approved1ByName?: string | null,
      approved1Date?: string | null,
      approver2?: string | null,
      approver2Name?: string | null,
      approver2Token?: string | null,
      approved2?: boolean | null,
      approved2By?: string | null,
      approved2ByName?: string | null,
      approved2Date?: string | null,
      fromDate?: string | null,
      toDate?: string | null,
      description?: string | null,
      entries?:  Array< {
        __typename: "TimesheetEntry",
        date: string,
        type?: DayType | null,
        leaveType?: number | null,
        leaveId?: number | null,
      } | null > | null,
      managersComments?:  Array< {
        __typename: "TimesheetManagerComment",
        date: string,
        managerId?: string | null,
        message?: string | null,
      } | null > | null,
      statusChanges?:  Array< {
        __typename: "TimesheetStatusChange",
        date: string,
        userId?: string | null,
        status?: TimesheetStatus | null,
        message?: string | null,
      } | null > | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type TimesheetsByCustomerQueryVariables = {
  customerId?: string | null,
  statusFromDate?: ModelTimesheetByCustomerCompositeKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelTimesheetFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type TimesheetsByCustomerQuery = {
  timesheetsByCustomer?:  {
    __typename: "ModelTimesheetConnection",
    items:  Array< {
      __typename: "Timesheet",
      id: string,
      owner: string,
      user?:  {
        __typename: "User",
        id: string,
        email?: string | null,
        name?: string | null,
        userType?: string | null,
        odooId?: string | null,
        customerId?: number | null,
      } | null,
      contractId?: number | null,
      employeeId?: number | null,
      employeeName?: string | null,
      customerId?: string | null,
      customerName?: string | null,
      jobId?: number | null,
      status?: TimesheetStatus | null,
      state?: TimesheetState | null,
      submitted?: boolean | null,
      submittedBy?: string | null,
      submittedByName?: string | null,
      submittedDate?: string | null,
      approver1?: string | null,
      approver1Name?: string | null,
      approver1Token?: string | null,
      approved1?: boolean | null,
      approved1By?: string | null,
      approved1ByName?: string | null,
      approved1Date?: string | null,
      approver2?: string | null,
      approver2Name?: string | null,
      approver2Token?: string | null,
      approved2?: boolean | null,
      approved2By?: string | null,
      approved2ByName?: string | null,
      approved2Date?: string | null,
      fromDate?: string | null,
      toDate?: string | null,
      description?: string | null,
      entries?:  Array< {
        __typename: "TimesheetEntry",
        date: string,
        type?: DayType | null,
        leaveType?: number | null,
        leaveId?: number | null,
      } | null > | null,
      managersComments?:  Array< {
        __typename: "TimesheetManagerComment",
        date: string,
        managerId?: string | null,
        message?: string | null,
      } | null > | null,
      statusChanges?:  Array< {
        __typename: "TimesheetStatusChange",
        date: string,
        userId?: string | null,
        status?: TimesheetStatus | null,
        message?: string | null,
      } | null > | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type TimesheetsByStatusQueryVariables = {
  status?: TimesheetStatus | null,
  fromDate?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelTimesheetFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type TimesheetsByStatusQuery = {
  timesheetsByStatus?:  {
    __typename: "ModelTimesheetConnection",
    items:  Array< {
      __typename: "Timesheet",
      id: string,
      owner: string,
      user?:  {
        __typename: "User",
        id: string,
        email?: string | null,
        name?: string | null,
        userType?: string | null,
        odooId?: string | null,
        customerId?: number | null,
      } | null,
      contractId?: number | null,
      employeeId?: number | null,
      employeeName?: string | null,
      customerId?: string | null,
      customerName?: string | null,
      jobId?: number | null,
      status?: TimesheetStatus | null,
      state?: TimesheetState | null,
      submitted?: boolean | null,
      submittedBy?: string | null,
      submittedByName?: string | null,
      submittedDate?: string | null,
      approver1?: string | null,
      approver1Name?: string | null,
      approver1Token?: string | null,
      approved1?: boolean | null,
      approved1By?: string | null,
      approved1ByName?: string | null,
      approved1Date?: string | null,
      approver2?: string | null,
      approver2Name?: string | null,
      approver2Token?: string | null,
      approved2?: boolean | null,
      approved2By?: string | null,
      approved2ByName?: string | null,
      approved2Date?: string | null,
      fromDate?: string | null,
      toDate?: string | null,
      description?: string | null,
      entries?:  Array< {
        __typename: "TimesheetEntry",
        date: string,
        type?: DayType | null,
        leaveType?: number | null,
        leaveId?: number | null,
      } | null > | null,
      managersComments?:  Array< {
        __typename: "TimesheetManagerComment",
        date: string,
        managerId?: string | null,
        message?: string | null,
      } | null > | null,
      statusChanges?:  Array< {
        __typename: "TimesheetStatusChange",
        date: string,
        userId?: string | null,
        status?: TimesheetStatus | null,
        message?: string | null,
      } | null > | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type TimesheetsByApprover1QueryVariables = {
  approver1?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelTimesheetFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type TimesheetsByApprover1Query = {
  timesheetsByApprover1?:  {
    __typename: "ModelTimesheetConnection",
    items:  Array< {
      __typename: "Timesheet",
      id: string,
      owner: string,
      user?:  {
        __typename: "User",
        id: string,
        email?: string | null,
        name?: string | null,
        userType?: string | null,
        odooId?: string | null,
        customerId?: number | null,
      } | null,
      contractId?: number | null,
      employeeId?: number | null,
      employeeName?: string | null,
      customerId?: string | null,
      customerName?: string | null,
      jobId?: number | null,
      status?: TimesheetStatus | null,
      state?: TimesheetState | null,
      submitted?: boolean | null,
      submittedBy?: string | null,
      submittedByName?: string | null,
      submittedDate?: string | null,
      approver1?: string | null,
      approver1Name?: string | null,
      approver1Token?: string | null,
      approved1?: boolean | null,
      approved1By?: string | null,
      approved1ByName?: string | null,
      approved1Date?: string | null,
      approver2?: string | null,
      approver2Name?: string | null,
      approver2Token?: string | null,
      approved2?: boolean | null,
      approved2By?: string | null,
      approved2ByName?: string | null,
      approved2Date?: string | null,
      fromDate?: string | null,
      toDate?: string | null,
      description?: string | null,
      entries?:  Array< {
        __typename: "TimesheetEntry",
        date: string,
        type?: DayType | null,
        leaveType?: number | null,
        leaveId?: number | null,
      } | null > | null,
      managersComments?:  Array< {
        __typename: "TimesheetManagerComment",
        date: string,
        managerId?: string | null,
        message?: string | null,
      } | null > | null,
      statusChanges?:  Array< {
        __typename: "TimesheetStatusChange",
        date: string,
        userId?: string | null,
        status?: TimesheetStatus | null,
        message?: string | null,
      } | null > | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type TimesheetsByApprover2QueryVariables = {
  approver2?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelTimesheetFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type TimesheetsByApprover2Query = {
  timesheetsByApprover2?:  {
    __typename: "ModelTimesheetConnection",
    items:  Array< {
      __typename: "Timesheet",
      id: string,
      owner: string,
      user?:  {
        __typename: "User",
        id: string,
        email?: string | null,
        name?: string | null,
        userType?: string | null,
        odooId?: string | null,
        customerId?: number | null,
      } | null,
      contractId?: number | null,
      employeeId?: number | null,
      employeeName?: string | null,
      customerId?: string | null,
      customerName?: string | null,
      jobId?: number | null,
      status?: TimesheetStatus | null,
      state?: TimesheetState | null,
      submitted?: boolean | null,
      submittedBy?: string | null,
      submittedByName?: string | null,
      submittedDate?: string | null,
      approver1?: string | null,
      approver1Name?: string | null,
      approver1Token?: string | null,
      approved1?: boolean | null,
      approved1By?: string | null,
      approved1ByName?: string | null,
      approved1Date?: string | null,
      approver2?: string | null,
      approver2Name?: string | null,
      approver2Token?: string | null,
      approved2?: boolean | null,
      approved2By?: string | null,
      approved2ByName?: string | null,
      approved2Date?: string | null,
      fromDate?: string | null,
      toDate?: string | null,
      description?: string | null,
      entries?:  Array< {
        __typename: "TimesheetEntry",
        date: string,
        type?: DayType | null,
        leaveType?: number | null,
        leaveId?: number | null,
      } | null > | null,
      managersComments?:  Array< {
        __typename: "TimesheetManagerComment",
        date: string,
        managerId?: string | null,
        message?: string | null,
      } | null > | null,
      statusChanges?:  Array< {
        __typename: "TimesheetStatusChange",
        date: string,
        userId?: string | null,
        status?: TimesheetStatus | null,
        message?: string | null,
      } | null > | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetNotificationQueryVariables = {
  id: string,
};

export type GetNotificationQuery = {
  getNotification?:  {
    __typename: "Notification",
    id: string,
    status?: NotificationStatus | null,
    date?: string | null,
    user?: string | null,
    template?: string | null,
    parameters?: string | null,
    timesheetId?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListNotificationsQueryVariables = {
  filter?: ModelNotificationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListNotificationsQuery = {
  listNotifications?:  {
    __typename: "ModelNotificationConnection",
    items:  Array< {
      __typename: "Notification",
      id: string,
      status?: NotificationStatus | null,
      date?: string | null,
      user?: string | null,
      template?: string | null,
      parameters?: string | null,
      timesheetId?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type NotificationsByTimesheetQueryVariables = {
  timesheetId?: string | null,
  userDate?: ModelNotificationByTimesheetCompositeKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelNotificationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type NotificationsByTimesheetQuery = {
  notificationsByTimesheet?:  {
    __typename: "ModelNotificationConnection",
    items:  Array< {
      __typename: "Notification",
      id: string,
      status?: NotificationStatus | null,
      date?: string | null,
      user?: string | null,
      template?: string | null,
      parameters?: string | null,
      timesheetId?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetRequestQueryVariables = {
  id: string,
};

export type GetRequestQuery = {
  getRequest?:  {
    __typename: "Request",
    id: string,
    owner: string,
    user?:  {
      __typename: "User",
      id: string,
      email?: string | null,
      name?: string | null,
      userType?: string | null,
      odooId?: string | null,
      customerId?: number | null,
    } | null,
    employeeId?: number | null,
    employeeName?: string | null,
    status?: RequestStatus | null,
    type?: RequestType | null,
    requestDate?: string | null,
    entityName?: string | null,
    notes?: string | null,
    drivingInstitureName?: string | null,
    emirate?: string | null,
    country?: string | null,
    dateFrom?: string | null,
    dateTo?: string | null,
    expectedAmount?: string | null,
    receivedAmount?: string | null,
    months?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListRequestsQueryVariables = {
  filter?: ModelRequestFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListRequestsQuery = {
  listRequests?:  {
    __typename: "ModelRequestConnection",
    items:  Array< {
      __typename: "Request",
      id: string,
      owner: string,
      user?:  {
        __typename: "User",
        id: string,
        email?: string | null,
        name?: string | null,
        userType?: string | null,
        odooId?: string | null,
        customerId?: number | null,
      } | null,
      employeeId?: number | null,
      employeeName?: string | null,
      status?: RequestStatus | null,
      type?: RequestType | null,
      requestDate?: string | null,
      entityName?: string | null,
      notes?: string | null,
      drivingInstitureName?: string | null,
      emirate?: string | null,
      country?: string | null,
      dateFrom?: string | null,
      dateTo?: string | null,
      expectedAmount?: string | null,
      receivedAmount?: string | null,
      months?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type RequestsByContractorQueryVariables = {
  owner?: string | null,
  requestDate?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelRequestFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type RequestsByContractorQuery = {
  requestsByContractor?:  {
    __typename: "ModelRequestConnection",
    items:  Array< {
      __typename: "Request",
      id: string,
      owner: string,
      user?:  {
        __typename: "User",
        id: string,
        email?: string | null,
        name?: string | null,
        userType?: string | null,
        odooId?: string | null,
        customerId?: number | null,
      } | null,
      employeeId?: number | null,
      employeeName?: string | null,
      status?: RequestStatus | null,
      type?: RequestType | null,
      requestDate?: string | null,
      entityName?: string | null,
      notes?: string | null,
      drivingInstitureName?: string | null,
      emirate?: string | null,
      country?: string | null,
      dateFrom?: string | null,
      dateTo?: string | null,
      expectedAmount?: string | null,
      receivedAmount?: string | null,
      months?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type OnCreateCandidateResumeSubscriptionVariables = {
  owner?: string | null,
};

export type OnCreateCandidateResumeSubscription = {
  onCreateCandidateResume?:  {
    __typename: "CandidateResume",
    id: string,
    owner: string,
    submitted?: boolean | null,
    verified?: boolean | null,
    ExperienceSummary?:  {
      __typename: "ExperienceSummary",
      TotalYearsOfManagementWorkExperience?: number | null,
      TotalYearsOfLowLevelManagementWorkExperience?: number | null,
      TotalMonthsOfWorkExperience?: number | null,
      ExecutiveBrief?: string | null,
      TotalMonthsOfLowLevelManagementWorkExperience?: number | null,
      TotalMonthsOfManagementWorkExperience?: number | null,
      TotalYearsOfMidLevelManagementWorkExperience?: number | null,
      TotalMonthsOfMidLevelManagementWorkExperience?: number | null,
      TotalYearsOfSeniorManagementWorkExperience?: number | null,
      TotalMonthsOfSeniorManagementWorkExperience?: number | null,
      ManagementRecord?: string | null,
      HighestEducationalLevel?: DegreeType | null,
      TotalYearsOfWorkExperience?: number | null,
    } | null,
    Achievements?: Array< string | null > | null,
    Available?: string | null,
    Competency?:  Array< {
      __typename: "Competency",
      skillLevel?: number | null,
      skillUsed?:  {
        __typename: "CompetencySkillUsed",
        value?: number | null,
        type?: SkillUsedType | null,
      } | null,
      skillProficiency?: SkillProficiencyList | null,
      Grade?: string | null,
      skillName?: string | null,
      description?: string | null,
      auth?: string | null,
      skillCount?: number | null,
      lastUsed?: string | null,
      CompetencyId?:  {
        __typename: "CompetencyId",
        idOwner?: string | null,
        id?: string | null,
      } | null,
      TaxonomyId?:  {
        __typename: "TaxonomyId",
        idOwner?: string | null,
        description?: string | null,
      } | null,
      skillAliasArray?: Array< string | null > | null,
    } | null > | null,
    ContactMethod?:  {
      __typename: "ContactMethod",
      InternetWebAddress?:  Array< {
        __typename: "InternetWebAddress",
        content?: string | null,
        type?: string | null,
      } | null > | null,
      InternetEmailAddress_main?: string | null,
      InternetEmailAddress_alt?: string | null,
      Telephone_home?: string | null,
      Telephone_work?: string | null,
      Telephone_alt?: string | null,
      Telephone_mobile?: string | null,
      Pager?: string | null,
      Fax?: string | null,
      PostalAddress_main?:  {
        __typename: "LocationSummary",
        CountryCode?: string | null,
        Municipality?: string | null,
        Region?: string | null,
        AddressLine?: string | null,
        PostalCode?: string | null,
      } | null,
      PostalAddress_alt?:  {
        __typename: "LocationSummary",
        CountryCode?: string | null,
        Municipality?: string | null,
        Region?: string | null,
        AddressLine?: string | null,
        PostalCode?: string | null,
      } | null,
    } | null,
    DOB?: string | null,
    EducationHistory?:  Array< {
      __typename: "Education",
      StartDate?: string | null,
      EndDate?: string | null,
      MeasureValue?: string | null,
      Major?: string | null,
      SchoolUnit?: string | null,
      Degree?:  {
        __typename: "Degree",
        degreeType?: string | null,
        DegreeName?: string | null,
        DegreeDate?: string | null,
      } | null,
      LocationSummary?:  {
        __typename: "LocationSummary",
        CountryCode?: string | null,
        Municipality?: string | null,
        Region?: string | null,
        AddressLine?: string | null,
        PostalCode?: string | null,
      } | null,
      SchoolName?: string | null,
      schoolType?: SchoolType | null,
      Comments?: string | null,
    } | null > | null,
    EmploymentHistory?:  Array< {
      __typename: "Employment",
      StartDate?: string | null,
      EndDate?: string | null,
      LocationSummary?:  {
        __typename: "LocationSummary",
        CountryCode?: string | null,
        Municipality?: string | null,
        Region?: string | null,
        AddressLine?: string | null,
        PostalCode?: string | null,
      } | null,
      InternetDomainName?: string | null,
      EmployerOrgName?: string | null,
      employerOrgType?: OrgType | null,
      PositionType?: string | null,
      MonthsOfWork?: number | null,
      TimelineNumber?: number | null,
      Title?: string | null,
      ReasonForLeaving?: string | null,
      OrgIndustry?:  {
        __typename: "OrgIndustry",
        IndustryDescription?: string | null,
        primaryIndicator?: boolean | null,
      } | null,
      JobArea?: string | null,
      JobGrade?: string | null,
      Project?: string | null,
      OrgName?: string | null,
      Description?: string | null,
    } | null > | null,
    ExecutiveSummary?: string | null,
    Hobbies?: string | null,
    LicensesAndCertifications?:  Array< {
      __typename: "LicenseOrCertification",
      IssuingAuthority?:  {
        __typename: "LicenseOrCertificationIssuingAuthority",
        IssuingAuthorityName?: string | null,
        countryCode?: string | null,
      } | null,
      Id?: string | null,
      EffectiveDate?:  {
        __typename: "LicenseOrCertificationEffectiveDate",
        ValidFrom?: string | null,
        FirstIssuedDate?: string | null,
        ValidTo?: string | null,
      } | null,
      LicensesOrCertificationName?: string | null,
      Description?: string | null,
    } | null > | null,
    Languages?:  Array< {
      __typename: "Language",
      CompetencyId?:  {
        __typename: "CompetencyId",
        idOwner?: string | null,
        id?: string | null,
      } | null,
      Proficiency?: LanguageProficiency | null,
      Speak?: boolean | null,
      LanguageCode?: string | null,
      Write?: boolean | null,
      Read?: boolean | null,
    } | null > | null,
    lang?: string | null,
    LegalDocuments?:  Array< {
      __typename: "LegalDocument",
      IssueCountryCode?: string | null,
      IssueAuthority?: string | null,
      IssueDate?: string | null,
      Number?: string | null,
      IssueLocationSummary?:  {
        __typename: "LocationSummary",
        CountryCode?: string | null,
        Municipality?: string | null,
        Region?: string | null,
        AddressLine?: string | null,
        PostalCode?: string | null,
      } | null,
      type?: string | null,
    } | null > | null,
    MaritalStatus?: MaritalStatus | null,
    Nationality?: Array< string | null > | null,
    OtherInfo?: string | null,
    PersonName?:  {
      __typename: "PersonName",
      FormattedName?: string | null,
      Title?: string | null,
      Suffix?: string | null,
      FamilyName?: string | null,
      GivenName?: string | null,
      PreferredGivenName?: string | null,
      MiddleName?: string | null,
      sex?: Gender | null,
    } | null,
    PlaceOfBirth?:  {
      __typename: "LocationSummary",
      CountryCode?: string | null,
      Municipality?: string | null,
      Region?: string | null,
      AddressLine?: string | null,
      PostalCode?: string | null,
    } | null,
    PoliticalStatus?: string | null,
    PreferredLocation?: string | null,
    PreferredPosition?: string | null,
    PreferredPositionType?: Array< PreferredPositionType | null > | null,
    PreferredTypeOfHours?: Array< PreferredTypeOfHours | null > | null,
    ProjectHistory?:  Array< {
      __typename: "Employment",
      StartDate?: string | null,
      EndDate?: string | null,
      LocationSummary?:  {
        __typename: "LocationSummary",
        CountryCode?: string | null,
        Municipality?: string | null,
        Region?: string | null,
        AddressLine?: string | null,
        PostalCode?: string | null,
      } | null,
      InternetDomainName?: string | null,
      EmployerOrgName?: string | null,
      employerOrgType?: OrgType | null,
      PositionType?: string | null,
      MonthsOfWork?: number | null,
      TimelineNumber?: number | null,
      Title?: string | null,
      ReasonForLeaving?: string | null,
      OrgIndustry?:  {
        __typename: "OrgIndustry",
        IndustryDescription?: string | null,
        primaryIndicator?: boolean | null,
      } | null,
      JobArea?: string | null,
      JobGrade?: string | null,
      Project?: string | null,
      OrgName?: string | null,
      Description?: string | null,
    } | null > | null,
    PublicationHistory?:  Array< {
      __typename: "Publication",
      Comments?: string | null,
      content?: Array< string | null > | null,
      Issue?: string | null,
      JournalOrSerialName?: string | null,
      Volume?: string | null,
      PageNumber?: string | null,
      Name?:  Array< {
        __typename: "PublicationName",
        FormattedName?: string | null,
        role?: string | null,
      } | null > | null,
      Title?: Array< string | null > | null,
      PublicationDate?: string | null,
    } | null > | null,
    Race?: string | null,
    References?:  Array< {
      __typename: "Reference",
      CompanyName?: string | null,
      PersonName?:  {
        __typename: "PersonName",
        FormattedName?: string | null,
        Title?: string | null,
        Suffix?: string | null,
        FamilyName?: string | null,
        GivenName?: string | null,
        PreferredGivenName?: string | null,
        MiddleName?: string | null,
        sex?: Gender | null,
      } | null,
      ContactMethod?:  {
        __typename: "ContactMethod",
        InternetEmailAddress_main?: string | null,
        InternetEmailAddress_alt?: string | null,
        Telephone_home?: string | null,
        Telephone_work?: string | null,
        Telephone_alt?: string | null,
        Telephone_mobile?: string | null,
        Pager?: string | null,
        Fax?: string | null,
      } | null,
      PositionTitle?: string | null,
    } | null > | null,
    RegisteredResidence?:  {
      __typename: "LocationSummary",
      CountryCode?: string | null,
      Municipality?: string | null,
      Region?: string | null,
      AddressLine?: string | null,
      PostalCode?: string | null,
    } | null,
    Religion?: string | null,
    RevisionDate?: string | null,
    Salary_desired?:  {
      __typename: "Salary",
      currency?: string | null,
      amount?: number | null,
      hiamount?: number | null,
      period?: SalaryPeriod | null,
    } | null,
    Salary_current?:  {
      __typename: "Salary",
      currency?: string | null,
      amount?: number | null,
      hiamount?: number | null,
      period?: SalaryPeriod | null,
    } | null,
    WillRelocate?: boolean | null,
    RawData?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateCandidateResumeSubscriptionVariables = {
  owner?: string | null,
};

export type OnUpdateCandidateResumeSubscription = {
  onUpdateCandidateResume?:  {
    __typename: "CandidateResume",
    id: string,
    owner: string,
    submitted?: boolean | null,
    verified?: boolean | null,
    ExperienceSummary?:  {
      __typename: "ExperienceSummary",
      TotalYearsOfManagementWorkExperience?: number | null,
      TotalYearsOfLowLevelManagementWorkExperience?: number | null,
      TotalMonthsOfWorkExperience?: number | null,
      ExecutiveBrief?: string | null,
      TotalMonthsOfLowLevelManagementWorkExperience?: number | null,
      TotalMonthsOfManagementWorkExperience?: number | null,
      TotalYearsOfMidLevelManagementWorkExperience?: number | null,
      TotalMonthsOfMidLevelManagementWorkExperience?: number | null,
      TotalYearsOfSeniorManagementWorkExperience?: number | null,
      TotalMonthsOfSeniorManagementWorkExperience?: number | null,
      ManagementRecord?: string | null,
      HighestEducationalLevel?: DegreeType | null,
      TotalYearsOfWorkExperience?: number | null,
    } | null,
    Achievements?: Array< string | null > | null,
    Available?: string | null,
    Competency?:  Array< {
      __typename: "Competency",
      skillLevel?: number | null,
      skillUsed?:  {
        __typename: "CompetencySkillUsed",
        value?: number | null,
        type?: SkillUsedType | null,
      } | null,
      skillProficiency?: SkillProficiencyList | null,
      Grade?: string | null,
      skillName?: string | null,
      description?: string | null,
      auth?: string | null,
      skillCount?: number | null,
      lastUsed?: string | null,
      CompetencyId?:  {
        __typename: "CompetencyId",
        idOwner?: string | null,
        id?: string | null,
      } | null,
      TaxonomyId?:  {
        __typename: "TaxonomyId",
        idOwner?: string | null,
        description?: string | null,
      } | null,
      skillAliasArray?: Array< string | null > | null,
    } | null > | null,
    ContactMethod?:  {
      __typename: "ContactMethod",
      InternetWebAddress?:  Array< {
        __typename: "InternetWebAddress",
        content?: string | null,
        type?: string | null,
      } | null > | null,
      InternetEmailAddress_main?: string | null,
      InternetEmailAddress_alt?: string | null,
      Telephone_home?: string | null,
      Telephone_work?: string | null,
      Telephone_alt?: string | null,
      Telephone_mobile?: string | null,
      Pager?: string | null,
      Fax?: string | null,
      PostalAddress_main?:  {
        __typename: "LocationSummary",
        CountryCode?: string | null,
        Municipality?: string | null,
        Region?: string | null,
        AddressLine?: string | null,
        PostalCode?: string | null,
      } | null,
      PostalAddress_alt?:  {
        __typename: "LocationSummary",
        CountryCode?: string | null,
        Municipality?: string | null,
        Region?: string | null,
        AddressLine?: string | null,
        PostalCode?: string | null,
      } | null,
    } | null,
    DOB?: string | null,
    EducationHistory?:  Array< {
      __typename: "Education",
      StartDate?: string | null,
      EndDate?: string | null,
      MeasureValue?: string | null,
      Major?: string | null,
      SchoolUnit?: string | null,
      Degree?:  {
        __typename: "Degree",
        degreeType?: string | null,
        DegreeName?: string | null,
        DegreeDate?: string | null,
      } | null,
      LocationSummary?:  {
        __typename: "LocationSummary",
        CountryCode?: string | null,
        Municipality?: string | null,
        Region?: string | null,
        AddressLine?: string | null,
        PostalCode?: string | null,
      } | null,
      SchoolName?: string | null,
      schoolType?: SchoolType | null,
      Comments?: string | null,
    } | null > | null,
    EmploymentHistory?:  Array< {
      __typename: "Employment",
      StartDate?: string | null,
      EndDate?: string | null,
      LocationSummary?:  {
        __typename: "LocationSummary",
        CountryCode?: string | null,
        Municipality?: string | null,
        Region?: string | null,
        AddressLine?: string | null,
        PostalCode?: string | null,
      } | null,
      InternetDomainName?: string | null,
      EmployerOrgName?: string | null,
      employerOrgType?: OrgType | null,
      PositionType?: string | null,
      MonthsOfWork?: number | null,
      TimelineNumber?: number | null,
      Title?: string | null,
      ReasonForLeaving?: string | null,
      OrgIndustry?:  {
        __typename: "OrgIndustry",
        IndustryDescription?: string | null,
        primaryIndicator?: boolean | null,
      } | null,
      JobArea?: string | null,
      JobGrade?: string | null,
      Project?: string | null,
      OrgName?: string | null,
      Description?: string | null,
    } | null > | null,
    ExecutiveSummary?: string | null,
    Hobbies?: string | null,
    LicensesAndCertifications?:  Array< {
      __typename: "LicenseOrCertification",
      IssuingAuthority?:  {
        __typename: "LicenseOrCertificationIssuingAuthority",
        IssuingAuthorityName?: string | null,
        countryCode?: string | null,
      } | null,
      Id?: string | null,
      EffectiveDate?:  {
        __typename: "LicenseOrCertificationEffectiveDate",
        ValidFrom?: string | null,
        FirstIssuedDate?: string | null,
        ValidTo?: string | null,
      } | null,
      LicensesOrCertificationName?: string | null,
      Description?: string | null,
    } | null > | null,
    Languages?:  Array< {
      __typename: "Language",
      CompetencyId?:  {
        __typename: "CompetencyId",
        idOwner?: string | null,
        id?: string | null,
      } | null,
      Proficiency?: LanguageProficiency | null,
      Speak?: boolean | null,
      LanguageCode?: string | null,
      Write?: boolean | null,
      Read?: boolean | null,
    } | null > | null,
    lang?: string | null,
    LegalDocuments?:  Array< {
      __typename: "LegalDocument",
      IssueCountryCode?: string | null,
      IssueAuthority?: string | null,
      IssueDate?: string | null,
      Number?: string | null,
      IssueLocationSummary?:  {
        __typename: "LocationSummary",
        CountryCode?: string | null,
        Municipality?: string | null,
        Region?: string | null,
        AddressLine?: string | null,
        PostalCode?: string | null,
      } | null,
      type?: string | null,
    } | null > | null,
    MaritalStatus?: MaritalStatus | null,
    Nationality?: Array< string | null > | null,
    OtherInfo?: string | null,
    PersonName?:  {
      __typename: "PersonName",
      FormattedName?: string | null,
      Title?: string | null,
      Suffix?: string | null,
      FamilyName?: string | null,
      GivenName?: string | null,
      PreferredGivenName?: string | null,
      MiddleName?: string | null,
      sex?: Gender | null,
    } | null,
    PlaceOfBirth?:  {
      __typename: "LocationSummary",
      CountryCode?: string | null,
      Municipality?: string | null,
      Region?: string | null,
      AddressLine?: string | null,
      PostalCode?: string | null,
    } | null,
    PoliticalStatus?: string | null,
    PreferredLocation?: string | null,
    PreferredPosition?: string | null,
    PreferredPositionType?: Array< PreferredPositionType | null > | null,
    PreferredTypeOfHours?: Array< PreferredTypeOfHours | null > | null,
    ProjectHistory?:  Array< {
      __typename: "Employment",
      StartDate?: string | null,
      EndDate?: string | null,
      LocationSummary?:  {
        __typename: "LocationSummary",
        CountryCode?: string | null,
        Municipality?: string | null,
        Region?: string | null,
        AddressLine?: string | null,
        PostalCode?: string | null,
      } | null,
      InternetDomainName?: string | null,
      EmployerOrgName?: string | null,
      employerOrgType?: OrgType | null,
      PositionType?: string | null,
      MonthsOfWork?: number | null,
      TimelineNumber?: number | null,
      Title?: string | null,
      ReasonForLeaving?: string | null,
      OrgIndustry?:  {
        __typename: "OrgIndustry",
        IndustryDescription?: string | null,
        primaryIndicator?: boolean | null,
      } | null,
      JobArea?: string | null,
      JobGrade?: string | null,
      Project?: string | null,
      OrgName?: string | null,
      Description?: string | null,
    } | null > | null,
    PublicationHistory?:  Array< {
      __typename: "Publication",
      Comments?: string | null,
      content?: Array< string | null > | null,
      Issue?: string | null,
      JournalOrSerialName?: string | null,
      Volume?: string | null,
      PageNumber?: string | null,
      Name?:  Array< {
        __typename: "PublicationName",
        FormattedName?: string | null,
        role?: string | null,
      } | null > | null,
      Title?: Array< string | null > | null,
      PublicationDate?: string | null,
    } | null > | null,
    Race?: string | null,
    References?:  Array< {
      __typename: "Reference",
      CompanyName?: string | null,
      PersonName?:  {
        __typename: "PersonName",
        FormattedName?: string | null,
        Title?: string | null,
        Suffix?: string | null,
        FamilyName?: string | null,
        GivenName?: string | null,
        PreferredGivenName?: string | null,
        MiddleName?: string | null,
        sex?: Gender | null,
      } | null,
      ContactMethod?:  {
        __typename: "ContactMethod",
        InternetEmailAddress_main?: string | null,
        InternetEmailAddress_alt?: string | null,
        Telephone_home?: string | null,
        Telephone_work?: string | null,
        Telephone_alt?: string | null,
        Telephone_mobile?: string | null,
        Pager?: string | null,
        Fax?: string | null,
      } | null,
      PositionTitle?: string | null,
    } | null > | null,
    RegisteredResidence?:  {
      __typename: "LocationSummary",
      CountryCode?: string | null,
      Municipality?: string | null,
      Region?: string | null,
      AddressLine?: string | null,
      PostalCode?: string | null,
    } | null,
    Religion?: string | null,
    RevisionDate?: string | null,
    Salary_desired?:  {
      __typename: "Salary",
      currency?: string | null,
      amount?: number | null,
      hiamount?: number | null,
      period?: SalaryPeriod | null,
    } | null,
    Salary_current?:  {
      __typename: "Salary",
      currency?: string | null,
      amount?: number | null,
      hiamount?: number | null,
      period?: SalaryPeriod | null,
    } | null,
    WillRelocate?: boolean | null,
    RawData?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteCandidateResumeSubscriptionVariables = {
  owner?: string | null,
};

export type OnDeleteCandidateResumeSubscription = {
  onDeleteCandidateResume?:  {
    __typename: "CandidateResume",
    id: string,
    owner: string,
    submitted?: boolean | null,
    verified?: boolean | null,
    ExperienceSummary?:  {
      __typename: "ExperienceSummary",
      TotalYearsOfManagementWorkExperience?: number | null,
      TotalYearsOfLowLevelManagementWorkExperience?: number | null,
      TotalMonthsOfWorkExperience?: number | null,
      ExecutiveBrief?: string | null,
      TotalMonthsOfLowLevelManagementWorkExperience?: number | null,
      TotalMonthsOfManagementWorkExperience?: number | null,
      TotalYearsOfMidLevelManagementWorkExperience?: number | null,
      TotalMonthsOfMidLevelManagementWorkExperience?: number | null,
      TotalYearsOfSeniorManagementWorkExperience?: number | null,
      TotalMonthsOfSeniorManagementWorkExperience?: number | null,
      ManagementRecord?: string | null,
      HighestEducationalLevel?: DegreeType | null,
      TotalYearsOfWorkExperience?: number | null,
    } | null,
    Achievements?: Array< string | null > | null,
    Available?: string | null,
    Competency?:  Array< {
      __typename: "Competency",
      skillLevel?: number | null,
      skillUsed?:  {
        __typename: "CompetencySkillUsed",
        value?: number | null,
        type?: SkillUsedType | null,
      } | null,
      skillProficiency?: SkillProficiencyList | null,
      Grade?: string | null,
      skillName?: string | null,
      description?: string | null,
      auth?: string | null,
      skillCount?: number | null,
      lastUsed?: string | null,
      CompetencyId?:  {
        __typename: "CompetencyId",
        idOwner?: string | null,
        id?: string | null,
      } | null,
      TaxonomyId?:  {
        __typename: "TaxonomyId",
        idOwner?: string | null,
        description?: string | null,
      } | null,
      skillAliasArray?: Array< string | null > | null,
    } | null > | null,
    ContactMethod?:  {
      __typename: "ContactMethod",
      InternetWebAddress?:  Array< {
        __typename: "InternetWebAddress",
        content?: string | null,
        type?: string | null,
      } | null > | null,
      InternetEmailAddress_main?: string | null,
      InternetEmailAddress_alt?: string | null,
      Telephone_home?: string | null,
      Telephone_work?: string | null,
      Telephone_alt?: string | null,
      Telephone_mobile?: string | null,
      Pager?: string | null,
      Fax?: string | null,
      PostalAddress_main?:  {
        __typename: "LocationSummary",
        CountryCode?: string | null,
        Municipality?: string | null,
        Region?: string | null,
        AddressLine?: string | null,
        PostalCode?: string | null,
      } | null,
      PostalAddress_alt?:  {
        __typename: "LocationSummary",
        CountryCode?: string | null,
        Municipality?: string | null,
        Region?: string | null,
        AddressLine?: string | null,
        PostalCode?: string | null,
      } | null,
    } | null,
    DOB?: string | null,
    EducationHistory?:  Array< {
      __typename: "Education",
      StartDate?: string | null,
      EndDate?: string | null,
      MeasureValue?: string | null,
      Major?: string | null,
      SchoolUnit?: string | null,
      Degree?:  {
        __typename: "Degree",
        degreeType?: string | null,
        DegreeName?: string | null,
        DegreeDate?: string | null,
      } | null,
      LocationSummary?:  {
        __typename: "LocationSummary",
        CountryCode?: string | null,
        Municipality?: string | null,
        Region?: string | null,
        AddressLine?: string | null,
        PostalCode?: string | null,
      } | null,
      SchoolName?: string | null,
      schoolType?: SchoolType | null,
      Comments?: string | null,
    } | null > | null,
    EmploymentHistory?:  Array< {
      __typename: "Employment",
      StartDate?: string | null,
      EndDate?: string | null,
      LocationSummary?:  {
        __typename: "LocationSummary",
        CountryCode?: string | null,
        Municipality?: string | null,
        Region?: string | null,
        AddressLine?: string | null,
        PostalCode?: string | null,
      } | null,
      InternetDomainName?: string | null,
      EmployerOrgName?: string | null,
      employerOrgType?: OrgType | null,
      PositionType?: string | null,
      MonthsOfWork?: number | null,
      TimelineNumber?: number | null,
      Title?: string | null,
      ReasonForLeaving?: string | null,
      OrgIndustry?:  {
        __typename: "OrgIndustry",
        IndustryDescription?: string | null,
        primaryIndicator?: boolean | null,
      } | null,
      JobArea?: string | null,
      JobGrade?: string | null,
      Project?: string | null,
      OrgName?: string | null,
      Description?: string | null,
    } | null > | null,
    ExecutiveSummary?: string | null,
    Hobbies?: string | null,
    LicensesAndCertifications?:  Array< {
      __typename: "LicenseOrCertification",
      IssuingAuthority?:  {
        __typename: "LicenseOrCertificationIssuingAuthority",
        IssuingAuthorityName?: string | null,
        countryCode?: string | null,
      } | null,
      Id?: string | null,
      EffectiveDate?:  {
        __typename: "LicenseOrCertificationEffectiveDate",
        ValidFrom?: string | null,
        FirstIssuedDate?: string | null,
        ValidTo?: string | null,
      } | null,
      LicensesOrCertificationName?: string | null,
      Description?: string | null,
    } | null > | null,
    Languages?:  Array< {
      __typename: "Language",
      CompetencyId?:  {
        __typename: "CompetencyId",
        idOwner?: string | null,
        id?: string | null,
      } | null,
      Proficiency?: LanguageProficiency | null,
      Speak?: boolean | null,
      LanguageCode?: string | null,
      Write?: boolean | null,
      Read?: boolean | null,
    } | null > | null,
    lang?: string | null,
    LegalDocuments?:  Array< {
      __typename: "LegalDocument",
      IssueCountryCode?: string | null,
      IssueAuthority?: string | null,
      IssueDate?: string | null,
      Number?: string | null,
      IssueLocationSummary?:  {
        __typename: "LocationSummary",
        CountryCode?: string | null,
        Municipality?: string | null,
        Region?: string | null,
        AddressLine?: string | null,
        PostalCode?: string | null,
      } | null,
      type?: string | null,
    } | null > | null,
    MaritalStatus?: MaritalStatus | null,
    Nationality?: Array< string | null > | null,
    OtherInfo?: string | null,
    PersonName?:  {
      __typename: "PersonName",
      FormattedName?: string | null,
      Title?: string | null,
      Suffix?: string | null,
      FamilyName?: string | null,
      GivenName?: string | null,
      PreferredGivenName?: string | null,
      MiddleName?: string | null,
      sex?: Gender | null,
    } | null,
    PlaceOfBirth?:  {
      __typename: "LocationSummary",
      CountryCode?: string | null,
      Municipality?: string | null,
      Region?: string | null,
      AddressLine?: string | null,
      PostalCode?: string | null,
    } | null,
    PoliticalStatus?: string | null,
    PreferredLocation?: string | null,
    PreferredPosition?: string | null,
    PreferredPositionType?: Array< PreferredPositionType | null > | null,
    PreferredTypeOfHours?: Array< PreferredTypeOfHours | null > | null,
    ProjectHistory?:  Array< {
      __typename: "Employment",
      StartDate?: string | null,
      EndDate?: string | null,
      LocationSummary?:  {
        __typename: "LocationSummary",
        CountryCode?: string | null,
        Municipality?: string | null,
        Region?: string | null,
        AddressLine?: string | null,
        PostalCode?: string | null,
      } | null,
      InternetDomainName?: string | null,
      EmployerOrgName?: string | null,
      employerOrgType?: OrgType | null,
      PositionType?: string | null,
      MonthsOfWork?: number | null,
      TimelineNumber?: number | null,
      Title?: string | null,
      ReasonForLeaving?: string | null,
      OrgIndustry?:  {
        __typename: "OrgIndustry",
        IndustryDescription?: string | null,
        primaryIndicator?: boolean | null,
      } | null,
      JobArea?: string | null,
      JobGrade?: string | null,
      Project?: string | null,
      OrgName?: string | null,
      Description?: string | null,
    } | null > | null,
    PublicationHistory?:  Array< {
      __typename: "Publication",
      Comments?: string | null,
      content?: Array< string | null > | null,
      Issue?: string | null,
      JournalOrSerialName?: string | null,
      Volume?: string | null,
      PageNumber?: string | null,
      Name?:  Array< {
        __typename: "PublicationName",
        FormattedName?: string | null,
        role?: string | null,
      } | null > | null,
      Title?: Array< string | null > | null,
      PublicationDate?: string | null,
    } | null > | null,
    Race?: string | null,
    References?:  Array< {
      __typename: "Reference",
      CompanyName?: string | null,
      PersonName?:  {
        __typename: "PersonName",
        FormattedName?: string | null,
        Title?: string | null,
        Suffix?: string | null,
        FamilyName?: string | null,
        GivenName?: string | null,
        PreferredGivenName?: string | null,
        MiddleName?: string | null,
        sex?: Gender | null,
      } | null,
      ContactMethod?:  {
        __typename: "ContactMethod",
        InternetEmailAddress_main?: string | null,
        InternetEmailAddress_alt?: string | null,
        Telephone_home?: string | null,
        Telephone_work?: string | null,
        Telephone_alt?: string | null,
        Telephone_mobile?: string | null,
        Pager?: string | null,
        Fax?: string | null,
      } | null,
      PositionTitle?: string | null,
    } | null > | null,
    RegisteredResidence?:  {
      __typename: "LocationSummary",
      CountryCode?: string | null,
      Municipality?: string | null,
      Region?: string | null,
      AddressLine?: string | null,
      PostalCode?: string | null,
    } | null,
    Religion?: string | null,
    RevisionDate?: string | null,
    Salary_desired?:  {
      __typename: "Salary",
      currency?: string | null,
      amount?: number | null,
      hiamount?: number | null,
      period?: SalaryPeriod | null,
    } | null,
    Salary_current?:  {
      __typename: "Salary",
      currency?: string | null,
      amount?: number | null,
      hiamount?: number | null,
      period?: SalaryPeriod | null,
    } | null,
    WillRelocate?: boolean | null,
    RawData?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateTimesheetSubscriptionVariables = {
  owner?: string | null,
};

export type OnCreateTimesheetSubscription = {
  onCreateTimesheet?:  {
    __typename: "Timesheet",
    id: string,
    owner: string,
    user?:  {
      __typename: "User",
      id: string,
      email?: string | null,
      name?: string | null,
      userType?: string | null,
      odooId?: string | null,
      customerId?: number | null,
    } | null,
    contractId?: number | null,
    employeeId?: number | null,
    employeeName?: string | null,
    customerId?: string | null,
    customerName?: string | null,
    jobId?: number | null,
    status?: TimesheetStatus | null,
    state?: TimesheetState | null,
    submitted?: boolean | null,
    submittedBy?: string | null,
    submittedByName?: string | null,
    submittedDate?: string | null,
    approver1?: string | null,
    approver1Name?: string | null,
    approver1Token?: string | null,
    approved1?: boolean | null,
    approved1By?: string | null,
    approved1ByName?: string | null,
    approved1Date?: string | null,
    approver2?: string | null,
    approver2Name?: string | null,
    approver2Token?: string | null,
    approved2?: boolean | null,
    approved2By?: string | null,
    approved2ByName?: string | null,
    approved2Date?: string | null,
    fromDate?: string | null,
    toDate?: string | null,
    description?: string | null,
    entries?:  Array< {
      __typename: "TimesheetEntry",
      date: string,
      type?: DayType | null,
      leaveType?: number | null,
      leaveId?: number | null,
    } | null > | null,
    managersComments?:  Array< {
      __typename: "TimesheetManagerComment",
      date: string,
      managerId?: string | null,
      message?: string | null,
    } | null > | null,
    statusChanges?:  Array< {
      __typename: "TimesheetStatusChange",
      date: string,
      userId?: string | null,
      status?: TimesheetStatus | null,
      message?: string | null,
    } | null > | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateTimesheetSubscriptionVariables = {
  owner?: string | null,
};

export type OnUpdateTimesheetSubscription = {
  onUpdateTimesheet?:  {
    __typename: "Timesheet",
    id: string,
    owner: string,
    user?:  {
      __typename: "User",
      id: string,
      email?: string | null,
      name?: string | null,
      userType?: string | null,
      odooId?: string | null,
      customerId?: number | null,
    } | null,
    contractId?: number | null,
    employeeId?: number | null,
    employeeName?: string | null,
    customerId?: string | null,
    customerName?: string | null,
    jobId?: number | null,
    status?: TimesheetStatus | null,
    state?: TimesheetState | null,
    submitted?: boolean | null,
    submittedBy?: string | null,
    submittedByName?: string | null,
    submittedDate?: string | null,
    approver1?: string | null,
    approver1Name?: string | null,
    approver1Token?: string | null,
    approved1?: boolean | null,
    approved1By?: string | null,
    approved1ByName?: string | null,
    approved1Date?: string | null,
    approver2?: string | null,
    approver2Name?: string | null,
    approver2Token?: string | null,
    approved2?: boolean | null,
    approved2By?: string | null,
    approved2ByName?: string | null,
    approved2Date?: string | null,
    fromDate?: string | null,
    toDate?: string | null,
    description?: string | null,
    entries?:  Array< {
      __typename: "TimesheetEntry",
      date: string,
      type?: DayType | null,
      leaveType?: number | null,
      leaveId?: number | null,
    } | null > | null,
    managersComments?:  Array< {
      __typename: "TimesheetManagerComment",
      date: string,
      managerId?: string | null,
      message?: string | null,
    } | null > | null,
    statusChanges?:  Array< {
      __typename: "TimesheetStatusChange",
      date: string,
      userId?: string | null,
      status?: TimesheetStatus | null,
      message?: string | null,
    } | null > | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteTimesheetSubscriptionVariables = {
  owner?: string | null,
};

export type OnDeleteTimesheetSubscription = {
  onDeleteTimesheet?:  {
    __typename: "Timesheet",
    id: string,
    owner: string,
    user?:  {
      __typename: "User",
      id: string,
      email?: string | null,
      name?: string | null,
      userType?: string | null,
      odooId?: string | null,
      customerId?: number | null,
    } | null,
    contractId?: number | null,
    employeeId?: number | null,
    employeeName?: string | null,
    customerId?: string | null,
    customerName?: string | null,
    jobId?: number | null,
    status?: TimesheetStatus | null,
    state?: TimesheetState | null,
    submitted?: boolean | null,
    submittedBy?: string | null,
    submittedByName?: string | null,
    submittedDate?: string | null,
    approver1?: string | null,
    approver1Name?: string | null,
    approver1Token?: string | null,
    approved1?: boolean | null,
    approved1By?: string | null,
    approved1ByName?: string | null,
    approved1Date?: string | null,
    approver2?: string | null,
    approver2Name?: string | null,
    approver2Token?: string | null,
    approved2?: boolean | null,
    approved2By?: string | null,
    approved2ByName?: string | null,
    approved2Date?: string | null,
    fromDate?: string | null,
    toDate?: string | null,
    description?: string | null,
    entries?:  Array< {
      __typename: "TimesheetEntry",
      date: string,
      type?: DayType | null,
      leaveType?: number | null,
      leaveId?: number | null,
    } | null > | null,
    managersComments?:  Array< {
      __typename: "TimesheetManagerComment",
      date: string,
      managerId?: string | null,
      message?: string | null,
    } | null > | null,
    statusChanges?:  Array< {
      __typename: "TimesheetStatusChange",
      date: string,
      userId?: string | null,
      status?: TimesheetStatus | null,
      message?: string | null,
    } | null > | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateNotificationSubscription = {
  onCreateNotification?:  {
    __typename: "Notification",
    id: string,
    status?: NotificationStatus | null,
    date?: string | null,
    user?: string | null,
    template?: string | null,
    parameters?: string | null,
    timesheetId?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateNotificationSubscription = {
  onUpdateNotification?:  {
    __typename: "Notification",
    id: string,
    status?: NotificationStatus | null,
    date?: string | null,
    user?: string | null,
    template?: string | null,
    parameters?: string | null,
    timesheetId?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteNotificationSubscription = {
  onDeleteNotification?:  {
    __typename: "Notification",
    id: string,
    status?: NotificationStatus | null,
    date?: string | null,
    user?: string | null,
    template?: string | null,
    parameters?: string | null,
    timesheetId?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateRequestSubscriptionVariables = {
  owner?: string | null,
};

export type OnCreateRequestSubscription = {
  onCreateRequest?:  {
    __typename: "Request",
    id: string,
    owner: string,
    user?:  {
      __typename: "User",
      id: string,
      email?: string | null,
      name?: string | null,
      userType?: string | null,
      odooId?: string | null,
      customerId?: number | null,
    } | null,
    employeeId?: number | null,
    employeeName?: string | null,
    status?: RequestStatus | null,
    type?: RequestType | null,
    requestDate?: string | null,
    entityName?: string | null,
    notes?: string | null,
    drivingInstitureName?: string | null,
    emirate?: string | null,
    country?: string | null,
    dateFrom?: string | null,
    dateTo?: string | null,
    expectedAmount?: string | null,
    receivedAmount?: string | null,
    months?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateRequestSubscriptionVariables = {
  owner?: string | null,
};

export type OnUpdateRequestSubscription = {
  onUpdateRequest?:  {
    __typename: "Request",
    id: string,
    owner: string,
    user?:  {
      __typename: "User",
      id: string,
      email?: string | null,
      name?: string | null,
      userType?: string | null,
      odooId?: string | null,
      customerId?: number | null,
    } | null,
    employeeId?: number | null,
    employeeName?: string | null,
    status?: RequestStatus | null,
    type?: RequestType | null,
    requestDate?: string | null,
    entityName?: string | null,
    notes?: string | null,
    drivingInstitureName?: string | null,
    emirate?: string | null,
    country?: string | null,
    dateFrom?: string | null,
    dateTo?: string | null,
    expectedAmount?: string | null,
    receivedAmount?: string | null,
    months?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteRequestSubscriptionVariables = {
  owner?: string | null,
};

export type OnDeleteRequestSubscription = {
  onDeleteRequest?:  {
    __typename: "Request",
    id: string,
    owner: string,
    user?:  {
      __typename: "User",
      id: string,
      email?: string | null,
      name?: string | null,
      userType?: string | null,
      odooId?: string | null,
      customerId?: number | null,
    } | null,
    employeeId?: number | null,
    employeeName?: string | null,
    status?: RequestStatus | null,
    type?: RequestType | null,
    requestDate?: string | null,
    entityName?: string | null,
    notes?: string | null,
    drivingInstitureName?: string | null,
    emirate?: string | null,
    country?: string | null,
    dateFrom?: string | null,
    dateTo?: string | null,
    expectedAmount?: string | null,
    receivedAmount?: string | null,
    months?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};
