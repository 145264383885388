import React from 'react';
import InfiniteTable, { InfiniteTableProps } from './InfiniteTable';
import { API } from 'aws-amplify';
import { IConnectListQuery } from '../types';
import { Subtract } from 'utility-types';

type ConnectedInfiniteTableProps<T> = InfiniteTableProps<T> & {
  queryGenerator: (nextToken: string | null) => { query: any; variables: {} };
  opName: string;
};

type InjectedProps<T> = {
  loadMore: () => Promise<void>;
  hasMoreData: boolean;
  loading: boolean;
  data: T[];
};

type State<T> = {
  nextToken: string | null;
  data: T[];
  loading: boolean;
};

class ConnectedInfiniteTable<RecordType, OpName extends string> extends React.Component<
  Subtract<ConnectedInfiniteTableProps<RecordType>, InjectedProps<RecordType>>,
  State<RecordType>
> {
  state = {
    nextToken: null,
    loading: false,
    data: [],
  };
  componentDidMount(): void {
    if (this.props.tableId) {
      // Add listener for table refresh
      window.addEventListener(`refresh#${this.props.tableId}`, async () => {
        this.setState(
          {
            nextToken: null,
            data: [],
          },
          () => {
            this.loadMore();
          },
        );
      });
    }
  }
  loadMore = async (): Promise<void> => {
    this.setState({ loading: true });
    const query = this.props.queryGenerator(this.state.nextToken);
    const response = (await API.graphql(query)) as IConnectListQuery<RecordType, OpName>;
    if (response.data) {
      if (response.data[this.props.opName as OpName]) {
        this.setState((state: State<RecordType>) => ({
          data: [...state.data, ...response.data[this.props.opName as OpName].items],
          nextToken: response.data[this.props.opName as OpName].nextToken,
          loading: false,
        }));
        return;
      }
    }
    this.setState({ loading: false });
  };
  render(): React.ReactElement {
    return (
      <InfiniteTable
        {...this.props}
        loadMore={this.loadMore}
        hasMoreData={this.state.nextToken !== null}
        loading={this.state.loading}
        data={this.state.data}
      />
    );
  }
}

export default ConnectedInfiniteTable;
