import React from 'react';
import { Spin } from 'antd';
import { BaseAlphaUI } from 'basealpha-ui';

export interface IAppState {
  user?: any;
}

export interface IAppStateContext {
  appState?: IAppState;
  setAppState?: (key: string, value: any) => void;
}

export const AppState = React.createContext<IAppStateContext>({});

const STATE_KEY_NAME = 'ehcmsState';

interface State {
  rehydrated: boolean;
  appState?: IAppState;
}

class AppStateGate extends React.Component<any, State> {
  state = {
    rehydrated: false,
    appState: undefined,
  };

  signingOut = false;

  componentDidMount(): void {
    const appState = window.localStorage.getItem(STATE_KEY_NAME);
    this.setState({
      rehydrated: true,
      appState: appState ? JSON.parse(appState) : {},
    });
    BaseAlphaUI.pushSignOutEvent(this.onSignOut);
  }

  onSignOut = (): void => {
    this.signingOut = true;
    window.localStorage.removeItem(STATE_KEY_NAME);
  };

  componentWillUnmount(): void {
    if (this.state.appState && !this.signingOut) {
      this.persist();
    }
  }

  persist = (): void => {
    window.localStorage.setItem(STATE_KEY_NAME, JSON.stringify(this.state.appState));
  };

  setAppState = (key: string, value: any): void => {
    this.setState(
      (state: State) => ({
        appState: {
          ...state.appState,
          [key]: value,
        },
      }),
      () => {
        this.persist();
      },
    );
  };

  render(): React.ReactNode {
    if (!this.state.rehydrated) {
      return (
        <div style={{ display: 'flex', flex: 1, alignItems: 'center', justifyContent: 'center', height: '100vh' }}>
          <Spin />
        </div>
      );
    }
    return (
      <AppState.Provider
        value={{
          appState: this.state.appState,
          setAppState: this.setAppState,
        }}
      >
        {this.props.children}
      </AppState.Provider>
    );
  }
}

export default AppStateGate;
