/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createCandidateResume = /* GraphQL */ `
  mutation CreateCandidateResume(
    $input: CreateCandidateResumeInput!
    $condition: ModelCandidateResumeConditionInput
  ) {
    createCandidateResume(input: $input, condition: $condition) {
      id
      owner
      submitted
      verified
      ExperienceSummary {
        TotalYearsOfManagementWorkExperience
        TotalYearsOfLowLevelManagementWorkExperience
        TotalMonthsOfWorkExperience
        ExecutiveBrief
        TotalMonthsOfLowLevelManagementWorkExperience
        TotalMonthsOfManagementWorkExperience
        TotalYearsOfMidLevelManagementWorkExperience
        TotalMonthsOfMidLevelManagementWorkExperience
        TotalYearsOfSeniorManagementWorkExperience
        TotalMonthsOfSeniorManagementWorkExperience
        ManagementRecord
        HighestEducationalLevel
        TotalYearsOfWorkExperience
      }
      Achievements
      Available
      Competency {
        skillLevel
        skillUsed {
          value
          type
        }
        skillProficiency
        Grade
        skillName
        description
        auth
        skillCount
        lastUsed
        CompetencyId {
          idOwner
          id
        }
        TaxonomyId {
          idOwner
          description
        }
        skillAliasArray
      }
      ContactMethod {
        InternetWebAddress {
          content
          type
        }
        InternetEmailAddress_main
        InternetEmailAddress_alt
        Telephone_home
        Telephone_work
        Telephone_alt
        Telephone_mobile
        Pager
        Fax
        PostalAddress_main {
          CountryCode
          Municipality
          Region
          AddressLine
          PostalCode
        }
        PostalAddress_alt {
          CountryCode
          Municipality
          Region
          AddressLine
          PostalCode
        }
      }
      DOB
      EducationHistory {
        StartDate
        EndDate
        MeasureValue
        Major
        SchoolUnit
        Degree {
          degreeType
          DegreeName
          DegreeDate
        }
        LocationSummary {
          CountryCode
          Municipality
          Region
          AddressLine
          PostalCode
        }
        SchoolName
        schoolType
        Comments
      }
      EmploymentHistory {
        StartDate
        EndDate
        LocationSummary {
          CountryCode
          Municipality
          Region
          AddressLine
          PostalCode
        }
        InternetDomainName
        EmployerOrgName
        employerOrgType
        PositionType
        MonthsOfWork
        TimelineNumber
        Title
        ReasonForLeaving
        OrgIndustry {
          IndustryDescription
          primaryIndicator
        }
        JobArea
        JobGrade
        Project
        OrgName
        Description
      }
      ExecutiveSummary
      Hobbies
      LicensesAndCertifications {
        IssuingAuthority {
          IssuingAuthorityName
          countryCode
        }
        Id
        EffectiveDate {
          ValidFrom
          FirstIssuedDate
          ValidTo
        }
        LicensesOrCertificationName
        Description
      }
      Languages {
        CompetencyId {
          idOwner
          id
        }
        Proficiency
        Speak
        LanguageCode
        Write
        Read
      }
      lang
      LegalDocuments {
        IssueCountryCode
        IssueAuthority
        IssueDate
        Number
        IssueLocationSummary {
          CountryCode
          Municipality
          Region
          AddressLine
          PostalCode
        }
        type
      }
      MaritalStatus
      Nationality
      OtherInfo
      PersonName {
        FormattedName
        Title
        Suffix
        FamilyName
        GivenName
        PreferredGivenName
        MiddleName
        sex
      }
      PlaceOfBirth {
        CountryCode
        Municipality
        Region
        AddressLine
        PostalCode
      }
      PoliticalStatus
      PreferredLocation
      PreferredPosition
      PreferredPositionType
      PreferredTypeOfHours
      ProjectHistory {
        StartDate
        EndDate
        LocationSummary {
          CountryCode
          Municipality
          Region
          AddressLine
          PostalCode
        }
        InternetDomainName
        EmployerOrgName
        employerOrgType
        PositionType
        MonthsOfWork
        TimelineNumber
        Title
        ReasonForLeaving
        OrgIndustry {
          IndustryDescription
          primaryIndicator
        }
        JobArea
        JobGrade
        Project
        OrgName
        Description
      }
      PublicationHistory {
        Comments
        content
        Issue
        JournalOrSerialName
        Volume
        PageNumber
        Name {
          FormattedName
          role
        }
        Title
        PublicationDate
      }
      Race
      References {
        CompanyName
        PersonName {
          FormattedName
          Title
          Suffix
          FamilyName
          GivenName
          PreferredGivenName
          MiddleName
          sex
        }
        ContactMethod {
          InternetEmailAddress_main
          InternetEmailAddress_alt
          Telephone_home
          Telephone_work
          Telephone_alt
          Telephone_mobile
          Pager
          Fax
        }
        PositionTitle
      }
      RegisteredResidence {
        CountryCode
        Municipality
        Region
        AddressLine
        PostalCode
      }
      Religion
      RevisionDate
      Salary_desired {
        currency
        amount
        hiamount
        period
      }
      Salary_current {
        currency
        amount
        hiamount
        period
      }
      WillRelocate
      RawData
      createdAt
      updatedAt
    }
  }
`;
export const updateCandidateResume = /* GraphQL */ `
  mutation UpdateCandidateResume(
    $input: UpdateCandidateResumeInput!
    $condition: ModelCandidateResumeConditionInput
  ) {
    updateCandidateResume(input: $input, condition: $condition) {
      id
      owner
      submitted
      verified
      ExperienceSummary {
        TotalYearsOfManagementWorkExperience
        TotalYearsOfLowLevelManagementWorkExperience
        TotalMonthsOfWorkExperience
        ExecutiveBrief
        TotalMonthsOfLowLevelManagementWorkExperience
        TotalMonthsOfManagementWorkExperience
        TotalYearsOfMidLevelManagementWorkExperience
        TotalMonthsOfMidLevelManagementWorkExperience
        TotalYearsOfSeniorManagementWorkExperience
        TotalMonthsOfSeniorManagementWorkExperience
        ManagementRecord
        HighestEducationalLevel
        TotalYearsOfWorkExperience
      }
      Achievements
      Available
      Competency {
        skillLevel
        skillUsed {
          value
          type
        }
        skillProficiency
        Grade
        skillName
        description
        auth
        skillCount
        lastUsed
        CompetencyId {
          idOwner
          id
        }
        TaxonomyId {
          idOwner
          description
        }
        skillAliasArray
      }
      ContactMethod {
        InternetWebAddress {
          content
          type
        }
        InternetEmailAddress_main
        InternetEmailAddress_alt
        Telephone_home
        Telephone_work
        Telephone_alt
        Telephone_mobile
        Pager
        Fax
        PostalAddress_main {
          CountryCode
          Municipality
          Region
          AddressLine
          PostalCode
        }
        PostalAddress_alt {
          CountryCode
          Municipality
          Region
          AddressLine
          PostalCode
        }
      }
      DOB
      EducationHistory {
        StartDate
        EndDate
        MeasureValue
        Major
        SchoolUnit
        Degree {
          degreeType
          DegreeName
          DegreeDate
        }
        LocationSummary {
          CountryCode
          Municipality
          Region
          AddressLine
          PostalCode
        }
        SchoolName
        schoolType
        Comments
      }
      EmploymentHistory {
        StartDate
        EndDate
        LocationSummary {
          CountryCode
          Municipality
          Region
          AddressLine
          PostalCode
        }
        InternetDomainName
        EmployerOrgName
        employerOrgType
        PositionType
        MonthsOfWork
        TimelineNumber
        Title
        ReasonForLeaving
        OrgIndustry {
          IndustryDescription
          primaryIndicator
        }
        JobArea
        JobGrade
        Project
        OrgName
        Description
      }
      ExecutiveSummary
      Hobbies
      LicensesAndCertifications {
        IssuingAuthority {
          IssuingAuthorityName
          countryCode
        }
        Id
        EffectiveDate {
          ValidFrom
          FirstIssuedDate
          ValidTo
        }
        LicensesOrCertificationName
        Description
      }
      Languages {
        CompetencyId {
          idOwner
          id
        }
        Proficiency
        Speak
        LanguageCode
        Write
        Read
      }
      lang
      LegalDocuments {
        IssueCountryCode
        IssueAuthority
        IssueDate
        Number
        IssueLocationSummary {
          CountryCode
          Municipality
          Region
          AddressLine
          PostalCode
        }
        type
      }
      MaritalStatus
      Nationality
      OtherInfo
      PersonName {
        FormattedName
        Title
        Suffix
        FamilyName
        GivenName
        PreferredGivenName
        MiddleName
        sex
      }
      PlaceOfBirth {
        CountryCode
        Municipality
        Region
        AddressLine
        PostalCode
      }
      PoliticalStatus
      PreferredLocation
      PreferredPosition
      PreferredPositionType
      PreferredTypeOfHours
      ProjectHistory {
        StartDate
        EndDate
        LocationSummary {
          CountryCode
          Municipality
          Region
          AddressLine
          PostalCode
        }
        InternetDomainName
        EmployerOrgName
        employerOrgType
        PositionType
        MonthsOfWork
        TimelineNumber
        Title
        ReasonForLeaving
        OrgIndustry {
          IndustryDescription
          primaryIndicator
        }
        JobArea
        JobGrade
        Project
        OrgName
        Description
      }
      PublicationHistory {
        Comments
        content
        Issue
        JournalOrSerialName
        Volume
        PageNumber
        Name {
          FormattedName
          role
        }
        Title
        PublicationDate
      }
      Race
      References {
        CompanyName
        PersonName {
          FormattedName
          Title
          Suffix
          FamilyName
          GivenName
          PreferredGivenName
          MiddleName
          sex
        }
        ContactMethod {
          InternetEmailAddress_main
          InternetEmailAddress_alt
          Telephone_home
          Telephone_work
          Telephone_alt
          Telephone_mobile
          Pager
          Fax
        }
        PositionTitle
      }
      RegisteredResidence {
        CountryCode
        Municipality
        Region
        AddressLine
        PostalCode
      }
      Religion
      RevisionDate
      Salary_desired {
        currency
        amount
        hiamount
        period
      }
      Salary_current {
        currency
        amount
        hiamount
        period
      }
      WillRelocate
      RawData
      createdAt
      updatedAt
    }
  }
`;
export const deleteCandidateResume = /* GraphQL */ `
  mutation DeleteCandidateResume(
    $input: DeleteCandidateResumeInput!
    $condition: ModelCandidateResumeConditionInput
  ) {
    deleteCandidateResume(input: $input, condition: $condition) {
      id
      owner
      submitted
      verified
      ExperienceSummary {
        TotalYearsOfManagementWorkExperience
        TotalYearsOfLowLevelManagementWorkExperience
        TotalMonthsOfWorkExperience
        ExecutiveBrief
        TotalMonthsOfLowLevelManagementWorkExperience
        TotalMonthsOfManagementWorkExperience
        TotalYearsOfMidLevelManagementWorkExperience
        TotalMonthsOfMidLevelManagementWorkExperience
        TotalYearsOfSeniorManagementWorkExperience
        TotalMonthsOfSeniorManagementWorkExperience
        ManagementRecord
        HighestEducationalLevel
        TotalYearsOfWorkExperience
      }
      Achievements
      Available
      Competency {
        skillLevel
        skillUsed {
          value
          type
        }
        skillProficiency
        Grade
        skillName
        description
        auth
        skillCount
        lastUsed
        CompetencyId {
          idOwner
          id
        }
        TaxonomyId {
          idOwner
          description
        }
        skillAliasArray
      }
      ContactMethod {
        InternetWebAddress {
          content
          type
        }
        InternetEmailAddress_main
        InternetEmailAddress_alt
        Telephone_home
        Telephone_work
        Telephone_alt
        Telephone_mobile
        Pager
        Fax
        PostalAddress_main {
          CountryCode
          Municipality
          Region
          AddressLine
          PostalCode
        }
        PostalAddress_alt {
          CountryCode
          Municipality
          Region
          AddressLine
          PostalCode
        }
      }
      DOB
      EducationHistory {
        StartDate
        EndDate
        MeasureValue
        Major
        SchoolUnit
        Degree {
          degreeType
          DegreeName
          DegreeDate
        }
        LocationSummary {
          CountryCode
          Municipality
          Region
          AddressLine
          PostalCode
        }
        SchoolName
        schoolType
        Comments
      }
      EmploymentHistory {
        StartDate
        EndDate
        LocationSummary {
          CountryCode
          Municipality
          Region
          AddressLine
          PostalCode
        }
        InternetDomainName
        EmployerOrgName
        employerOrgType
        PositionType
        MonthsOfWork
        TimelineNumber
        Title
        ReasonForLeaving
        OrgIndustry {
          IndustryDescription
          primaryIndicator
        }
        JobArea
        JobGrade
        Project
        OrgName
        Description
      }
      ExecutiveSummary
      Hobbies
      LicensesAndCertifications {
        IssuingAuthority {
          IssuingAuthorityName
          countryCode
        }
        Id
        EffectiveDate {
          ValidFrom
          FirstIssuedDate
          ValidTo
        }
        LicensesOrCertificationName
        Description
      }
      Languages {
        CompetencyId {
          idOwner
          id
        }
        Proficiency
        Speak
        LanguageCode
        Write
        Read
      }
      lang
      LegalDocuments {
        IssueCountryCode
        IssueAuthority
        IssueDate
        Number
        IssueLocationSummary {
          CountryCode
          Municipality
          Region
          AddressLine
          PostalCode
        }
        type
      }
      MaritalStatus
      Nationality
      OtherInfo
      PersonName {
        FormattedName
        Title
        Suffix
        FamilyName
        GivenName
        PreferredGivenName
        MiddleName
        sex
      }
      PlaceOfBirth {
        CountryCode
        Municipality
        Region
        AddressLine
        PostalCode
      }
      PoliticalStatus
      PreferredLocation
      PreferredPosition
      PreferredPositionType
      PreferredTypeOfHours
      ProjectHistory {
        StartDate
        EndDate
        LocationSummary {
          CountryCode
          Municipality
          Region
          AddressLine
          PostalCode
        }
        InternetDomainName
        EmployerOrgName
        employerOrgType
        PositionType
        MonthsOfWork
        TimelineNumber
        Title
        ReasonForLeaving
        OrgIndustry {
          IndustryDescription
          primaryIndicator
        }
        JobArea
        JobGrade
        Project
        OrgName
        Description
      }
      PublicationHistory {
        Comments
        content
        Issue
        JournalOrSerialName
        Volume
        PageNumber
        Name {
          FormattedName
          role
        }
        Title
        PublicationDate
      }
      Race
      References {
        CompanyName
        PersonName {
          FormattedName
          Title
          Suffix
          FamilyName
          GivenName
          PreferredGivenName
          MiddleName
          sex
        }
        ContactMethod {
          InternetEmailAddress_main
          InternetEmailAddress_alt
          Telephone_home
          Telephone_work
          Telephone_alt
          Telephone_mobile
          Pager
          Fax
        }
        PositionTitle
      }
      RegisteredResidence {
        CountryCode
        Municipality
        Region
        AddressLine
        PostalCode
      }
      Religion
      RevisionDate
      Salary_desired {
        currency
        amount
        hiamount
        period
      }
      Salary_current {
        currency
        amount
        hiamount
        period
      }
      WillRelocate
      RawData
      createdAt
      updatedAt
    }
  }
`;
export const createTimesheet = /* GraphQL */ `
  mutation CreateTimesheet(
    $input: CreateTimesheetInput!
    $condition: ModelTimesheetConditionInput
  ) {
    createTimesheet(input: $input, condition: $condition) {
      id
      owner
      user {
        id
        email
        name
        userType
        odooId
        customerId
      }
      contractId
      employeeId
      employeeName
      customerId
      customerName
      jobId
      status
      state
      submitted
      submittedBy
      submittedByName
      submittedDate
      approver1
      approver1Name
      approver1Token
      approved1
      approved1By
      approved1ByName
      approved1Date
      approver2
      approver2Name
      approver2Token
      approved2
      approved2By
      approved2ByName
      approved2Date
      fromDate
      toDate
      description
      entries {
        date
        type
        leaveType
        leaveId
      }
      managersComments {
        date
        managerId
        message
      }
      statusChanges {
        date
        userId
        status
        message
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteTimesheet = /* GraphQL */ `
  mutation DeleteTimesheet(
    $input: DeleteTimesheetInput!
    $condition: ModelTimesheetConditionInput
  ) {
    deleteTimesheet(input: $input, condition: $condition) {
      id
      owner
      user {
        id
        email
        name
        userType
        odooId
        customerId
      }
      contractId
      employeeId
      employeeName
      customerId
      customerName
      jobId
      status
      state
      submitted
      submittedBy
      submittedByName
      submittedDate
      approver1
      approver1Name
      approver1Token
      approved1
      approved1By
      approved1ByName
      approved1Date
      approver2
      approver2Name
      approver2Token
      approved2
      approved2By
      approved2ByName
      approved2Date
      fromDate
      toDate
      description
      entries {
        date
        type
        leaveType
        leaveId
      }
      managersComments {
        date
        managerId
        message
      }
      statusChanges {
        date
        userId
        status
        message
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateTimesheet = /* GraphQL */ `
  mutation UpdateTimesheet(
    $input: UpdateTimesheetInput!
    $condition: ModelTimesheetConditionInput
  ) {
    updateTimesheet(input: $input, condition: $condition) {
      id
      owner
      user {
        id
        email
        name
        userType
        odooId
        customerId
      }
      contractId
      employeeId
      employeeName
      customerId
      customerName
      jobId
      status
      state
      submitted
      submittedBy
      submittedByName
      submittedDate
      approver1
      approver1Name
      approver1Token
      approved1
      approved1By
      approved1ByName
      approved1Date
      approver2
      approver2Name
      approver2Token
      approved2
      approved2By
      approved2ByName
      approved2Date
      fromDate
      toDate
      description
      entries {
        date
        type
        leaveType
        leaveId
      }
      managersComments {
        date
        managerId
        message
      }
      statusChanges {
        date
        userId
        status
        message
      }
      createdAt
      updatedAt
    }
  }
`;
export const createNotification = /* GraphQL */ `
  mutation CreateNotification(
    $input: CreateNotificationInput!
    $condition: ModelNotificationConditionInput
  ) {
    createNotification(input: $input, condition: $condition) {
      id
      status
      date
      user
      template
      parameters
      timesheetId
      createdAt
      updatedAt
    }
  }
`;
export const updateNotification = /* GraphQL */ `
  mutation UpdateNotification(
    $input: UpdateNotificationInput!
    $condition: ModelNotificationConditionInput
  ) {
    updateNotification(input: $input, condition: $condition) {
      id
      status
      date
      user
      template
      parameters
      timesheetId
      createdAt
      updatedAt
    }
  }
`;
export const deleteNotification = /* GraphQL */ `
  mutation DeleteNotification(
    $input: DeleteNotificationInput!
    $condition: ModelNotificationConditionInput
  ) {
    deleteNotification(input: $input, condition: $condition) {
      id
      status
      date
      user
      template
      parameters
      timesheetId
      createdAt
      updatedAt
    }
  }
`;
export const createRequest = /* GraphQL */ `
  mutation CreateRequest(
    $input: CreateRequestInput!
    $condition: ModelRequestConditionInput
  ) {
    createRequest(input: $input, condition: $condition) {
      id
      owner
      user {
        id
        email
        name
        userType
        odooId
        customerId
      }
      employeeId
      employeeName
      status
      type
      requestDate
      entityName
      notes
      drivingInstitureName
      emirate
      country
      dateFrom
      dateTo
      expectedAmount
      receivedAmount
      months
      createdAt
      updatedAt
    }
  }
`;
export const deleteRequest = /* GraphQL */ `
  mutation DeleteRequest(
    $input: DeleteRequestInput!
    $condition: ModelRequestConditionInput
  ) {
    deleteRequest(input: $input, condition: $condition) {
      id
      owner
      user {
        id
        email
        name
        userType
        odooId
        customerId
      }
      employeeId
      employeeName
      status
      type
      requestDate
      entityName
      notes
      drivingInstitureName
      emirate
      country
      dateFrom
      dateTo
      expectedAmount
      receivedAmount
      months
      createdAt
      updatedAt
    }
  }
`;
export const updateRequest = /* GraphQL */ `
  mutation UpdateRequest(
    $input: UpdateRequestInput!
    $condition: ModelRequestConditionInput
  ) {
    updateRequest(input: $input, condition: $condition) {
      id
      owner
      user {
        id
        email
        name
        userType
        odooId
        customerId
      }
      employeeId
      employeeName
      status
      type
      requestDate
      entityName
      notes
      drivingInstitureName
      emirate
      country
      dateFrom
      dateTo
      expectedAmount
      receivedAmount
      months
      createdAt
      updatedAt
    }
  }
`;
