import React from 'react';
import { Form, Input } from 'formik-antd';

const TimesheetQuery = (): React.ReactElement => {
  return (
    <>
      <h3>Timesheet Query</h3>
      <Form.Item name={`notes`}>
        <Input.TextArea name="notes" />
      </Form.Item>
    </>
  );
};

export default TimesheetQuery;
