import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { pick } from 'lodash';
import { Timesheet, IConnectGetQuery } from '../../types';
import { API, graphqlOperation } from 'aws-amplify';
import { getTimesheet } from '../../graphql/queries';
import Loader from '../../Components/Loader';
import { message } from 'antd';
import TimesheetForm from './partials/TimesheetForm';
import { CreateTimesheetInput, UpdateTimesheetInput } from '../../API';
import { updateTimesheet } from '../../graphql/mutations';
import mixpanel from 'mixpanel-browser';

type Props = RouteComponentProps<{ id: string }, {}, { record: any}> & {};
type State = {
  timesheet?: Timesheet | null;
  loading: boolean;
};
class Form extends React.Component<Props, State> {
  state = {
    timesheet: null,
    loading: false,
  };
  async componentDidMount(): Promise<void> {
    if (this.props.location.state?.record) {
      // We passed it from the list in the state
      this.setState({
        timesheet: this.props.location.state.record,
      });
      mixpanel.track('View Timesheet', {
        timesheetId: this.props.location.state.record.id,
      });
    } else if (this.props.match.params.id) {
      // We should load from the API
      try {
        this.setState({
          loading: true,
        });
        const timesheet = (await API.graphql(
          graphqlOperation(getTimesheet, { id: this.props.match.params.id }),
        )) as IConnectGetQuery<Timesheet, 'getTimesheet'>;
        if (timesheet.data && timesheet.data.getTimesheet) {
          this.setState({
            timesheet: timesheet.data.getTimesheet,
            loading: false,
          });
          mixpanel.track('View Timesheet', {
            timesheetId: timesheet.data.getTimesheet.id,
          });
        }
      } catch (e) {
        message.error('Timesheet not found');
        this.props.history.goBack();
      }
    }
  }
  save = async (values: CreateTimesheetInput & { submitting?: boolean }): Promise<any> => {
    try {
      const submitting = values.submitting;
      if (typeof values.submitting !== 'undefined') {
        delete values.submitting;
      }
      const op = updateTimesheet;
      const opName = 'updateTimesheet';
      const vals = pick(values, ['id', 'status', 'submitted', 'submittedDate', 'entries', 'fromDate']);
      const response = await API.graphql(graphqlOperation(op, { input: vals }));
      if (response && response.data && response.data[opName]) {
        if (submitting) {
          try {
            const submitResponse = await API.post('EhcmsPortalApi', '/contractor/timesheet/submit', {
              body: {
                timesheetId: values.id,
              },
            });
            message.success('Your timesheet was submitted for approval');
            mixpanel.track('Submit Timesheet', {
              timesheetId: values.id,
            });
            this.props.history.push('/timesheets');
            return submitResponse;
          } catch (e) {
            let error = e.response?.data?.message || 'Error submitting timesheet';
            if (
              error ===
              'The number of remaining time off is not sufficient for this time off type.\\nPlease also check the time off waiting for validation.'
            ) {
              error = `You don't have enough leave days available`;
            }
            message.error(error);
          }
        } else {
          message.success('Your timesheet was saved');
          this.props.history.push('/timesheets');
          return response.data[opName];
        }
      }
      // return Promise.reject(new Error('Error saving your timesheet'));
    } catch (e) {
      // return Promise.reject(e);
    }
  };
  submitTimehseet = async (values: CreateTimesheetInput): Promise<any> => {
    try {
      const op = updateTimesheet;
      const opName = 'updateTimesheet';
      const response = await API.graphql(graphqlOperation(op, { input: values }));
      if (response && response.data && response.data[opName]) {
        // return response.data[opName];
        try {
          const submitResponse = API.post('EhcmsPortalApi', '/contractor/timesheet/submit', {
            body: {
              id: values.id,
            },
          });
          message.success('Your timesheet was submitted for approval');
          mixpanel.track('Reject Timesheet', {
            timesheetId: values.id,
          });
          this.props.history.push('/timesheets');
          return submitResponse;
        } catch (e) {
          message.error(e.message);
        }
      }
      return Promise.reject(new Error('Error saving your timesheet'));
    } catch (e) {
      return Promise.reject(e);
    }
  };
  close = (): void => {
    this.props.history.goBack();
  };
  render(): React.ReactElement {
    if (this.state.loading) {
      return <Loader />;
    }
    if (this.state.timesheet) {
      return (
        <TimesheetForm
          record={this.state.timesheet}
          save={this.save}
          submitTimesheet={this.submitTimehseet}
          close={this.close}
        />
      );
    }
    return <Loader />;
  }
}

export default Form;
