import React from 'react';
import { Form, Input, Select } from 'formik-antd';

const NocDrivingLicense = (): React.ReactElement => {
  return (
    <>
      <h3>NOC - Driving License</h3>
      <Form.Item name={`drivingInstitureName`} label="Driving Institute Name">
        <Input name="drivingInstitureName" />
      </Form.Item>
      <Form.Item name={`emirate`} label="Emirate">
        <Select name={`emirate`}>
          {Select.renderOptions([
            { value: 'Abu Dhabi', label: 'Abu Dhabi' },
            { value: 'Ajman', label: 'Ajman' },
            { value: 'Dubai', label: 'Dubai' },
            { value: 'Fujairah', label: 'Fujairah' },
            { value: 'Ras al-Khaimah', label: 'Ras al-Khaimah' },
            { value: 'Sharjah', label: 'Sharjah' },
            { value: 'Umm al-Quwain', label: 'Umm al-Quwain' },
          ])}
        </Select>
      </Form.Item>
    </>
  );
};

export default NocDrivingLicense;
