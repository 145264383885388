import React from 'react';
import { Link } from 'react-router-dom';
import { Menu, Icon } from 'antd';

const AppMenu: React.FC = (): React.ReactElement => (
  <Menu theme="dark" mode="inline">
    <Menu.Item key="profile">
      <Link to="/profile">
        <Icon type="time" />
        <span>Profile</span>
      </Link>
    </Menu.Item>
    <Menu.Item key="1">
      <Link to="/timesheets">
        <Icon type="time" />
        <span>Timesheets</span>
      </Link>
    </Menu.Item>
    <Menu.Item key="2">
      <Link to="/requests">
        <Icon type="time" />
        <span>Requests</span>
      </Link>
    </Menu.Item>
  </Menu>
);

export default AppMenu;
