import React from 'react';
import ProfileSetup from '../Pages/Profile/ProfileSetup';
import * as Sentry from '@sentry/react';
import withAppState, { IAppStateComponentProps } from '../state/withAppState';
import UserModel from '../Models/User';
import Loader from './Loader';
import { Auth } from 'aws-amplify';

type Props = IAppStateComponentProps;

class UserProfileGate extends React.Component<Props> {
  async componentDidMount(): Promise<void> {
    if (!this.props.appState?.user || !this.props.appState?.user?.profile_completed) {
      try {
        const profile = await UserModel.getProfile();
        this.props.setAppState('user', profile);
      } catch (e) {
        Sentry.captureMessage(`Error getting user profile: ${JSON.stringify(e)}`);
        Sentry.captureException(e);
        Auth.signOut();
      }
    }
  }

  render(): React.ReactNode {
    if (!this.props.appState?.user) {
      return <Loader />;
    }
    // if (
    //   !this.props.appState?.user ||
    //   (!this.props.appState.user.profile_completed && !this.props.appState.user.odooId)
    // ) {
    //   return <ProfileSetup />;
    // }
    return this.props.children;
  }
}

export default withAppState(UserProfileGate);
