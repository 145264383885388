import React from 'react';

import Amplify from 'aws-amplify';
import Analytics from '@aws-amplify/analytics';
// import Api from '@aws-amplify/api';
// import PubSub from '@aws-amplify/pubsub';
// import Auth from '@aws-amplify/auth';
import { BaseAlphaUI, withAuthentication } from 'basealpha-ui';
import aws_exports from './aws-exports';
import aws_config_override from './aws-config-override';

import './App.less';
import MainRouter from './Routers/MainRouter';
import merge from './utils/merge';
import { initMixpanel, mixpanelSetUser } from './utils/mixpanel';

Amplify.configure(merge(aws_exports, aws_config_override));
Analytics.configure({
  disabled: true,
});
// Api.configure(aws_exports);
// PubSub.configure(aws_exports);
// Auth.configure(aws_exports);

BaseAlphaUI.configure({
  appName: 'Ehcms',
  authApiName: 'EhcmsPortalApi',
  oauthUrl: '/contractor/oauth',
});

const App: React.FC = (): React.ReactElement => {
  return <MainRouter />;
};

initMixpanel();

// @ts-ignore
export default withAuthentication(App, {
  header: (
    <div>
      <img src={require('./assets/edari.svg')} width="130px" style={{ marginLeft: '-10px' }} />
    </div>
  ),
  events: {
    onLogin: [
      async (): Promise<void> => {
        await mixpanelSetUser();
      },
    ],
  },
});
// export default withAuthentication(App, {
//   usernameAttributes: 'email',
//   authenticatorComponents: [<SignIn />, <SignUp />, <ForgotPassword />],
//   // signUpConfig: {
//   //   signUpFields: [
//   //     {
//   //       label: 'First Name',
//   //       key: 'name',
//   //       required: true,
//   //       type: 'string',
//   //       custom: false,
//   //       displayOrder: 1,
//   //     },
//   //     {
//   //       label: 'Last Name',
//   //       key: 'family_name',
//   //       required: true,
//   //       type: 'string',
//   //       custom: false,
//   //       displayOrder: 2,
//   //     },
//   //     {
//   //       label: 'Email',
//   //       key: 'email',
//   //       required: true,
//   //       custom: false,
//   //       type: 'email',
//   //       displayOrder: 3,
//   //     },
//   //     {
//   //       label: 'Password',
//   //       key: 'password',
//   //       required: true,
//   //       custom: false,
//   //       type: 'password',
//   //       displayOrder: 4,
//   //     },
//   //   ],
//   // hiddenDefaults: ['username', 'email'],
//   // },
// });
