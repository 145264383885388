import React from 'react';
import { Form, Input } from 'formik-antd';

const PayrollNotReceived = (): React.ReactElement => {
  return (
    <>
      <h3>Payroll Query - Salary Not Received</h3>
      <i>We will check your query and get back to you.</i>
    </>
  );
};

export default PayrollNotReceived;
