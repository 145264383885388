import React from 'react';
import { Form, Input } from 'formik-antd';

const PayrollWrongAmount = (): React.ReactElement => {
  return (
    <>
      <h3>Payroll Query - Wrong Amount Received</h3>
      <Form.Item name={`expectedAmount`} label="Expected Amount">
        <Input type="number" name="expectedAmount" />
      </Form.Item>
      <Form.Item name={`receivedAmount`} label="Amount Received">
        <Input type="number" name="receivedAmount" />
      </Form.Item>
    </>
  );
};

export default PayrollWrongAmount;
