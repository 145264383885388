import React from 'react';

import { Avatar, Dropdown, Menu, Button } from 'antd';
import { Auth } from 'aws-amplify';
import { AuthService } from 'basealpha-ui';

const UserMenu = (): React.ReactElement => {
  const logout = (): void => {
    const service = new AuthService();
    service.signOut();
  };

  const menu = (
    <Menu>
      <Menu.Item key="0">
        <Button type="link" onClick={logout}>
          Logout
        </Button>
      </Menu.Item>
    </Menu>
  );

  return (
    <Dropdown overlay={menu} trigger={['click']}>
      <Avatar icon="user" />
    </Dropdown>
  );
};

export default UserMenu;
