import React from 'react';
import { Form, Input } from 'formik-antd';

const EmploymentLetter = (): React.ReactElement => {
  return (
    <>
      <h3>Employment Letter</h3>
      <Form.Item name={`entityName`} label="Entity Name">
        <Input name="entityName" />
      </Form.Item>
      <Form.Item name={`notes`} label="Additional Notes">
        <Input.TextArea name="notes" />
      </Form.Item>
    </>
  );
};

export default EmploymentLetter;
