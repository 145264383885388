import React, { ReactElement } from 'react';
import { Layout } from 'antd';
import AppMenu from '../Components/Menu';
import UserMenu from '../Components/UserMenu';
const { Sider, Footer } = Layout;

class App extends React.Component<{
  children: React.ReactNode;
}> {
  state = {
    collapsed: false,
    collapsedWidth: undefined,
  };

  onCollapse = (collapsed: boolean): void => {
    this.setState({ collapsed });
  };

  render(): ReactElement {
    const { children } = this.props;
    return (
      <Layout style={{ minHeight: '100vh' }}>
        <Sider
          collapsible
          breakpoint="lg"
          collapsedWidth={this.state.collapsedWidth}
          onBreakpoint={(broken: boolean): void => {
            if (broken) {
              this.setState({
                collapsedWidth: '0',
              });
            } else {
              this.setState({
                collapsedWidth: undefined,
              });
            }
          }}
          collapsed={this.state.collapsed}
          onCollapse={this.onCollapse}
        >
          <div className="logo">
            <img src={require('../assets/edari.svg')} style={{ maxWidth: '60%', marginLeft: '20px' }} />
          </div>
          <AppMenu />
        </Sider>
        <Layout style={{ padding: '0 24px 24px' }}>
          <div style={{ position: 'absolute', right: '20px', top: '10px', zIndex: 10 }}>
            <UserMenu />
          </div>
          {children}
          <Footer style={{ textAlign: 'center' }}>Edari ©{new Date().getFullYear()}</Footer>
        </Layout>
      </Layout>
    );
  }
}

export default App;
