import React from 'react';
import { Timesheet } from '../../../types';
import { Formik, FieldArray } from 'formik';
import { Form, SubmitButton, Input, DatePicker, Select } from 'formik-antd';
import { Row, Col, Button } from 'antd';
import moment from 'moment';
import PageHeader from '../../../Components/PageHeader';
import ContentWrapper from '../../../Components/ContentWrapper';
import { TimesheetEntryInput, DayType, TimesheetStatus, UpdateTimesheetInput } from '../../../API';

const validate = (values: Timesheet): any => {
  const errors = {} as any;
  // Trying to submit, check all entries
  values?.entries?.forEach((entry: TimesheetEntryInput | null, index: number): void => {
    if (!entry) {
      return;
    }
    if (entry.leaveId) {
      return;
    }
    if (entry.type === DayType.LEAVE && !entry.leaveType) {
      // User made this day a leave but didn't specify the type
      if (!errors.entries) {
        errors.entries = [];
      }
      errors.entries[index] = {
        leaveType: 'Please specify leave type',
      };
    }
  });
  return errors;
};

const TimesheetForm = ({
  record,
  save,
  submitTimesheet,
  close,
}: {
  record: Timesheet | null;
  save: (data: Timesheet) => void;
  submitTimesheet: (data: Timesheet) => void;
  close: () => void;
}): React.ReactElement => {
  const isLocked = record?.submitted && record?.status !== TimesheetStatus.REJECTED;
  return (
    <div data-testid="timesheet-form">
      <PageHeader
        title={record?.description || ''}
        routes={[
          { path: '/timesheets', breadcrumbName: 'Timesheets' },
          { path: ':id', breadcrumbName: record?.description },
        ]}
      />
      <ContentWrapper>
        <Formik initialValues={record || ({} as Timesheet)} onSubmit={save} validate={validate}>
          {(formProps: any): React.ReactElement => (
            <Form
              layout="horizontal"
              labelCol={{
                span: 6,
              }}
              wrapperCol={{
                span: 18,
              }}
              labelAlign="left"
            >
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item name={`fromDate`} label="From">
                    <DatePicker name="fromDate" disabled />
                  </Form.Item>
                  <Form.Item name={`status`} label="Status">
                    <Input name="status" disabled />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item name={`toDate`} label="To">
                    <DatePicker name="toDate" disabled />
                  </Form.Item>
                </Col>
              </Row>
              <br />
              <Row
                gutter={16}
                style={{
                  background: '#EFEFEF',
                  padding: '10px 0',
                }}
              >
                <Col span={8}>Date</Col>
                <Col span={8}>Activity</Col>
                <Col span={8}>Leave Type</Col>
              </Row>
              <FieldArray
                name="entries"
                render={(arrayHelpers: any): React.ReactElement => (
                  <>
                    {formProps.values.entries &&
                      formProps.values.entries.map((entry: TimesheetEntryInput, index: number) => (
                        <Row
                          key={entry.date}
                          gutter={16}
                          style={{ padding: '5px 0', borderBottom: '1px solid #efefef' }}
                        >
                          <Col span={8}>
                            <Form.Item name={`entries[${index}].date`} label="">
                              {moment(formProps.values.entries[index].date).format('ddd D')}
                              {/* <DatePicker name={`entries[${index}].date`} disabled /> */}
                            </Form.Item>
                          </Col>
                          <Col span={8}>
                            <Form.Item name={`entries[${index}].type`} label="">
                              <Select
                                name={`entries[${index}].type`}
                                disabled={
                                  !!formProps.values.entries[index].leaveId ||
                                  formProps.values.entries[index].type === DayType.HOLIDAY ||
                                  !!isLocked
                                }
                              >
                                {Select.renderOptions([
                                  { value: DayType.WORKED, label: 'Worked' },
                                  { value: DayType.LEAVE, label: 'Leave' },
                                  { value: DayType.CUSTOM_HOLIDAY, label: 'Public Holiday' },
                                  ...(formProps.values.entries[index].type === DayType.HOLIDAY
                                    ? [{ value: DayType.HOLIDAY, label: 'Holiday' }]
                                    : []),
                                ])}
                              </Select>
                            </Form.Item>
                          </Col>
                          <Col span={8}>
                            {formProps.values.entries[index].type === DayType.LEAVE && (
                              <Form.Item name={`entries[${index}].leaveType`} label="">
                                <Select
                                  name={`entries[${index}].leaveType`}
                                  disabled={!!formProps.values.entries[index].leaveId || !!isLocked}
                                >
                                  {Select.renderOptions([
                                    { value: 5, label: 'Annual Leave' },
                                    { value: 6, label: 'Sick Leave' },
                                    { value: 4, label: 'Unpaid Leave' },
                                    { value: 7, label: 'Not Worked' },
                                    { value: 8, label: 'Maternity Leave' },
                                    { value: 9, label: 'Paternity Leave' },
                                    { value: 10, label: 'Compassionate Leave' },
                                  ])}
                                </Select>
                              </Form.Item>
                            )}
                          </Col>
                        </Row>
                      ))}
                  </>
                )}
              />
              <Row style={{ marginTop: '20px' }}>
                <Col span={12}>
                  {formProps.values.submittedDate && (
                    <>
                      <h3>Status:</h3>
                      <p>
                        <b>Date Submitted: </b> {moment(formProps.values.submittedDate).format('MMMM Do YYYY')}
                      </p>
                      {formProps.values.approver1 && (
                        <p>
                          <b>{formProps.values.approver1Name}: </b>
                          {formProps.values.approved1
                            ? `Approved (${moment(formProps.values.approved1Date).format('MMMM Do YYYY')})`
                            : 'Pending Approval'}
                        </p>
                      )}
                      {formProps.values.approver2 && (
                        <p>
                          <b>{formProps.values.approver2Name}: </b>
                          {formProps.values.approved2
                            ? `Approved (${moment(formProps.values.approved2Date).format('MMMM Do YYYY')})`
                            : 'Pending Approval'}
                        </p>
                      )}
                      {(formProps.values.statusChanges || []).length > 0 && <h3>Timeline:</h3>}
                      <ul>
                        {(formProps.values.statusChanges || []).map((change: any) => (
                          <li>{`${moment(change.date).format('MMMM Do YYYY')}: ${change.message}`}</li>
                        ))}
                      </ul>
                    </>
                  )}
                </Col>
                <Col span={12}>
                  <div style={{ marginTop: '20px', textAlign: 'right' }}>
                    {!isLocked && (
                      <>
                        <SubmitButton style={{ marginRight: '10px' }}>Save Your Progress</SubmitButton>
                        <Button
                          type="primary"
                          onClick={async (): Promise<void> => {
                            // formProps.validateForm().then(() => {
                            //   submitTimesheet(formProps.values);
                            // })
                            await formProps.setFieldValue('submitting', true);
                            formProps.submitForm();
                          }}
                          disabled={formProps.isSubmitting}
                          loading={formProps.isSubmitting}
                          style={{ marginRight: '10px' }}
                        >
                          Submit For Approval
                        </Button>
                      </>
                    )}
                    <Button type="default" onClick={close}>
                      Back
                    </Button>
                  </div>
                </Col>
              </Row>
            </Form>
          )}
        </Formik>
      </ContentWrapper>
    </div>
  );
};

export default TimesheetForm;
