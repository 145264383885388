import React from 'react';
import { Link } from 'react-router-dom';
import { PageHeader as AntdPageHeader } from 'antd';

function itemRender(route: any, params: any, routes: any, paths: any): React.ReactElement {
  const last = routes.indexOf(route) === routes.length - 1;
  return last ? <span>{route.breadcrumbName}</span> : <Link to={`/${paths.join('/')}`}>{route.breadcrumbName}</Link>;
}

const PageHeader = ({ title, routes }: { title: string; routes?: any }): React.ReactElement => {
  return (
    <AntdPageHeader
      title={title}
      breadcrumb={
        routes
          ? {
              routes,
              itemRender,
            }
          : undefined
      }
    />
  );
};

export default PageHeader;
