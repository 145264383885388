import React, { useState, useEffect } from 'react';

// Amplify
import { graphqlOperation } from 'aws-amplify';
import { Connect } from 'aws-amplify-react';

// Components
import { Table } from 'antd';

// Mock
// import AddItem from './AddItem';
// import EditItem from './EditItem';
import { listTimesheets, timesheetsByContractor } from '../../graphql/queries';
import { Link } from 'react-router-dom';
import { Timesheet, IConnectListQuery } from '../../types';
import { TimesheetStatus } from '../../API';
import { History } from 'history';
import withAppState, { IAppStateComponentProps } from '../../state/withAppState';
import ConnectedInfiniteTable from '../../Components/ConnectedInfiniteTable';

class TimesheetTable extends ConnectedInfiniteTable<Timesheet, 'timesheetsByContractor'> {}

type Props = IAppStateComponentProps & { status: TimesheetStatus; history: History };
const List = (props: Props): React.ReactElement => {
  const queryGenerator = (nextToken: string | null): { query: any; variables: {} } => {
    return graphqlOperation(timesheetsByContractor, {
      owner: props.appState.user.id,
      statusFromDate: { beginsWith: { status: props.status, fromDate: null } },
      sortDirection: 'DESC',
      nextToken,
      limit: 20,
    });
  };

  const columns = [
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (text: string, record: Timesheet): string => {
        return record.status || 'PENDING';
      },
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: 'From Date',
      dataIndex: 'fromDate',
      key: 'fromDate',
    },
    {
      title: 'To Date',
      dataIndex: 'toDate',
      key: 'toDate',
    },
  ];
  return (
    <React.Fragment>
      <br />
      <TimesheetTable
        tableId="timesheet-table"
        onRowClick={(record: Timesheet, rowIndex: number): void => {
          props.history.push(`/timesheets/${record.id}`, { record });
        }}
        columns={columns}
        queryGenerator={queryGenerator}
        opName="timesheetsByContractor"
      />
    </React.Fragment>
  );
};

export default withAppState(List);
