import React from 'react';
import { Switch, Route, RouteComponentProps } from 'react-router-dom';
import { Tabs } from 'antd';
import { RequestStatus } from '../../API';
import List from './List';
import ContentWrapper from '../../Components/ContentWrapper';
import Form from './Form';
import PageHeader from '../../Components/PageHeader';

class Requests extends React.Component {
  render(): React.ReactElement {
    return (
      <Switch>
        <Route
          path="/requests"
          exact
          render={(props: RouteComponentProps): React.ReactElement => (
            <>
              <PageHeader title="Requests" />
              <ContentWrapper>
                <List history={props.history} />
              </ContentWrapper>
            </>
          )}
        />
        <Route path="/requests/:id" component={Form} />
      </Switch>
    );
  }
}

export default Requests;
