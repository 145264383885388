import React from 'react';
import { Form, Input } from 'formik-antd';

const PayrollPayslip = (): React.ReactElement => {
  return (
    <>
      <h3>Payroll Query - Request Payslip</h3>
      <Form.Item name={`months`} label="Months">
        <Input.TextArea name="months" />
      </Form.Item>
    </>
  );
};

export default PayrollPayslip;
