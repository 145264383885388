import React, { useState, useEffect } from 'react';

// Amplify
import { graphqlOperation } from 'aws-amplify';
import { Connect } from 'aws-amplify-react';

// Components
import { Button, Table } from 'antd';

// Mock
import { requestsByContractor } from '../../graphql/queries';
import { Request } from '../../types';
import { RequestStatus, RequestType } from '../../API';
import { History } from 'history';
import withAppState, { IAppStateComponentProps } from '../../state/withAppState';
import ConnectedInfiniteTable from '../../Components/ConnectedInfiniteTable';
import { typeDisplay } from '../../utils/request';

class RequestTable extends ConnectedInfiniteTable<Request, 'requestsByContractor'> {}

type Props = IAppStateComponentProps & { history: History };
const List = (props: Props): React.ReactElement => {
  const queryGenerator = (nextToken: string | null): { query: any; variables: {} } => {
    return graphqlOperation(requestsByContractor, {
      owner: props.appState.user.id,
      sortDirection: 'DESC',
      nextToken,
      limit: 20,
    });
  };

  const columns = [
    // {
    //   title: 'Status',
    //   dataIndex: 'status',
    //   key: 'status',
    //   render: (_text: string, record: Request): string => {
    //     return record.status || 'PENDING';
    //   },
    // },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
      render: (text: string): string => {
        return typeDisplay(text as RequestType);
      },
    },
    {
      title: 'Request Date',
      dataIndex: 'requestDate',
      key: 'requestDate',
    },
  ];
  return (
    <React.Fragment>
      <br />
      <Button type="default" onClick={() => props.history.push('/requests/new')}>New Request</Button>
      <RequestTable
        tableId="request-table"
        onRowClick={(record: Request): void => {
          props.history.push(`/requests/${record.id}`, { record });
        }}
        columns={columns}
        queryGenerator={queryGenerator}
        opName="requestsByContractor"
      />
    </React.Fragment>
  );
};

export default withAppState(List);
