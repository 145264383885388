import React from 'react';
import { DatePicker, Form, Input } from 'formik-antd';

const NocTravel = (): React.ReactElement => {
  return (
    <>
      <h3>NOC - Travel</h3>
      <Form.Item name={`country`} label="Country">
        <Input name="country" />
      </Form.Item>
      <Form.Item name={`dateFrom`} label="Date From">
        <DatePicker name="dateFrom" mode="date" />
      </Form.Item>
      <Form.Item name={`dateTo`} label="Date To">
        <DatePicker name="dateTo" mode="date" />
      </Form.Item>
    </>
  );
};

export default NocTravel;
