import React from 'react';
import { Table, Button } from 'antd';
import { ColumnProps, TableProps } from 'antd/lib/table';

export type InfiniteTableProps<T> = TableProps<T> & {
  tableId: string;
  columns: Array<ColumnProps<any>>;
  loading: boolean;
  data: any;
  hasMoreData: boolean;
  loadMore: () => Promise<void>;
  maxHeight?: number;
  onRowClick?: (record: T, rowIndex: number) => void;
};

class InfiniteTable<T> extends React.Component<InfiniteTableProps<T>> {
  static defaultProps = {
    rowKey: 'id',
  };

  tableId: string;

  constructor(props: InfiniteTableProps<T>) {
    super(props);

    this.tableId = props.tableId || `table-${Math.floor(Math.random() * 10000)}`;
  }

  componentDidMount(): void {
    const scrollElement = document.querySelector(`.${this.tableId} .ant-table-body`);
    if (scrollElement) {
      scrollElement.addEventListener('scroll', (event: any): void => {
        const maxScroll = event.target.scrollHeight - event.target.clientHeight;
        const currentScroll = event.target.scrollTop;
        if (currentScroll === maxScroll && this.props.hasMoreData) {
          this.loadMore();
        }
      });
    }
    this.loadMore();
  }

  loadMore = async (): Promise<void> => {
    await this.props.loadMore();
  };

  render(): React.ReactElement {
    const { onRowClick, data, columns, maxHeight, ...restProps } = this.props;
    return (
      <Table
        className={this.tableId}
        columns={columns}
        dataSource={data}
        pagination={false}
        scroll={{ y: maxHeight || '100vh' }}
        style={{ minWidth: '400px' }}
        onRow={(record: T, rowIndex: number): { onClick: () => void } => {
          return {
            onClick: (): void => {
              if (onRowClick) {
                onRowClick(record, rowIndex);
              }
            },
          };
        }}
        {...restProps}
        footer={
          this.props.hasMoreData
            ? (): React.ReactNode => (
                <div style={{ textAlign: 'center' }}>
                  <Button
                    type="link"
                    onClick={this.loadMore}
                    loading={this.props.loading}
                    disabled={this.props.loading}
                  >
                    Load more
                  </Button>
                </div>
              )
            : undefined
        }
      />
    );
  }
}

export default InfiniteTable;
