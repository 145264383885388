import React from 'react';
import { Request } from '../../../types';
import { Formik, FieldArray } from 'formik';
import { Form, SubmitButton, Input, DatePicker, Select } from 'formik-antd';
import { Row, Col, Button } from 'antd';
import PageHeader from '../../../Components/PageHeader';
import ContentWrapper from '../../../Components/ContentWrapper';
import { RequestType } from '../../../API';
import { typeDisplay } from '../../../utils/request';
import SalaryCertificate from './Types/SalaryCertificate';
import ExperienceLetter from './Types/ExperienceLetter';
import EmploymentLetter from './Types/EmploymentLetter';
import SalaryTransferLetter from './Types/SalaryTransferLetter';
import NocDrivingLicense from './Types/NocDrivingLicense';
import NocTravel from './Types/NocTravel';
import PayrollNotReceived from './Types/PayrollNotReceived';
import PayrollWrongAmount from './Types/PayrollWrongAmount';
import PayrollPayslip from './Types/PayrollPayslip';
import MedicalInsurance from './Types/MedicalInsurance';
import TimesheetQuery from './Types/TimesheetQuery';

const validate = (values: Request): any => {
  const errors = {} as any;
  if (values.id) {
    errors.type = 'You cannot update this request';
  }
  switch (values.type) {
    case RequestType.SALARY_CERTIFICATE: case RequestType.EMPLOYMENT_LETTER:
      if (!values.entityName) {
        errors.entityName = 'Please specify entity name';
      }
      break;
    case RequestType.SALARY_TRANSFER_LETTER:
      if (!values.entityName) {
        errors.entityName = 'Please specify new bank name';
      }
      break;
    case RequestType.NOC_DRIVING_LICENSE:
      if (!values.drivingInstitureName) {
        errors.entityName = 'Please specify driving institute name';
      }
      if (!values.emirate) {
        errors.entityName = 'Please specify Emirate';
      }
      break;
    case RequestType.NOC_TRAVEL:
      if (!values.country) {
        errors.entityName = 'Please specify the country';
      }
      if (!values.dateFrom) {
        errors.entityName = 'Please specify from date';
      }
      if (!values.dateTo) {
        errors.entityName = 'Please specify to date';
      }
      break;
    case RequestType.PAYROLL_WRONG_AMOUNT:
      if (!values.expectedAmount) {
        errors.entityName = 'Please specify the expected amount';
      }
      if (!values.receivedAmount) {
        errors.entityName = 'Please specify the received amount';
      }
      break;
    case RequestType.PAYROLL_PAYSLIP:
      if (!values.months) {
        errors.entityName = 'Please specify the months';
      }
      break;
    case RequestType.MEDICAL_INSURANCE: case RequestType.TIMESHEET_QUERY:
      if (!values.notes) {
        errors.entityName = 'Please specify your query';
      }
      break;
  }
  return errors;
};

const setType = async (formProps: any, type: RequestType): Promise<void> => {
  await formProps.setFieldValue('type', type);
};

const getComponentByType = (type: RequestType): React.ReactElement | null => {
  switch (type) {
    case RequestType.SALARY_CERTIFICATE:
      return <SalaryCertificate />;
    case RequestType.EXPERIENCE_LETTER:
      return <ExperienceLetter />;
    case RequestType.EMPLOYMENT_LETTER:
      return <EmploymentLetter />;
    case RequestType.SALARY_TRANSFER_LETTER:
      return <SalaryTransferLetter />;
    
      case RequestType.NOC_DRIVING_LICENSE:
      return <NocDrivingLicense />;
    case RequestType.NOC_TRAVEL:
      return <NocTravel />;
      
    case RequestType.PAYROLL_NOT_RECEIVED:
      return <PayrollNotReceived />;
    case RequestType.PAYROLL_WRONG_AMOUNT:
      return <PayrollWrongAmount />;
    case RequestType.PAYROLL_PAYSLIP:
      return <PayrollPayslip />;

    case RequestType.MEDICAL_INSURANCE:
      return <MedicalInsurance />;

    case RequestType.TIMESHEET_QUERY:
      return <TimesheetQuery />;
  }
  return null;
}

const RequestForm = ({
  record,
  save,
  close,
}: {
  record: Request | null;
  save: (data: Request) => void;
  close: () => void;
}): React.ReactElement => {
  const isLocked = !!record?.id;
  return (
    <div data-testid="request-form">
      <PageHeader
        title={typeDisplay(record?.type) || ''}
        routes={[
          { path: '/requests', breadcrumbName: 'Requests' },
          { path: ':id', breadcrumbName: typeDisplay(record?.type) },
        ]}
      />
      <ContentWrapper>
        <Formik initialValues={record || ({} as Request)} onSubmit={save} validate={validate}>
          {(formProps: any): React.ReactElement => (
            <Form
              layout="horizontal"
              labelCol={{
                span: 6,
              }}
              wrapperCol={{
                span: 18,
              }}
              labelAlign="left"
            >
              {!formProps.values.type && (
                <>
                  <Button block type="primary" onClick={() => setType(formProps, RequestType.SALARY_CERTIFICATE)}>
                    Salary Certificate
                  </Button>
                  <br />
                  <br />
                  <Button block type="primary" onClick={() => setType(formProps, RequestType.EXPERIENCE_LETTER)}>
                    Experience Letter
                  </Button>
                  <br />
                  <br />
                  <Button block type="primary" onClick={() => setType(formProps, RequestType.EMPLOYMENT_LETTER)}>
                    Employment Letter
                  </Button>
                  <br />
                  <br />
                  <Button block type="primary" onClick={() => setType(formProps, RequestType.SALARY_TRANSFER_LETTER)}>
                    Salary Transfer Letter
                  </Button>
                  <br />
                  <br />
                  <Button block type="primary" onClick={() => setType(formProps, RequestType.NOC_DRIVING_LICENSE)}>
                    NOC - Driving License
                  </Button>
                  <br />
                  <br />
                  <Button block type="primary" onClick={() => setType(formProps, RequestType.NOC_TRAVEL)}>
                    NOC - Travel
                  </Button>
                  <br />
                  <br />
                  <Button block type="primary" onClick={() => setType(formProps, RequestType.PAYROLL_NOT_RECEIVED)}>
                    Payroll Query - Salary Not Received
                  </Button>
                  <br />
                  <br />
                  <Button block type="primary" onClick={() => setType(formProps, RequestType.PAYROLL_WRONG_AMOUNT)}>
                    Payroll Query - Wrong Amount Received
                  </Button>
                  <br />
                  <br />
                  <Button block type="primary" onClick={() => setType(formProps, RequestType.PAYROLL_PAYSLIP)}>
                    Payroll Query - Request Payslip
                  </Button>
                  <br />
                  <br />
                  <Button block type="primary" onClick={() => setType(formProps, RequestType.MEDICAL_INSURANCE)}>
                    Medical Insurance Query
                  </Button>
                  <br />
                  <br />
                  <Button block type="primary" onClick={() => setType(formProps, RequestType.TIMESHEET_QUERY)}>
                    Timesheet Query
                  </Button>
                  <br />
                  <br />
                </>
              )}
              {formProps.values.type && getComponentByType(formProps.values.type)}
              <SubmitButton disabled={isLocked}>Submit Your Request</SubmitButton>
              <Button onClick={close}>Back</Button>
            </Form>
          )}
        </Formik>
      </ContentWrapper>
    </div>
  );
};

export default RequestForm;
