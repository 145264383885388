import React from 'react';
import { Layout } from 'antd';
const { Content } = Layout;

const ContentWrapper = ({ children }: { children: React.ReactNode }): React.ReactElement => (
  <Content
    style={{
      background: '#fff',
      padding: 24,
      margin: 0,
      minHeight: 280,
      overflow: 'auto',
    }}
  >
    {children}
  </Content>
);

export default ContentWrapper;
