const merge = (object1: any, object2: any): any => {
  const target: any = {};
  // Merge the object into the target object
  const merger = (obj: any): void => {
    for (const prop in obj) {
      if (obj.hasOwnProperty(prop)) {
        if (Object.prototype.toString.call(obj[prop]) === '[object Object]') {
          // If we're doing a deep merge
          // and the property is an object
          target[prop] = merge(target[prop], obj[prop]);
        } else {
          // Otherwise, do a regular merge
          target[prop] = obj[prop];
        }
      }
    }
  };
  merger(object1);
  merger(object2);
  return target;
};

export default merge;
