import React from 'react';
import { Form, Input } from 'formik-antd';

const SalaryCertificate = (): React.ReactElement => {
  return (
    <>
      <h3>Salary Certificate</h3>
      <Form.Item name={`entityName`} label="Bank/Entity Name">
        <Input name="entityName" />
      </Form.Item>
      <Form.Item name={`notes`} label="Additional Notes">
        <Input.TextArea name="notes" />
      </Form.Item>
    </>
  );
};

export default SalaryCertificate;
